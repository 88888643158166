import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"
url = "https://api.nextlevel-football.com/api"

url =  domainUrl

export const editChat= createAsyncThunk(
  'chat/edit',
  async (
    reqData, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.put(
        `${url}/chats/${reqData.id}`,
        reqData,
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUnreadChats = createAsyncThunk(
  'chat/unread_chats',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/new_chat_count`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getChatHistory = createAsyncThunk(
  'chat/chat_history',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/chats`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendMessage = createAsyncThunk(
    'chat/send_message',
    async (reqData, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }
  
        const { data } = await axios.post(
          `${url}/chats`,
          reqData,
          config
        )
  
        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

export const getMessage = createAsyncThunk(
    'chat/messages',
    async (chatId, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/chats/${chatId}`, config)

        return {
          data: data,
          chatId: chatId
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

export const deleteChat= createAsyncThunk(
  'chat/delete',
  async (chatId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/chats/${chatId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)









export const deleteGroup= createAsyncThunk(
  'chat/delete_group',
  async (group, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/groups/${group}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const leaveGroup= createAsyncThunk(
  'chat/leave_group',
  async (group, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/groups/${group}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)







export const deleteMeeting= createAsyncThunk(
  'chat/meeting',
  async (group, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.delete(`${url}/meetings/${group}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getMeeting= createAsyncThunk(
  'chat/get_meeting',
  async (meetingId, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/meetings/${meetingId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createMeeting = createAsyncThunk(
  'chat/create_meeting',
  async (
    reqData, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/meetings`,
        reqData,
        config
      )
      
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const meetingUserActions = createAsyncThunk(
  'chat/meeting_user',
  async (
    reqData, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/meeting_user_actions`,
        reqData,
        config
      )
      
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const joinMeeting = createAsyncThunk(
  'chat/join_meeting',
  async (
    reqData, 
    { rejectWithValue, }) => {
    try {

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${url}/join_meeting`,
        reqData,
        config
      )
      
      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
