import React, { useState, useRef, useEffect } from 'react';
import './banner.css';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import {deleteAccount} from '../../routes/UserAction';
import {createStripeAccount} from '../../routes/PaymentAction';

import { deleteSession, editSession, createSession } from "../../routes/SessionAction";
import { deleteSubscription } from "../../routes/SubscriptionAction";
import { deleteAlert, editAlert, createAlert } from "../../routes/AlertAction";
import { deleteTrainingLocation, editTrainingLocation, createTrainingLocation } from "../../routes/TrainingLocationAction";

import CustomAlert from '../Alert/CustomAlert';
import MediaModal from '../Modal/MediaModal';

import { timeFormat, timeTable, domain } from "../../dummyData";
import Locations from '../../components/Location/Locations.json'


const CoachBannerMy = (props) => {
    const dispatch = useDispatch();

    const { loading, error, successMessage, user } = useSelector((state) => state.persist.user)
    const userData = user;

    const handleStripeAccount = () => {
        if(props.data?.account == 5) {
            customAlertRef.current.handleOpen("Sorry it seems you've already activated payment", 'danger');
            
        } else {
            dispatch(createStripeAccount())
            .then(response =>{
                // if (error) {
                //     // console.log('er', error)
                //     customAlertRef.current.handleOpen(error, 'danger');
                
                // } else {
                //     if (successMessage !== '') {
                //         customAlertRef.current.handleOpen(successMessage, 'success');
                //     }
                // }
                
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
    const handleDeleteAccount = () => {deleteAccountReq()}

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()
    const displayModallRef = useRef()
    const handleDisplay = (title, media) => {
        displayModallRef.current.handleOpen(title, media);
        
    };

    const deleteAccountReq = () => {
        dispatch(deleteAccount())
        .then(response =>{
          navigate('/login')
        })
        .catch((error) => {
            console.log(error)
        })
    }

    //// forms

    const [showSForm, setShowSForm] = useState(false);
    const [showTForm, setShowTForm] = useState(false);
    const [showAForm, setShowAForm] = useState(false);
    const [showPForm, setShowPForm] = useState(false);

    const [stateData, setStateData] = useState(
        Locations.find((c) => c.name === userData?.country).states
    );
    const [cityData, setCityData] = useState([]);

    const handleAlertChangeState = event => {
        getState(event.target.value.split("#")[0])
        setAlertFormData({...alertFormData, state:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2]});

        let availableState = Locations.find((c) => c.name === userData?.country);
        let availableCities = availableState?.states?.find(
          (s) => s.name === event.target.value.split("#")[0]
        );
        
        setCityData(availableCities.cities)
    }
    const handleAlertChangeCity = event => {
        setAlertFormData({...alertFormData, city:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2] });
    }

    const handleLocationChangeState = event => {
        getState(event.target.value.split("#")[0])
        setTrainingLocationFormData({...trainingLocationFormData, state:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2]});

        let availableState = Locations.find((c) => c.name === userData?.country);
        let availableCities = availableState?.states?.find(
          (s) => s.name === event.target.value.split("#")[0]
        );
        setCityData(availableCities.cities)
    }
    const handleLocationChangeCity = event => {
        setTrainingLocationFormData({...trainingLocationFormData, city:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2] });
    }

    const [sessionFormData, setSessionFormData] = useState({
        name: '',
        day: '',
        start_time: '',
        end_time: '',
        description: '',
        max_booking: 1,
        min_booking: 1,
        training_location_id: 0,
        start_at: '',
        end_at: '',
        image: '',
        is_private: 1,
        is_online: 0,
        total: '0.00',
        tax: '0.00',
        coach_id: props.data.id,
        status: 0,
    });
    const [alertFormData, setAlertFormData] = useState({
        description: '',
        state: '',
        city: '',
        long: '',
        lat: '',
        status: 0,
    });
    const [trainingLocationFormData, setTrainingLocationFormData] = useState({
        description: '',
        state: '',
        city: '',
        long: '',
        coach_id: props.data.id,
        lat: '',
        status: 0,
    });

    const [imagePreview, setImagePreview] = useState(null);
    // const handleSessionFormChange = (e) => {
    //     if (e.target.name === 'status') {
    //         setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 0: 1 });
    //     } else if (e.target.name === 'is_private') {
    //         setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 1: 0 });
    //     } else if (e.target.name === 'is_online') {
    //         setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 1: 0 });
    //     } else if(e.target.name === 'image') {

    //         const files = e.target.files;
    //         if (files && files.length > 0) {
    //             const file = files[0];
    //             setSessionFormData({ ...sessionFormData, [e.target.name]: file });
    //             setImagePreview(URL.createObjectURL(file));
    //         } else {
    //             setSessionFormData({ ...sessionFormData, [e.target.name]: '' });
    //             setImagePreview(null);
    //             console.error("No file selected");
    //         }
    //     } else {
    //         setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.value });
    //     }
    // }
    const handleSessionFormChange = (e) => {
        const { name, value, checked, files } = e.target;
        let newFormData = { ...sessionFormData };
    
        if (name === 'status') {
            newFormData[name] = checked ? 0 : 1;
        } else if (name === 'is_private' || name === 'is_online') {
            newFormData[name] = checked ? 1 : 0;
        } else if (name === 'image') {
            if (files && files.length > 0) {
                const file = files[0];
                newFormData[name] = file;
                setImagePreview(URL.createObjectURL(file));
            } else {
                newFormData[name] = '';
                setImagePreview(null);
                console.error("No file selected");
            }
        } else if (name === 'start_time' || name === 'end_time') {
            newFormData[name] = value;
    
            // Update start_at or end_at based on the new time input
            const { day, start_time, end_time } = newFormData;
            if (name === 'start_time' && day) {
                newFormData.start_at = `${day}T${start_time}:00.000Z`;
            }
            if (name === 'end_time' && day) {
                newFormData.end_at = `${day}T${end_time}:00.000Z`;
            }
        } else if (name === 'day') {
            newFormData[name] = value;
    
            // Update start_at and end_at based on the new day input
            const { start_time, end_time } = newFormData;
            if (start_time) {
                newFormData.start_at = `${value}T${start_time}:00.000Z`;
            }
            if (end_time) {
                newFormData.end_at = `${value}T${end_time}:00.000Z`;
            }
        } else {
            newFormData[name] = value;
        }
    
        setSessionFormData(newFormData);
    }

    const handleLocationFormChange = (e) => {
        if (e.target.name === 'status') {
            setTrainingLocationFormData({ ...trainingLocationFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setTrainingLocationFormData({ ...trainingLocationFormData, [e.target.name]: e.target.value });
        }
    }

    const handleAlertFormChange = (e) => {
        if (e.target.name === 'status') {
            setAlertFormData({ ...alertFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setAlertFormData({ ...alertFormData, [e.target.name]: e.target.value });
        }
    }

    const showForm = (type) => {
        switch (type) {
            case "session":
                setSessionFormData({
                    name: '',
                    description: '',
                    max_booking: 1,
                    min_booking: 1,
                    training_location_id: 0,
                    start_at: '',
                    end_at: '',
                    image: '',
                    is_private: 1,
                    is_online: 0,
                    total: '0.00',
                    tax: '0.00',
                    coach_id: props.data.id,
                    status: 0,
                })
                return setShowSForm(!showSForm)
            case "location":
                setTrainingLocationFormData({
                    description: '',
                    state: '',
                    city: '',
                    long: '',
                    lat: '',
                    coach_id: props.data.id,
                    status: 0,
                })
                return setShowTForm(!showTForm)
            case "alert":
                setAlertFormData({
                    description: '',
                    state: '',
                    city: '',
                    long: '',
                    lat: '',
                    status: 0,
                })
                return setShowAForm(!showAForm)
            case "plan":
                return setShowPForm(!showPForm)
            default:
                break
        }
    }

    const hideForm = (type) => {
        switch (type) {
            case "session":
                return setShowSForm(false)
            case "location":
                return setShowTForm(false)
            case "alert":
                return setShowAForm(false)
            case "plan":
                return setShowPForm(false)
            default:
                break
        }
    }

    const triggerRefresh = () => {
        props.fetchCoach();
    }

    const formatDateString = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [stVal, setStVal] = useState('');
    const getState = (name) => {
        if (name) {
            let availableState = Locations.find((c) => c.name === userData?.country);
            let availableCities = availableState?.states?.find(
                (s) => s.name === name
            );
            setStVal(availableCities.name+'#'+availableCities.longitude+'#'+availableCities.latitude)
        }
        
    }

    const formatDateString2 = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Extracts the date part in 'YYYY-MM-DD' format
    };
    
    const formatTimeString = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[1].slice(0, 5); // Extracts the time part in 'HH:MM' format
    };

    const setRow = (row, type) => {
        const updatedRow = { ...row };
        if (type === "session" && updatedRow.start_at) {
            // updatedRow.start_at = formatDateString(updatedRow.start_at);

            updatedRow.day = formatDateString2(updatedRow.start_at);
            updatedRow.start_time = formatTimeString(updatedRow.start_at);
            updatedRow.end_time = formatTimeString(updatedRow.end_at);
        }
        if (type === "session" && updatedRow.end_at) {
            updatedRow.end_at = formatDateString(updatedRow.end_at);
        }

        if (updatedRow.status) {
            updatedRow.status =  updatedRow.status? 1: 0;
        }
        if (type === "session" && updatedRow.is_online) {
            updatedRow.is_online =  updatedRow.is_online? 1: 0;
        }
        if (type === "session" && updatedRow.is_private) {
            updatedRow.is_private =  updatedRow.is_private? 1: 0;
        }

        if (type === "location" || type === 'session') {
            getState(updatedRow.state);
        }

        switch (type) {
            case "session":
                return setSessionFormData(updatedRow)
            case "location":
                return setTrainingLocationFormData(updatedRow)
            case "alert":
                return setAlertFormData(updatedRow)
            default:
                break
        }
        // console.log('edit', sessionFormData)
        
    }

    const deleteRow = (row, type) => {
        switch (type) {
            case "session":
                return dispatch(deleteSession(row.id))
                .then(response =>{
        
                  triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                })
            case "location":
                return dispatch(deleteTrainingLocation(row.id))
                .then(response =>{
        
                  triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                })
            case "alert":
                return dispatch(deleteAlert(row.id))
                .then(response =>{
        
                  triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                })
            case "plan":
                return dispatch(deleteSubscription(row.id))
                .then(response =>{
        
                  triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                })
            default:
                break
        }
        
    }

    const submitData = (type) => {
        switch (type) {
            case "session":
                // console.log('newd', sessionFormData)
                if(props.data?.account === 5) {
                    if(sessionFormData.name === '') {
                        return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
                    } else {
                        return sessionFormData.id > 0? 
                        dispatch(editSession({...sessionFormData, is_online:sessionFormData.is_online? 1: 0, is_private:sessionFormData.is_private? 1: 0}))
                        : dispatch(createSession(sessionFormData))
                        .then(response =>{
                            if (error) {
                                // console.log('er', error)
                                customAlertRef.current.handleOpen(error, 'danger');
                            
                            } else {
                                if (successMessage !== '') {
                                    customAlertRef.current.handleOpen(successMessage, 'success');
                                }
                            }
                            triggerRefresh();
                        })
                        .catch((error) => {
                            console.log(error)
                            customAlertRef.current.handleOpen(error, 'danger');
                        })
                    }
                    
                } else {
                    // account not linked
                    customAlertRef.current.handleOpen("Sorry it seems you haven't activated payment", 'danger');
                }
            case "location":
                if(trainingLocationFormData.description === '') return
                return trainingLocationFormData.id > 0? 
                dispatch(editTrainingLocation(trainingLocationFormData))
                : dispatch(createTrainingLocation(trainingLocationFormData))
                .then(response =>{
                    if (error) {
                        // console.log('er', error)
                        customAlertRef.current.handleOpen(error, 'danger');
                    
                    } else {
                        if (successMessage !== '') {
                            customAlertRef.current.handleOpen(successMessage, 'success');
                        }
                    }
                    triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                    customAlertRef.current.handleOpen(error, 'danger');
                })
            case "alert":
                if(alertFormData.description === '') return
                return alertFormData.id > 0? 
                dispatch(editAlert(alertFormData))
                : dispatch(createAlert(alertFormData))
                .then(response =>{
                    if (error) {
                        // console.log('er', error)
                        customAlertRef.current.handleOpen(error, 'danger');
                    
                    } else {
                        if (successMessage !== '') {
                            customAlertRef.current.handleOpen(successMessage, 'success');
                        }
                    }
                    triggerRefresh();
                })
                .catch((error) => {
                    console.log(error)
                    customAlertRef.current.handleOpen(error, 'danger');
                })
            default:
                break
        }
        
    }

    //// end forms

    return (
        <>
            <MediaModal ref={displayModallRef}/>
            <CustomAlert ref={customAlertRef}/>
            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img alt='ddk' onClick={() => {handleDisplay(`${props.data?.user?.first_name} ${props.data?.user?.last_name}`, props.data.user?.image? domain+props.data.user?.image:"./assets/images/1.jpg")}} src= {props.data.user?.image? domain+props.data.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.created_at)}</h6>
                            <span onClick={handleDeleteAccount} style={{marginLeft:'20px',  border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                Delete Acct
                            </span>
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Sub: ({props.data?.left})</h6>

                        {/* <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D'}}>Credit: $ 500</h6> */}

                        <div className="d-flex flex-row my-1 justify-content-center">
                        
                        <div className='mx-1'>
                            <span onClick={() => showForm('session')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-add"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Session</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => showForm('location')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-location"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Venue</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => showForm('alert')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-bell"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Alert</h6>
                        </div>

                        <div className='mx-1'>
                            <span onClick={() => showForm('plan')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Sub</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Chat</h6>
                        </div>


                        </div>
                        
                    </div>
                    <div className="d-flex flex-column">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={() => {navigateToRoute(`/edit_profile?id=${userData.uuid}`)}}>View Profile</span>
                        <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleStripeAccount}>
                            {
                                props.data?.account === 1 ? "Pay Initiated"
                                : props.data?.account === 2 ? "Pay Deactivated"
                                : props.data?.account === 5 ? "Pay Activated"
                                : props.data?.account === 3 ? "Pending"
                                : "Activate Pay"
                            }
                        </span>
                    </div>
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #16307D", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img onClick={() => {handleDisplay(`${props.data?.user?.first_name} ${props.data?.user?.last_name}`, props.data.user?.image? domain+props.data.user?.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data?.user?.image? domain+props.data?.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.createdAt)}</h6>
                        
                    {/* </div> */}
                </div>
                <div className="d-flex justify-content-center">
                    <span onClick={handleDeleteAccount} style={{ border:"solid 1px #16307D", maxWidth:'150px', color:'#16307D'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        Delete Acct
                    </span>
                </div>
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Sub: ({props.data?.left}) </h6>

                    {/* <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D'}}>Credit: $ 500</h6> */}

                    <div className="d-flex flex-row my-1 justify-content-center">
                        <div className='mx-1'>
                            <span onClick={() => showForm('session')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-add"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Session</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => showForm('location')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-location"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Venue</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => showForm('alert')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-bell"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Alert</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => showForm('plan')} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Sub</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.6em', textalign:'center'}}>Chat</h6>
                        </div>
                    </div>
                    
                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={() => {navigateToRoute(`/edit_profile?id=${userData.uuid}`)}}>View Profile</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleStripeAccount}>
                            {
                                props.data?.account === 1 ? "Pay Initiated"
                                : props.data?.account === 2 ? "Pay Deactivated"
                                : props.data?.account === 5 ? "Pay Activated"
                                : props.data?.account === 3 ? "Pending"
                                : "Activate Pay"
                            }
                        </span>
                    </div>
                    
                </div>
                
            </div>

            {showSForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Session Form</h3>
                    <div id='session-form'>

                        {props.data?.state? <>

                            {sessionFormData.id > 0? <>
                                <div >
                                    {imagePreview ? 
                                        (
                                            <div className='d-flex flex-column align-items-center'>
                                                <img alt='ddk' src={imagePreview} className="rounded-circle" width="50" height="50">
                                                </img>
                                                {/* <h6 style={{textAlign: 'center', marginLeft: '0px'}}>{sessionFormData.name? sessionFormData.name: ''}</h6> */}
                                                <input type="file" onChange={handleSessionFormChange} name='image' className="form-control mt-1" id="exampleFormControlInput1" placeholder="image" style={{width: '100px'}}/>
                                            </div>
                                        ) : (
                                            <div className='d-flex flex-column align-items-center'>
                                                <img alt='ddk' src= {sessionFormData?.image? domain+sessionFormData.image:"./assets/images/1.jpg"} className="rounded-circle" width="50" height="50">
                                                </img>
                                                {/* <h6 style={{textAlign: 'center', marginLeft: '0px'}}>{sessionFormData.name? sessionFormData.name: ''}</h6> */}
                                                <input type="file" onChange={handleSessionFormChange} name='image' className="form-control mt-1" id="exampleFormControlInput1" placeholder="image" style={{width: '100px'}}/>
                                            </div>
                                        )

                                    }
                                </div>
                                <div className='row'>
                                    
                                    <div className="mb-3 col-sm-12 col-md-2">
                                        <label className="form-label">Name</label>
                                        <input required type="text" value={sessionFormData.name} onChange={handleSessionFormChange} name='name' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter name"/>
                                        
                                    </div>
                                    <div className="mb-3 col-sm-12 col-md-1">
                                        <label className="form-label">Total</label>
                                        <input type="text" value={sessionFormData.total} onChange={handleSessionFormChange} name='total' className="form-control form-control-sm" placeholder="total" required/>
                                    </div>
                                    
                                    <div className="mb-3 col-sm-12 col-md-1">
                                        <label className="form-label">Slots</label>
                                        <input type="number" min="1" value={sessionFormData.max_booking?sessionFormData.max_booking: 1} onChange={handleSessionFormChange} name='max_booking' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="max booking"/>
                                    </div>

                                    <div className="mb-3 col-sm-12 col-md-2">
                                        <label className="form-label">Day</label>
                                        <input type="date" value={sessionFormData.day} onChange={handleSessionFormChange} name='day' className="form-control form-control-sm" placeholder="enter value"/>
                                    </div>
                                    <div className="mb-3 col-sm-12 col-md-2">
                                        <label className="form-label">Start At</label>
                                        <input type="time" value={sessionFormData.start_time} onChange={handleSessionFormChange} name='start_time' className="form-control form-control-sm" placeholder="enter value"/>
                                    </div>
                                    <div className="mb-3 col-sm-12 col-md-2">
                                        <label className="form-label">End At</label>
                                        <input type="time" value={sessionFormData.end_time} onChange={handleSessionFormChange} name='end_time' className="form-control form-control-sm" placeholder="enter value"/>
                                    </div>
                                    <div className="mb-3 col-sm-12 col-md-2">
                                        <label className="form-label">Training Location</label>
                                        <select value={sessionFormData.training_location_id? sessionFormData.training_location_id: ''} name='training_location_id' onChange={handleSessionFormChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                            <option value="">Select Location</option>
                                            {props.data.trainingLocations?.length > 0? 
                                            props.data.trainingLocations?.map((row) => {
                                            return <option key={row.id} value={row.id}>{row.description
                                            }</option>
                                            })
                                            : <></>}
                                        </select>
                                    </div>
                                </div>

                                <div className='row'>

                                    
                                </div>

                                

                                <div className="d-flex justify-content-center mb-3">
                                    <div className="d-flex mx-auto">
                                        <div className="form-check form-switch col-3 mx-auto">
                                            <input name='is_private' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleSessionFormChange} checked={sessionFormData.is_private === 1? true: false}/>
                                            <label className="form-check-label">Private</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mx-auto">
                                        <div className="form-check form-switch col-3 mx-auto">
                                            <input name='is_online' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleSessionFormChange} checked={sessionFormData.is_online === 1? true: false}/>
                                            <label className="form-check-label">Online</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mb-3">
                                    <div className="d-flex mx-auto">
                                        <div className="form-check form-switch col-3 mx-auto">
                                            <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleSessionFormChange} checked={sessionFormData.status === 0? true: false}/>
                                            <label className="form-check-label">Status</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <div className="d-flex mx-auto">
                                        <div className="mb-3 ml-4">
                                            <button onClick={() => hideForm('session')} className="btn btn-sm btn-secondary">Close</button>
                                        </div>
                                        <div className="mb-3 col-4">
                                            <button onClick={() => {submitData('session')}} className="btn btn-sm btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </>:null}

                        </>: <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'10px', marginTop:'0', color:'red', textAlign:'center'}}>Please subscribe to create sessions</h6>}
                        

                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3 d-flex flex-row justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">Sessions Table</h6>
                                <div className="">
                                    <select className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                        <option>All</option>
                                        <option>This week</option>
                                        <option>This Month</option>
                                        <option>This Week</option>
                                    </select>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                    <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Bookings</th>
                                                <th>Created date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{}}>
                                            {props.data?.sessions?.map((row) => (
                                                <tr key={row.id}><td>{row.name}</td>
                                                    <td>
                                                        {row.status === 0? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{row.bookings?.length}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                    <td>
                                                        <i onClick={() => {setRow(row, 'session')}}  className="fas fa-edit text-success mr-2"></i>
                                                        <i onClick={() => {deleteRow(row, 'session')}} className="fas fa-trash text-danger ml-2"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            : <></>}

            {showTForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Pre-Set Training Locations Form</h3>
                    <div id='training-location-form'>

                    <div className='row'>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">City</label>
                                <select value={stVal? stVal: ''} name='state' onChange={(e, value) => {handleLocationChangeState(e, value)}} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="">Select City</option>
                                    {stateData?.length > 0? stateData.map((state) => {
                                    return <option
                                    key={state.id} 
                                    value={state.name+'#'+state.longitude+'#'+state.latitude}>
                                    {state.name}
                                    </option>
                                    }): null}
                                </select>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">District</label>
                                <input type="text" value={trainingLocationFormData.city? trainingLocationFormData.city: ''} name='city' onChange={(e) => handleLocationFormChange(e)} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="district"/>
                                
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">Description</label>
                                <input type="text" value={trainingLocationFormData.description? trainingLocationFormData.description: ''} onChange={(e) => handleLocationFormChange(e)} name='description' className="form-control form-control-sm" id="exampleFormControlInput1" required placeholder="description"/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mb-3">
                            <div className="d-flex mx-auto">
                                <div className="form-check form-switch col-3 mx-auto">
                                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleLocationFormChange} checked={trainingLocationFormData.status === 0? true: false}/>
                                    <label className="form-check-label">Status</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => hideForm('location')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <button onClick={() => {submitData('location')}} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>


                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Training Locations Table</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                    <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Created date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{}}>
                                            {props.data?.trainingLocations?.map((row) => (
                                                <tr key={row.id}><td>{row.description}</td>
                                                    <td>
                                                        {row.status === 0? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{row.state}</td>
                                                    <td>{row.city}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                    <td>
                                                        <i onClick={() => {setRow(row, 'location')}}  className="fas fa-edit text-success mr-2"></i>
                                                        <i onClick={() => {deleteRow(row, 'location')}} className="fas fa-trash text-danger ml-2"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            : <></>}

            {showAForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Alert New Player Form</h3>
                    <div id='alert-form'>

                    <div className='row'>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">City</label>
                                <select value={stVal? stVal: ''} name='state' onChange={(e, value) => {handleAlertChangeState(e, value)}} className="form-select form-select-sm" aria-label=".form-select-sm example">
                                    <option value="">Select City</option>
                                    {stateData?.length > 0? stateData.map((state) => {
                                    return <option
                                    key={state.id} 
                                    value={state.name+'#'+state.longitude+'#'+state.latitude}>
                                    {state.name}
                                    </option>
                                    }): null}
                                </select>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">District</label>
                                <input type="text" value={alertFormData.city? alertFormData.city: ''} name='city' onChange={(e) => handleAlertFormChange(e)} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="district"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">Description</label>
                                <input type="text" value={alertFormData.description? alertFormData.description: ''} name='description' onChange={handleAlertFormChange} className="form-control form-control-sm" id="exampleFormControlInput1" required placeholder="description"/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mb-3">
                            <div className="d-flex mx-auto">
                                <div className="form-check form-switch col-3 mx-auto">
                                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleAlertFormChange} checked={alertFormData.status === 0? true: false}/>
                                    <label className="form-check-label">Status</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => hideForm('alert')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <button onClick={() => {submitData('alert')}} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Alerts Table</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Created date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{}}>
                                            {props.data.user?.alerts?.map((row) => (
                                                <tr key={row.id}><td>{row.description}</td>
                                                    <td>
                                                        {row.status === 0? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{row.state}</td>
                                                    <td>{row.city}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                    <td>
                                                        <i onClick={() => {setRow(row, 'alert')}}  className="fas fa-edit text-success mr-2"></i>
                                                        <i onClick={() => {deleteRow(row, 'alert')}} className="fas fa-trash text-danger ml-2"></i>
                                                    </td>
                                                </tr>
                                            ))}
                                            

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            : <></>}

            {showPForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Subscriptions</h3>
                    <div id='subscription'>

                        <div className="card shadow mb-4" style={{height: "100%"}}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Subscriptions Table</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive" style={{height: "300px"}}>
                                    <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>Package Name</th>
                                                <th>Status</th>
                                                <th>Start At</th>
                                                <th>Ends At</th>
                                                <th>Created date</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{}}>
                                            {props.data?.subscriptions?.map((row) => (
                                                <tr key={row.id}><td>{row.package?.name}</td>
                                                    <td>
                                                        {row.status === 5? 
                                                            <span className="badge text-bg-success">Active</span>
                                                        : 
                                                            <span className="badge text-bg-danger">In Active</span>
                                                        }
                                                    </td>
                                                    <td>{timeTable(row.start_at)}</td>
                                                    <td>{timeTable(row.end_at)}</td>
                                                    <td>{timeTable(row.created_at)}</td>
                                                </tr>
                                            ))}
                                            

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => hideForm('plan')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <Link to={'/pricing'} className="btn btn-sm btn-primary">Renew</Link>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            : <></>}
            
        </>
    );

}

export default CoachBannerMy