import { configureStore } from '@reduxjs/toolkit';
import userReducer from './UserSlice'
import sessionReducer from './SessionSlice'
import chatReducer from './ChatSlice'
import systemReducer from './SystemSlice'
import inventoryReducer from './InventorySlice'

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "main-root",
  version: 1,
  storage: storage,
};

const reducer = combineReducers({
  user: userReducer,
  // chat: chatReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const reducer2 = {
  persist: persistedReducer,
  session: sessionReducer,
  chat: chatReducer,
  system: systemReducer,
  inventory: inventoryReducer,
};

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: reducer2,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//   },
// })
