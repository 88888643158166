/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Index from "./Index"
import { getSessions, deleteSession } from '../routes/SessionAction';

import ConfirmDialog from '../components/Alert/ConfirmDialog';
import { timeTable } from "../dummyData";

const DashboardSession = (props) => {
    const dispatch = useDispatch();
    // const { sessions } = useSelector((state) => state.sessions)

    const confirmDialogRef = useRef()

    const [pageData, setPageData] = useState([]);
    const [row, setRow] = useState({});

    const fetchData = () => {
        dispatch(getSessions({searchword:'', cat: ''}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const deleteRow = () => {
        dispatch(deleteSession(row.id))
        .then(response =>{

          fetchData();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchData()
    
    }, [])

    const handleDelete = (data) => {
        setRow(data)
        sleep(0).then(() => {
            confirmDialogRef.current.handleOpen();
        });
        
        
    };

    const handleDeleteResponse = () => {
        deleteRow()
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const View = (props) => {
        return (
            <>
                <ConfirmDialog 
                    message={'Are you sure you want to delete this row?'} 
                    ref={confirmDialogRef}
                    handleDeleteResponse={handleDeleteResponse}
                />
                <div className="container-fluid">
            
                                
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Sessions</h1>
                        {/* <select className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <option>All</option>
                            <option>This week</option>
                            <option>This Month</option>
                            <option>This Week</option>
                        </select>
                        <a href=".." className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Add New
                        </a> */}
                    </div>

                
                    <div className="row">

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Active</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{pageData?.filter(el => el.status !== 5)?.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Group</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{pageData?.filter(el => el.max_booking > 1)?.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Private
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-auto">
                                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{pageData?.filter(el => el.max_booking === 1)?.length}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Ended</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{pageData?.filter(el => el.status === 5)?.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>




                <div className="container-fluid">


                    {/* <h1 className="h3 mb-2 text-gray-800">Tables</h1>
                    <p className="mb-4">DataTables is a third party plugin.</p> */}

                    
                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Sessions Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Country</th>
                                            <th>Coach</th>
                                            <th>Bookings</th>
                                            <th>Created date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {pageData.map((row) => (
                                            <tr key={row.id}><td>{row.name}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                     : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{row.user?.country}</td>
                                                <td>{row.user?.first_name}</td>
                                                <td>{row.bookings?.length}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                <td>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-trash text-danger ml-2"></i>
                                                </td>
                                            </tr>
                                        ))}
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return (
        <Index view={<View/>} />
    )
}

export default DashboardSession
