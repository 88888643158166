import React from "react";
// import './CardCSSOne.css'

import { timeFormat } from "../../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'


const CoachNearCard = (props) => {

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    return (
        <>

            {/* improvement area */}
            <div className="row mx-2">
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem"}}>
                    <div className="d-flex justify-content-center my-2">
                        <img style={{width:'20vh', height:'20vh', marginTop:'10px'}} alt='ddk' src={props.data.user?.image? props.data.user.image: "./assets/images/1.jpg"} className="rounded-circle"></img>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        {(props.data.user?.user_id === userData.id)? 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>You</h6> 
                        : 
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>{props.data.user? props.data.user.last_name: ''} {props.data.user? props.data.user.first_name: ''}</h6>
                        }
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#67BEFA', fontSize:12}}>Joined {timeFormat(props.data.user?.created_at)}</h6>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:12}}>Specialized at:</h6>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="d-flex flex-row">
                            {props.data.expertise?.length > 0 ?
                                props.data.expertise?.map((row) => (
                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                ))
                            : <h5 style={{color:'#16307D', fontSize: 12}}>No Expertise...</h5>}
                        </div>
                    </div>
                    <span onClick={() => {navigateToRoute(`/coach?id=${props.data.uuid}&&code=${props.data.user.uuid}`)}} style={{backgroundColor:'#16307D', border:"solid 1px #16307D", height:'30px', marginTop:'10px'}} className="badge py-2 px-1 my-1 rounded">
                        <i style={{marginRight:'10px'}} className="fa-solid fa-message"></i>
                        Send Proposal
                    </span>
                    
                </div>
            </div>
        </>
    );

}

export default CoachNearCard