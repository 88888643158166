import React, { useState, useEffect } from "react";
import SessionBanner from "../../components/Banners/SessionBanner";
import SessionBannerMy from "../../components/Banners/SessionBannerMy";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import { getSessions, showSession } from '../../routes/SessionAction';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from "react-router-dom";

import PlayerCard from "../../components/Cards/Player/PlayerCardS";
import PlayerPaidCard from "../../components/Cards/Player/PlayerPaidCard";
import MediaCard from "../../components/Cards/Session/MediaCard";
import MeetingCard from "../../components/Cards/Session/MeetingCard";


const UserSession = () => {
    const dispatch = useDispatch();

    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");

    const { user, searchWord, loading } = useSelector((state) => state.persist.user)
    // const { isloading } = useSelector((state) => state.session)
    const userData = user;

    const sessionSli = useSelector((state) => state.session)
    const sLoading = sessionSli.loading;
    const chatSli = useSelector((state) => state.chat)
    const cLoading = chatSli.loading;
    const sysSli = useSelector((state) => state.system)
    const sysLoading = sysSli.loading;

    const [pageSessionData, setPageSessionData] = useState([]);
    const [pageSessionsData, setPageSessionsData] = useState([]);
    const [isMine, setIsMine] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchSessions = () => {
        dispatch(getSessions({cat: 'single', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageSessionsData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchSession = () => {
        dispatch(showSession(id))
        .then(response =>{
            setPageSessionData(response.payload.data);

            if (userData.id === response.payload.data?.user?.id) {
                setIsMine(true)
                // isCoachHandler()
            } else {
                isPlayerHandler();
            }
            
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const [isPaidPlayer, setIsPaidPlayer] = useState(false);
    const isPlayerHandler = () => {

        let book = pageSessionData?.bookings?.filter(el => ((el.user_id === userData.id) && el.status === 5)).map(row => row)

        if (book?.length > 0) {
            setIsPaidPlayer(true)
            // console.log('book1', book)
        } else {
            setIsPaidPlayer(false)
            // console.log('book2', book)
        }

        

    }

    useEffect(() => {

        fetchSession();
        fetchSessions();
    
    }, [id])

    return(
        <>
            <div className="divider"></div>
            <Header page={'session'}/>

            {(loading || sLoading || cLoading || sysLoading) ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>

                {isMine === true? <SessionBannerMy fetchSession={fetchSession} data={pageSessionData}/> : <SessionBanner fetchSession={fetchSession} data={pageSessionData} isPaidPlayer={isPaidPlayer}/>}
                {/* <SessionBanner /> */}

                <h6 style={{textAlign:'center', color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>{pageSessionData?.description}</h6>

                {pageSessionData?.mine === true? <>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Players</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            {pageSessionData?.bookings?
                            <SlickHorizontalScroll slidesToShow={pageSessionData?.bookings.length<=3? (pageSessionData?.bookings.length) : null} data={pageSessionData?.bookings} card={PlayerPaidCard}/>
                            : <></>}
                        </div>
                    </div>

                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Paid Players</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            {pageSessionData?.bookings?
                            <SlickHorizontalScroll slidesToShow={pageSessionData?.bookings?.filter(el => el.status === 5)?.length<=3? (pageSessionData?.bookings?.filter(el => el.status === 5)?.length) : null} data={pageSessionData?.bookings?.filter(el => el.status === 5)} card={PlayerPaidCard}/>
                            : <></>}
                        </div>
                    </div>

                    {pageSessionData?.is_online?
                    <>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Meetings</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            {pageSessionData?.meetings?
                            <SlickHorizontalScroll slidesToShow={pageSessionData?.meetings?.filter(el => el.status !== 2)?.length<=3? (pageSessionData?.meetings?.filter(el => el.status !== 2)?.length) : null} data={pageSessionData?.meetings?.filter(el => el.status !== 2)} card={MeetingCard}/>
                            : <></>}
                        </div>
                    </div>

                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Past Meetings</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                        {pageSessionData?.meetings?
                        <SlickHorizontalScroll slidesToShow={pageSessionData?.meetings?.filter(el => el.status === 2)?.length<=3? (pageSessionData?.meetings?.filter(el => el.status === 2)?.length) : null} fetchSession={fetchSession} data={pageSessionData?.meetings?.filter(el => el.status === 2)} card={MeetingCard}/>
                        : <></>}
                        </div>
                    </div>
                    </>: null}
                </>:<>
                    {pageSessionData?.member === true? <>
                        <div>
                            <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Players</h6>
                            <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                            <div className="px-5">
                                {pageSessionData?.bookings?
                                <SlickHorizontalScroll slidesToShow={pageSessionData?.bookings.length<=3? (pageSessionData?.bookings.length) : null} data={pageSessionData?.bookings} card={PlayerCard}/>
                                : <></>}
                            </div>
                        </div>

                        {pageSessionData?.paid === true? <div>
                            <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Meetings</h6>
                            <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                            <div className="px-5">
                                {pageSessionData?.meetings?
                                <SlickHorizontalScroll slidesToShow={pageSessionData?.meetings?.filter(el => el.status !== 2)?.length<=3? (pageSessionData?.meetings?.filter(el => el.status !== 2)?.length) : null} data={pageSessionData?.meetings?.filter(el => el.status !== 2)} card={MeetingCard}/>
                                : <></>}
                            </div>
                        </div>: null}

                        <div>
                            <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Past Meetings</h6>
                            <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                            <div className="px-5">
                            {pageSessionData?.meetings?
                            <SlickHorizontalScroll slidesToShow={pageSessionData?.meetings?.filter(el => el.status === 2)?.length<=3? (pageSessionData?.meetings?.filter(el => el.status === 2)?.length) : null} fetchSession={fetchSession} data={pageSessionData?.meetings?.filter(el => el.status === 2)} card={MeetingCard}/>
                            : <></>}
                            </div>
                        </div>
                    
                    </>:<></>}
                </>}

                {/* <div>
                    <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Gallery</h6>
                    <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                    <div className="px-5">
                    {pageSessionData?.meetings?
                    <SlickHorizontalScroll slidesToShow={pageSessionData?.meetings.length<=3? (pageSessionData?.meetings.length) : null} data={pageSessionData?.meetings} card={MediaCard}/>
                    : <></>}
                    </div>
                </div> */}
            </>}

            <Footer/>
        </>
        
    )
}

export default UserSession