import React, { useEffect, useState, useRef } from 'react';

import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header";

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {editUser, signUp} from '../routes/UserAction';
import CustomAlert from '../components/Alert/CustomAlert';

// import "./home.css"
import "./edit.css"
import PageHeaderTwo from "./PageHeaderTwo"
import PageHeader from "./PageHeader"

import ErrorDialog from '../components/Alert/ErrorDialog';
import PushNotification from '../components/Alert/PushNotification';
import MapSetter from '../components/Map/MapSetter';
import Locations from '../components/Location/Locations.json'
import { domainUrl, domain } from '../dummyData';
let url =  domain;

const EditProfilePage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorDialogRef = useRef()
  const mapDialog = useRef()

  const [stage, setStage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleRememberMe = () => {
    setFormData({...formData, rememberMe:!rememberMe});
  }

  const handleChangeUserType = (value) => {
    setFormData({...formData, userType:value });
    setStage(true)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const [errorMsg, setErrorMsg] = useState('');
  const customAlertRef = useRef()

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    age: 0,
    updatePass: 0,
    map: 0,
    rememberMe: false,

    firstName: user.firstName, 
    image: user.image,
    lastName: user.lastName, 
    userType: user.userType, 
    address: user.address,
    email: user.email,
    phone: user.phone,
    description: user.description? user.description :'',
    password: '',
    oldpassword: '',
    lat: user.lat,
    long: user.long,
    city: user.city? user.city: '',
    state: user.state,
    country: user.country,
    currencySymbol: user.currencySymbol,
    area: user.area,
    dob: user.dob,
    rate: user.rate? user.rate : '',
    skills: user.expertise,
  });

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [skillData, setSkillData] = useState([]);
  const [skillOptionData, setSkillOptionData] = useState([])

  const handleSkill = event => {
    let id = event.target.value
    // const skills = skillOptionData.filter((item) => item.id == id);
    const skills = skillOptionData.find((c) => c.id == id);

    if (skillData.length > 0) {
      const check = skillData.filter((item) => item.id == id);

      if(check.length > 0){
        setSkillData(skillData.filter((item) => item.id != id));
      } else {
        const newList = skillData.concat({
          id: skills.id,
          name: skills.name,
        });
        setSkillData(newList);
      }
    } else {
      const newList = skillData.concat({
        id: skills.id,
        name: skills.name,
      });
      setSkillData(newList);
    }
    
  }

  const handleChangeCountry = event => {
    setFormData({...formData, country:event.target.value.split("|")[0], currencySymbol:event.target.value.split("|")[1]});
    let availableState = Locations.find((c) => c.name === event.target.value.split("|")[0]);
    setStateData(availableState.states)
  }
  const handleChangeState = event => {
    
    setFormData({...formData, state:event.target.value});
    let availableState = Locations.find((c) => c.name === country);
    let availableCities = availableState?.states?.find(
      (s) => s.name === event.target.value
    );
    setCityData(availableCities.cities)
  }
  const handleChangeCity = event => {
    setFormData({...formData, city:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2] });
  }

  const handleChangeUpdatePass = () => {
    setFormData({...formData, updatePass:updatePass===0?1:0});
  }
  const handleChangeAge = () => {
    setFormData({...formData, age:age===0?1:0});
  }
  const handleMap = (value) => {
    // setFormData({...formData, map:map===0?1:0});
    if (value === 1) {
      mapDialog.current.handleOpen();
    }
    
  }

  const handleResponse = (value) => {
    if (value) {
      setFormData({...formData, lat:value.lat, long:value.lng });
    } else {
      setFormData({...formData, lat:'', long:'' });
    }
    
  }

  const {
    firstName, 
    lastName, 
    address, 
    email, 
    password, 
    oldpassword,
    userType, 
    long, 
    lat, 
    phone,
    description,
    city,
    image,
    state,
    country,
    currencySymbol,
    area,
    age, 
    updatePass,
    dob,
    map,
    rate,
    skills,
    rememberMe
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const clearData = () => {
    setFormData({ 
      ...formData, 
      firstName: user.firstName, 
      image: user.image,
      lastName: user.lastName, 
      userType: user.userType, 
      address: user.address,
      email: user.email,
      phone: user.phone,
      description: user.description? user.description :'',
      password: '',
      oldpassword: '',
      lat: user.lat,
      long: user.long,
      city: user.city? user.city: '',
      state: user.state,
      country: user.country,
      currencySymbol: user.currencySymbol,
      area: user.area,
      age: 0,
      updatePass: 0,
      dob: user.dob,
      map: 0,
      rate: user.rate? user.rate: '',
      skills: user.expertise,
      rememberMe: false
    });
  };

  const [imagePreview, setImagePreview] = useState(null);
  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFormData({ ...formData, image: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, image: '' });
      setImagePreview(null);
      console.error("No file selected");
    }
  };

  const fetchExpertiseData = () => {
    let url =  `${domainUrl}/guest_expertise`

    fetch(url)
    .then(response => {
      return response.json()
    })
    .then(data => {
      setSkillOptionData(data.data)
    })
  }

  useEffect(() => {
    if (!user) { 
      navigate('/login') 
    } else {
      fetchExpertiseData();

      // console.log(user.expertise)

      // user.expertise?.map((row) => {
      //   // console.log('r', row)
      //   setSkillData({...skillData, id: row.expertise?.id, name:row.expertise?.name})
      // })

      if (user.expertise) {
        const updatedSkillData = user.expertise.map((row) => ({
          id: row.expertise?.id,
          name: row.expertise?.name
        }));
        setSkillData(updatedSkillData);
      }
  
      // console.log(skillData);

    }
  }, [navigate, user, success])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (updatePass === 1) {
      if (password.length > 7 && oldpassword.length > 7) {
        if (
          email !== '' && 
          firstName !== '' && lastName !== '' && long !== '' && lat !== '') {
            dispatch(editUser({...formData, skills:skillData}))
            .then(response =>{
              if (error) {
                customAlertRef.current.handleOpen(error, 'danger');
              }else {
                if (successMessage) {
                  customAlertRef.current.handleOpen(successMessage, 'success');
                }
              }
            });
        } else {
          customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
        }
      } else {
        customAlertRef.current.handleOpen('Please enter passwords correctly', 'danger');
      }
    } else {
      if (
        email !== '' &&
        firstName !== '' && lastName !== '' && long !== '' && lat !== '') {
          dispatch(editUser({...formData, skills:skillData}))
          .then(response =>{
            if (error) {
              customAlertRef.current.handleOpen(error, 'danger');
            }else {
              if (successMessage) {
                customAlertRef.current.handleOpen(successMessage, 'success');
              }
            }
          });
      } else {
        customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
      }
    }

  };

  return (
    <>
      {/* reg */}
      <Header page={'player'}/>
      <div className="divider"></div>
      {/* <ErrorDialog ref={errorDialogRef} msg={errorMsg}/> */}
      <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
      
      {loading ? (
        <div className="loader-container">
            <div className="spinner"></div>
            {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
        </div>
      ) :
      <div style={{}} className=''>
        <div className="b-screen pb-4 overflow-y-auto" style={{backgroundColor:'white', height:'100%'}}>

          {/* <PageHeaderTwo screen={'big'}/> */}
          <div className="d-flex justify-content-center mb-5">
            <div className="rounded-4 d-flex flex-column justify-content-center my-auto pb-2" style=
            {{width:'40vw', height:'80%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>

              {imagePreview ? 
              (
                <img 
                  style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                  alt='Preview' 
                  src={imagePreview} 
                  className="rounded-circle mx-auto mt-2 mb-1" 
                />
              ) : 
              (
                user?.image ? (
                  <img 
                    style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                    alt='ddk' 
                    src={user?.image ? url + user.image : "./assets/images/1.jpg"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                  />
                ) : (
                  <img 
                    alt='ddk' 
                    src={user.userType === 4 ? "./assets/images/logo/kickball.png" : "./assets/images/logo/holdball.png"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                    height="50" 
                    width="50"
                  />
                )
              )
              }

              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">{user.userType===4? 'Player Account':'Coach Account'}</h6>
              

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4">
                <div className="d-flex flex-row justify-content-center col-12 my-auto mx-auto">
                  <div className="input-group input-group-sm my-1 col-5">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-edit"></i>Profile
                    </span>
                    <input onChange={handleImageChange} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="file" name="image" id="image" autoComplete="image" className="form-control" placeholder="Image"></input>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={firstName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="firstName" id="firstName" autoComplete="firstName" className="form-control" placeholder="First Name"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={lastName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="lastName" id="lastName" autoComplete="lastName" className="form-control" placeholder="Last Name"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="address" id="address" autoComplete="address" className="form-control" placeholder="Address"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={country} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="country" id="country" autoComplete="country" className="form-control" placeholder="Country"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={state} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="state" id="state" autoComplete="state" className="form-control" placeholder="State"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={city? city: address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" className="form-control" placeholder="City"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-shopping-bag"></i>
                      </span>
                      <select 
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} name="email" id="email" className="form-control form-select" placeholder='Area of Perfection'
                        onChange={(e, value) => {handleSkill(e, value)}}
                        value={user.userType===4? 'Area of Perfection':'Area of Specialization'}
                      >
                        <option >{user.userType===4? 'Area of Perfection':'Area of Specialization'}</option>
                        {skillOptionData?.length > 0? skillOptionData.map((skillOption) => {
                          return <option
                            key={skillOption.id} 
                            value={skillOption.id}>
                            {skillOption.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={area} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="area" id="area" autoComplete="area" className="form-control" placeholder="Area"></input>
                    </div>
                  </div>
                </div>

                {/* <div>
                  {user && user.expertise && user.expertise.map((row) => (
                    <span key={row.expertise?.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D", fontSize:10}} className="badge py-1 my-1 rounded-pill">
                      {row.expertise?.name}
                    </span>
                  ))}
                </div> */}

                <div style={{}} className="">
                  {skillData?.length > 0? skillData.map((skill) => {
                    return <span 
                      key={skill.id}
                      style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D", fontSize:10}} className="badge py-1 my-1 rounded-pill">
                        {skill.name}
                    </span>
                  }): <></>}
                  
                </div>

                {user.userType===4?
                <div className='row'>
                  <div className="input-group input-group-sm my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-phone"></i>
                    </span>
                    <input required={user.userType===4?true:false} onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" className="form-control" placeholder="Phone"></input>
                  </div>
                </div>
                
                :
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required={user.userType===3?true:false} onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-wallet"></i>
                      </span>
                      <input required={user.userType===3?true:false} onChange={handleChange} value={rate} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="rate" id="rate" autoComplete="rate" className="form-control" placeholder="Charge/hr"></input>
                    </div>
                  </div>
                  
                </div>
                }

                <div className="input-group input-group-sm col-14 my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    <i className="fa fa-message"></i>
                  </span>
                  <input onChange={handleChange} value={description} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="description" id="description" className="form-control" placeholder="About"></input>
                </div>
                

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={updatePass === 0? false: true} onChange={handleChangeUpdatePass} value={updatePass}/>
                  <h6 className='my-0' style={{color:'#16307D', fontSize:11}}>Change Password</h6>
                </div>

                {updatePass === 1? 
                <div className='row'>
                  <div className="input-group col-6 input-group-sm my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input onChange={handleChange} value={oldpassword} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="oldpassword" id="oldpassword" className="form-control" placeholder="Enter Password"></input>
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                      :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                    </span>
                  </div>
                  <div className="input-group input-group-sm col-6 my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter New Password"></input>
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                      :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                    </span>
                  </div>
                </div>
                
                :null}
                

                <button 
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm rounded-4 mx-auto">
                    Save
                  </button>
              </form>
            
            </div>
          </div>

        </div>

        {/* mobile */}
        <div className="m-screen pb-2 overflow-y-auto" style={{backgroundColor:'white', height:'100%'}}>

          {/* <PageHeaderTwo screen={'mobile'}/> */}


          <div className="d-flex justify-content-center my-5 px-3">
            <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-auto" style={{width:'100%', height:'80%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>

              {imagePreview ? 
              (
                <img 
                  style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                  alt='Preview' 
                  src={imagePreview} 
                  className="rounded-circle mx-auto mt-2 mb-1" 
                />
              ) : 
              (
                user?.image ? (
                  <img 
                    style={{ border: '#16307D 3px solid', width: '10vw', height: '10vw', maxWidth: '30px', maxHeight: '30px' }} 
                    alt='ddk' 
                    src={user?.image ? url + user.image : "./assets/images/1.jpg"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                  />
                ) : (
                  <img 
                    alt='ddk' 
                    src={user.userType === 4 ? "./assets/images/logo/kickball.png" : "./assets/images/logo/holdball.png"} 
                    className="rounded-circle mx-auto mt-2 mb-1" 
                    height="50" 
                    width="50"
                  />
                )
              )
              }


              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">{user.userType===4? 'Player Account':'Coach Account'}</h6>
              

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-3">
                <div className="d-flex flex-row justify-content-center col-12 my-auto mx-auto">
                  <div className="input-group input-group-sm my-1 col-6">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-edit"></i>Profile
                    </span>
                    <input onChange={handleImageChange} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="file" name="image" id="image" autoComplete="image" className="form-control" placeholder="Image"></input>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={firstName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="firstName" id="firstName" autoComplete="firstName" className="form-control" placeholder="First Name"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={lastName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="lastName" id="lastName" autoComplete="lastName" className="form-control" placeholder="Last Name"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="address" id="address" autoComplete="address" className="form-control" placeholder="Address"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={country} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="country" id="country" autoComplete="country" className="form-control" placeholder="Country"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={state} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="state" id="state" autoComplete="state" className="form-control" placeholder="State"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input disabled onChange={handleChange} value={city? city: address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" className="form-control" placeholder="City"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-shopping-bag"></i>
                      </span>
                      <select 
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} name="email" id="email" className="form-control form-select" placeholder='Area of Perfection'
                        onChange={(e, value) => {handleSkill(e, value)}}
                        value={user.userType===4? 'Area of Perfection':'Area of Specialization'}
                      >
                        <option >{user.userType===4? 'Area of Perfection':'Area of Specialization'}</option>
                        {skillOptionData?.length > 0? skillOptionData.map((skillOption) => {
                          return <option
                            key={skillOption.id} 
                            value={skillOption.id}>
                            {skillOption.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div style={{}} className="">
                    {skillData?.length > 0? skillData.map((skill) => {
                      return <span 
                        key={skill.id}
                        style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D", fontSize:10}} className="badge py-1 my-1 rounded-pill">
                          {skill.name}
                      </span>
                    }): <></>}
                    
                  </div>

                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={area} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="area" id="area" autoComplete="area" className="form-control" placeholder="Area"></input>
                    </div>
                  </div>
                </div>

                {user.userType!==4?
                  <div className='row'>
                    <div className='col-12'>
                      <div className="input-group input-group-sm my-1">
                        <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                          <i className="fa fa-wallet"></i>
                        </span>
                        <input onChange={handleChange} value={rate} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="rate" id="rate" autoComplete="rate" className="form-control" placeholder="Charge/hr"></input>
                      </div>
                    </div>
                    
                  </div>
                  : null
                }


                <div className="input-group input-group-sm col-14 my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    <i className="fa fa-message"></i>
                  </span>
                  <input onChange={handleChange} value={description} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="description" id="description" autoComplete="description" className="form-control" placeholder="About"></input>
                </div>

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={updatePass === 0? false: true} onChange={handleChangeUpdatePass} value={updatePass}/>
                  <h6 className='my-0' style={{color:'#16307D', fontSize:11}}>Change password</h6>
                </div>

                {updatePass === 1?
                  <div className='row'>
                    <div className="input-group input-group-sm my-1 col-6">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input onChange={handleChange} value={oldpassword} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="oldpassword" id="oldpassword" className="form-control" placeholder="Password"></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                        :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                      </span>
                    </div>
                    <div className="input-group input-group-sm my-1 col-6">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="New Password"></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                        :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                      </span>
                    </div>
                  </div>
                :
                  null
                }

                

                <button 
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm rounded-4 mx-auto">
                    Save
                </button>
              </form>
            
            </div>
          </div>

        </div>
      </div>
      }
      <Footer/>
    </>
  )
}

export default EditProfilePage
