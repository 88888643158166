import React, { useState, useEffect } from "react"
import { useLocation, Link } from "react-router-dom";

export default function PaymentResponse() {

    // const search = useLocation().search;
    // const statusUrl =new URLSearchParams(search).get("success");

    useEffect(() => {
        // window.location.href = "/login";

        const timer = setTimeout(() => {
            window.location.href = "/login";
        }, 3000); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer);

    }, [])

    return (
        <div className="loader-container">
            <div className="spinner"></div>
            {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
        </div>
        // <div className="d-flex align-items-center justify-content-center flex-column vh-100 bg-primary">
        //     <h1 className="display-1 fw-bold text-white">Welcome Back</h1>

        //     <h4 className="text-success">Your payment is now pending confirmation</h4>

        //     <h4 className="text-white">From Stripe</h4>

        //     <Link to={'/'} className="btn btn-success" role="button">
        //     Back To Home
        //     </Link>
        // </div>
        
    );
}