import React, { useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CreateSessionModal from '../Modal/CreateSessionModal';
import ViewSessionModal from '../Modal/ViewSessionModal';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ userType, data, duser }) => {
  const [availability, setAvailability] = useState(data || []);
  const [cuser, setCuser] = useState(duser || null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [hours, setHours] = useState('');
  const [message, setMessage] = useState('');

  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  const handleSelectSlot = ({ start }) => {
    if (start < new Date()) {
      // setMessage('Past availability');
      setSelectedDate(null);
      // if (modalRef2.current) {
      //   modalRef2.current.handleOpen();
      // }
    } else {
      setMessage('');
      setSelectedDate(start);
      if (modalRef.current) {
        modalRef.current.handleOpen();
      }
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    if (modalRef2.current) {
      modalRef2.current.handleOpen();
    }
  };

  const events = availability?.map(event => ({
    title: event.name,
    start: new Date(event.start_at),
    end: new Date(event.end_at),
    allDay: false,
    eventId: event.id,
    sessionD: event,
    style: new Date(event.start_at) < new Date() ? { backgroundColor: 'lightcoral', color: 'white' } : { backgroundColor: 'lightblue', color: 'black' },
  })) || [];

  const CustomEvent = ({ event }) => {
    return (
      <div style={{ textAlign: 'center', color: event.style.color }}>
        {event.title}
      </div>
    );
  };

  return (
    <div>
      <CreateSessionModal ref={modalRef} title={selectedDate?.toDateString()} eDate={selectedDate?.toDateString()} cuser={cuser} />
      <ViewSessionModal ref={modalRef2} event={selectedEvent} />
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}
        style={{ height: 600 }}
        eventPropGetter={(event) => ({
          style: event.style,
        })}
        components={{
          event: CustomEvent,
        }}
        onSelectEvent={handleEventClick}
      />
    </div>
  );
};

export default MyCalendar;
