import React, { forwardRef, useImperativeHandle } from 'react';
import addNotification from 'react-push-notification';
// import logo from "./assets/images/logo/logo.png"


// const PushNotification = forwardRef((props, ref) => {

//   const notify = () => {
//     addNotification({
//         title: "Next Level...",
//         message: "Hello from emjay",
//         duration: 4000,
//         icon: logo,
//         native: true,
//         vibrate: true,
//         backgroundBottom: '#448aff',
//         backgroundTop: '#d50000',
//         onClick: () => {
//             alert('clicked') 
//         }
//     });
//   }

//   const handleNotify = (data) => {
//     notify()
//   };


//   useImperativeHandle(
//     ref,
//     () => ({
//       handleNotify() {handleNotify()},
//     }),
//   )


//   return (
//     <>
//         {/* <div onClick={() => handleClose(false)}> */}
//             {/* <a href="#" className="header-logo">
//                 <img src="./assets/images/logo/logo.png" 
//                 alt="NextLevel's logo" width="60" height="36"/>
//             </a> */}
//         {/* </div> */}
//     </>
//   );

// })

const PushNotification = (message, url) => {
    return addNotification({
        title: "Next Level...",
        message: message,
        duration: 300000, //5mins 60 x 5 x 1000
        icon: "./assets/images/logo/logo.png",
        native: true,
        vibrate: true,
        backgroundBottom: '#448aff',
        backgroundTop: '#d50000',
        onClick: () => {
            window.location.href = "/"+ url?url:'http://localhost:3000';
            // alert('clicked') 
        }
        //https://nextlevel-football.com/undefined
    });
  }


export default PushNotification