import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


const ErrorDialog = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    // console.log(props.message)

    const handleOpen = (message) => {
        setMessage(message)
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    };

    useImperativeHandle(
        ref,
        () => ({
          handleOpen(message) {handleOpen(message)},
          handleClose() {handleClose()},
        }),
    )

    return (
        <>
            <Alert show={show} variant="danger">
                <Alert.Heading>NextLevel Error Dialog</Alert.Heading>
                <p>
                    {message? message: ''}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                <Button onClick={() => setShow(false)} variant="outline-success">
                    Close
                </Button>
                </div>
            </Alert>
        </>

    )
})
export default ErrorDialog