import React, {forwardRef, useImperativeHandle, useState, createRef } from 'react';
import CurrentLocation from './CurrentLocation';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const MapSetter = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [mapData, setMapData] = useState({
    lat: null,
    lng: null,
  });

  const getMapData = (data) => {
    props.handleResponse(data)
    setMapData(data)
    // setOpen(true)
  };

  const handleSave = () => {
    // console.log(mapData)
    props.handleResponse(mapData)
    // setMapData(data)
    setOpen(false)
  };

  const handleOpen = () => {
    setMapData(null, null)
    setOpen(true)
  };

  const handleClose = () => {
    props.handleResponse(false)
    // setMapData(null, null)
    setOpen(false)
  };

  const getCurrentLocation = () => {}
  const setLocation = () => {}


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  return (
    <div onClick={() => handleClose}>

      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title?props.title: 'Form'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CurrentLocation 
            viewData={props.viewData? props.viewData: null} 
            viewMap={props.viewMap? props.viewMap:false} 
            canChoose={props.canChoose? props.canChoose: true} 
            getMapData={getMapData}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default MapSetter