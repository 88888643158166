import React from "react";

import { timeFormat, dateTime, reduceText, createMeeting } from "../../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { editMeetingN, editMeeting } from '../../../routes/MeetingAction';
import { enterMeetingUser } from '../../../routes/MeetingUserAction';

const CoachCard = (props) => {
    // console.log(props.data)

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    // console.log(props.data)

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const startMeeting = (row) => {
        // const vmid = createMeeting();
        // console.log(vmid)

        const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjMmZhMzJlYy03MTc4LTQxMDItOTIxZi0xMTZmOGQxYmE5MjYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4ODY5NDA3OSwiZXhwIjoxODQ2NDgyMDc5fQ.sLq-UbUciieqYXPB1ZkMpyaPeOc3NgGi-wBCTnUYwVI";

        fetch(`https://api.videosdk.live/v2/rooms`, {
            method: "POST",
            headers: {
            authorization: `${authToken}`,
            "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            // console.log('a', data.roomId)
            // return data.roomId;

            if (data.roomId) {
                dispatch(editMeetingN({...row, status: 1, code: data.roomId, cuuid:userData.uuid, ctype:userData.userType}))
                .then(response =>{})
                .catch((error) => {
                    console.log(error)
                })
            }
        })
    };

    const endMeeting = (row) => {
        dispatch(editMeeting({...row, status: 2}))
        .then(response =>{
            // props.fetchSession();
        })
        .catch((error) => {
            console.log(error)
        })
    };

    const leaveMeeting = (row) => {
        dispatch(editMeetingN({...row, status: 2}))
        .then(response =>{})
        .catch((error) => {
            console.log(error)
        })
    };

    const enterMeeting = (row) => {
        dispatch(enterMeetingUser({...row, status: 1, cuuid:userData.uuid, ctype:userData.userType}))
        .then(response =>{})
        .catch((error) => {
            console.log(error)
        })
    };

    return (
        <>
            {/* latest player feedback */}
            <div className="row" style={{marginLeft: '2vw', marginRight: '2vw',}}>
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem", minWidth:"200px"}}>
                    {/* <div className="d-flex flex-row my-2">
                        <img alt='ddk' src="./assets/images/1.jpg" className="rounded-circle" width="30" height="30"></img>
                        <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'10px', color:'#16307D'}} className="py-1">{props.data?.name}</h6>
                    </div> */}
                    <h6 style={{marginBottom:'0', marginTop:'0', textAlign: 'center', color:'#16307D'}} className="py-1">
                        {props.data?.name? reduceText(props.data?.name, 40, '...') : ''}
                    </h6>
                    <div className="d-flex flex-row my-1">
                        <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-solid fa-star-half-stroke"></i>
                    </div>

                    <h6 style={{marginBottom:'0', marginTop:'0', textAlign: 'center', color:'#16307D'}} className="py-1">
                        {dateTime(props.data?.start_at)}
                    </h6>
                    <h6 style={{marginBottom:'0', marginTop:'0', textAlign: 'center', color:'#008BEB'}} className="py-1">
                        To
                    </h6>
                    <h6 style={{marginBottom:'0', marginTop:'0', textAlign: 'center', color:'#16307D'}} className="py-1">
                        {dateTime(props.data?.end_at)}
                    </h6>

                    <hr className="mx-2"/>

                    {props.data?.status === 2? <h6 style={{marginBottom:'0', marginTop:'0', textAlign: 'center', color:'#008BEB'}} className="py-1">
                        Ended
                    </h6> 
                    :
                    <div className="d-flex justify-content-center">
                        <div className="d-flex mx-auto">
                            {props.data?.status === 0? 
                                
                                props.data?.user_id === userData.id? <div className="mb-3 ml-4">
                                    <button onClick={() => startMeeting(props.data)} className="btn btn-sm btn-success">Start</button>
                                </div>: <div className="mb-3 ml-4">
                                    <button className="btn btn-sm btn-success">Pending</button>
                                </div>
                            
                            :
                            <>
                                {props.data?.user_id === userData.id? 
                                    <><div className="mb-3 ml-4">
                                        <button onClick={() => enterMeeting(props.data)} className="btn btn-sm btn-success">Enter</button>
                                    </div>
                                    {/* <div className="mb-3 col-4">
                                        <button onClick={() => leaveMeeting(props.data)} className="btn btn-sm btn-danger">Leave</button>
                                    </div> */}
                                    <div className="mb-3 col-4">
                                        <button onClick={() => endMeeting(props.data)} className="btn btn-sm btn-danger">End</button>
                                    </div>
                                    </>
                                :
                                props.data?.status === 1? <div className="mb-3 ml-4">
                                    <button onClick={() => enterMeeting(props.data)} className="btn btn-sm btn-success">Join</button>
                                </div>: <div className="mb-3 ml-4">
                                    <button onClick={() => leaveMeeting(props.data)} className="btn btn-sm btn-success">Enter</button>
                                </div>}
                            </>}
                            
                        </div>
                    </div>}

                    <h6 style={{color:'#008BEB', fontSize:14, marginTop:'5px'}}>Published {timeFormat(props.data?.created_at)}</h6>
                </div>
            </div>
        </>
    );

}

export default CoachCard