import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {signIn, signUp} from '../routes/UserAction';

import CustomAlert from '../components/Alert/CustomAlert';
import TermsModal from '../components/Modal/TermsModal';

import "./home.css"
import PageHeaderTwo from "./PageHeaderTwo"
import PageHeader from "./PageHeader"

import ErrorDialog from '../components/Alert/ErrorDialog';
import PushNotification from '../components/Alert/PushNotification';
import MapSetter from '../components/Map/MapSetter';
import Locations from '../components/Location/Locations.json'
import { domainUrl } from '../dummyData';

const RegisterPage = () => {
  const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalRef = useRef()
  const handleOpen = () => {
    modalRef.current.handleOpen();
      
  };

  const customAlertRef = useRef()
  const errorDialogRef = useRef()
  const mapDialog = useRef()

  const [stage, setStage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleRememberMe = () => {
    setFormData({...formData, rememberMe:!rememberMe});
  }

  const handleChangeUserType = (value) => {
    setFormData({...formData, userType:value });
    setStage(true)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    lat: "",
    long: '',
    address: '',
    phone: '',
    password: '',
    email: '',
    userType: 4,
    city: '',
    state: '',
    country: '',
    currencySymbol: '',
    area: '',
    age: 0,
    term: 0,
    dob: '',
    map: 0,
    rate: '',
    skills: [],
    rememberMe: false
  });

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [skillData, setSkillData] = useState([
    // {id: 1, name: 'passing'},
    // {id: 2, name: 'kicking'},
    // {id: 3, name: 'throwing'}
  ]);
  const [skillOptionData, setSkillOptionData] = useState([
    // {id: 1, name: 'passing'},
    // {id: 2, name: 'kicking'},
    // {id: 3, name: 'throwing'}
  ])

  const handleSkill = event => {
    let id = event.target.value
    // const skills = skillOptionData.filter((item) => item.id == id);
    const skills = skillOptionData.find((c) => c.id == id);

    if (skillData.length > 0) {
      const check = skillData.filter((item) => item.id == id);

      if(check.length > 0){
        setSkillData(skillData.filter((item) => item.id != id));
      } else {
        const newList = skillData.concat({
          id: skills.id,
          name: skills.name,
        });
        setSkillData(newList);
      }
    } else {
      const newList = skillData.concat({
        id: skills.id,
        name: skills.name,
      });
      setSkillData(newList);
    }
    
  }

  const handleChangeCountry = event => {
    // console.log(event.target.value);

    setFormData({...formData, country:event.target.value.split("|")[0], currencySymbol:event.target.value.split("|")[1]});

    // setFormData({...formData, country:event.target.value.split("-")[0]});
    // setFormData({...formData, currencySymbol:event.target.value.split("-")[1]});

    let availableState = Locations.find((c) => c.name === event.target.value.split("|")[0]);
    // console.log('states', availableState.states)
    setStateData(availableState.states)
    // console.log('update', formData)
  }
  const handleChangeState = event => {
    
    setFormData({...formData, state:event.target.value});
    let availableState = Locations.find((c) => c.name === country);
    let availableCities = availableState?.states?.find(
      (s) => s.name === event.target.value
    );
    setCityData(availableCities.cities)
  }
  const handleChangeCity = event => {
    setFormData({...formData, city:event.target.value.split("#")[0], long:event.target.value.split("#")[1], lat:event.target.value.split("#")[2] });
  }

  const handleChangeTerm = () => {
    setFormData({...formData, term:term===0?1:0});
  }
  const handleChangeAge = () => {
    setFormData({...formData, age:age===0?1:0});
  }
  const handleMap = (value) => {
    // setFormData({...formData, map:map===0?1:0});
    if (value === 1) {
      mapDialog.current.handleOpen();
    }
    
  }

  const handleResponse = (value) => {
    if (value) {
      setFormData({...formData, lat:value.lat, long:value.lng });
    } else {
      setFormData({...formData, lat:'', long:'' });
    }
    
  }

  const {
    firstName, 
    lastName, 
    address, 
    email, 
    password, 
    userType, 
    long, 
    lat, 
    phone,
    city,
    state,
    country,
    currencySymbol,
    area,
    age, 
    term,
    dob,
    map,
    rate,
    skills: [],
    rememberMe
  } = formData;

  const handleChange = (e) => {
    // if (e.target.name ==== 'userType') {
    //   setFormData({ ...formData, [e.target.name]: e.target.checked? 4: 3 });
    // } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    // }
  };

  const clearData = () => {
    setFormData({ 
      ...formData, 
      firstName: '', 
      lastName: '', 
      userType: 4, 
      address: '',
      email: '',
      phone: '',
      password: '',
      lat: '',
      long: '',
      city: '',
      state: '',
      country: '',
      currencySymbol: '',
      area: '',
      age: 0,
      term: 0,
      dob: '',
      map: 0,
      rate: '',
      skills: [],
      rememberMe: false
    });
  };

  const fetchExpertiseData = () => {
    // let url = "http://127.0.0.1:8000/api/guest_expertise";
    let url =  `${domainUrl}/guest_expertise`

    fetch(url)
    .then(response => {
      return response.json()
    })
    .then(data => {
      setSkillOptionData(data.data)
    })
  }

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
    if (userToken !== null) {
      navigate('/landing_page')
      // return navigateToRoute('login')
      // window.location.href = "/login";
      // return null
    }
    fetchExpertiseData();

    // if (user) { 
    //   navigate('/landing_page') 
    // } else {
    //   fetchExpertiseData();
    // }

      // if (success) navigate('/message')
  }, [navigate, user, success])

  const checkPassword = (val) => {
    let status = false;
    if (val.length > 0) {
      return false
    } 
  }

  function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    // PushNotification(formData)
    if (password.length > 7) {
      if (
        email !== '' && password !== '' &&
        firstName !== '' && lastName !== '') 
      {
        // console.log('reg', formData)
        let presendData = ({...formData, skills:skillData})
        dispatch(signUp({...formData, skills:skillData}))
        .then(response =>{
          setSubmitting(true);
          if (error) {
            setErrorMsg(error);
            customAlertRef.current.handleOpen(error, 'danger');
            setSubmitting(false);
            
          } else {
            if (successMessage !== '') {
              dispatch(signIn({email:email, password:password}))
              .then(response =>{
                customAlertRef.current.handleOpen(successMessage, 'success');
                setSubmitting(false);
                navigate('/landing_page')
              })
            }
          }
        }).catch((error) => {
          setSubmitting(false);
          customAlertRef.current.handleOpen(error, 'danger');
        });
        
      } else {
        customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
        setSubmitting(false);
      }
    } else {
      customAlertRef.current.handleOpen('Password cannot be less than 8 characters!', 'danger');
      setSubmitting(false);
      
    }
  };

  return (
    <>
      <MapSetter 
        handleResponse={handleResponse} 
        ref={mapDialog} 
        canChoose={false}

        // viewData={{
        //   lat: 51.501364, 
        //   lng: -0.141890
        // }} 
        // viewMap={false} 
      />

      {/* <TermsModal 
        title={' Terms of service'} 
        ref={modalRef}
      /> */}

      {/* <ErrorDialog ref={errorDialogRef} message={errorMsg}/> */}

      {/* pre reg */}
      <div style={{display:stage?'none':'block'}} className=''>

        <div className="main-box b-screen" style={{backgroundColor:'#16307D', height:'100%'}}>

          <PageHeader screen={'big'}/>


          <div className="d-flex justify-content-center" style={{padding:'20px'}}>
            <div style={{padding:'20px', height:'100vh'}}>
              <div className="rounded-4 d-flex flex-column justify-content-center" style={{width:'50vw', height:'65vh', minHeight:'60vh', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
                <h6 style={{color:'#16307D'}} className="mx-auto mt-2 mb-0">Select Account Type</h6>
                <p style={{maxWidth:'30vw', color:'#16307D', textAlign:'center'}} className="mx-auto my-1">Your digital solution designed to help manage and improve football.</p>
                
                <div className="d-flex flex-row justify-content-center my-auto mx-4 px-4">

                  <div style={{backgroundColor:'#F8F9FF'}} className="d-flex flex-column justify-content-center my-auto mx-4 px-2 py-2">
                    <img alt='ddk' src="./assets/images/logo/kickball.png" className="rounded-circle mx-auto mt-2 mb-1" height="65" width="80"></img>
                    <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">Player Account</h6>

                    <div style={{width:'18vw', color:'#16307D', textAlign:'center'}}>
                      If you're looking for a top-notch football coach to help take your skills to the next level!
                    </div>
                    

                    <button style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto" onClick={ () => {handleChangeUserType(4)}}>Get Started</button>
                  </div>
                  <div style={{backgroundColor:'#F8F9FF'}} className="d-flex flex-column justify-content-center my-auto mx-4 px-2 py-2">
                    <img alt='ddk' src="./assets/images/logo/holdball.png" className="rounded-circle mx-auto mt-2 mb-1" height="65" width="80"></img>
                    <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">Coach Account</h6>

                    <div style={{width:'18vw', color:'#16307D', textAlign:'center'}}>
                      You are excited to offer your services to players looking to take their skills to the next level.
                    </div>
                    

                    <button style={{color:'#F8BC16', backgroundColor:'#FFFFFF', width:'120px', border:"solid 1px #F8BC16"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto" onClick={ () => {handleChangeUserType(3)}}>Get Started</button>
                  </div>


                </div>
                <Link to={'/'} className='my-0 mx-auto'>
                  <i style={{color:'#16307D', fontSize:10}} className="fa fa-arrow-left mx-auto my-2">  Go Back</i>
                </Link>
                
              </div>
            </div>
            
          </div>



        </div>

        {/* mobile */}
        <div className="main-box m-screen pb-3" style={{backgroundColor:'#16307D', height:'100%'}}>

          <PageHeader url={'/register'} txt={'Register'} txt1={"Don't have an account"} screen={'mobile'}/>


          <div className="d-flex justify-content-center my-5">
            <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-1" style={{width:'80vw', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
                
              <h6 style={{color:'#16307D'}} className="mx-auto mt-2 mb-0">Select Account Type</h6>
              <p style={{maxWidth:'70vw', color:'#16307D', textAlign:'center'}} className="d-flex flex-row justify-content-center mx-auto my-1">Your digital solution designed to help manage and improve football.</p>
                
              <div className="d-flex flex-column justify-content-center my-auto mx-1">

                <div style={{backgroundColor:'#F8F9FF'}} className="d-flex flex-column justify-content-center my-auto mx-4 px-2 py-2">
                  <img alt='ddk' src="./assets/images/logo/kickball.png" className="rounded-circle mx-auto mt-2 mb-1" height="65" width="80"></img>
                  <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">Player Account</h6>

                  <div className='mx-auto' style={{width:'50vw', color:'#16307D', textAlign:'center'}}>
                    If you're looking for a top-notch football coach to help take your skills to the next level!
                  </div>
                    

                  <button style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'110px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto" onClick={ () => {handleChangeUserType(4)}}>Get Started</button>
                </div>

                  <div style={{backgroundColor:'#F8F9FF'}} className="d-flex flex-column justify-content-center my-auto mx-4 px-2 py-2 mt-3">
                    <img alt='ddk' src="./assets/images/logo/holdball.png" className="rounded-circle mx-auto mt-2 mb-1" height="65" width="80"></img>
                    <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">Coach Account</h6>

                    <div className='mx-auto' style={{width:'50vw', color:'#16307D', textAlign:'center'}}>
                      You are excited to offer your services to players looking to take their skills to the next level.
                    </div>
                    

                  <button style={{color:'#F8BC16', backgroundColor:'#FFFFFF', width:'110px', border:"solid 1px #F8BC16"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto" onClick={ () => {handleChangeUserType(3)}}>Get Started</button>
                </div>

                <Link to={'/'} className='my-0 mx-auto'>
                  <i style={{color:'#16307D', fontSize:10}} className="fa fa-arrow-left mx-auto my-2">  Go Back</i>
                </Link>

              </div>  
              
            </div>
          </div>

        </div>

      </div>
      {/* pre reg end*/}

      {/* reg */}
      <div style={{display:stage?'block':'none', height:'100vh'}} className=''>
        <TermsModal 
          title={' Terms of service'} 
          ref={modalRef}
        />
        <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>

        <div className="main-box b-screen pb-4 overflow-y-auto" style={{backgroundColor:'#16307D', height:'100vh'}}>

          <PageHeaderTwo screen={'big'}/>

          {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
                {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
            </div>
          ) :
          <div className="d-flex justify-content-center mb-5">
            <div className="rounded-4 d-flex flex-column justify-content-center my-auto pb-2" style={{width:'40vw', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
              <img alt='ddk' src= { userType===4? "./assets/images/logo/kickball.png": "./assets/images/logo/holdball.png"} className="rounded-circle mx-auto mt-2 mb-1" height="50" width="50"></img>
              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">{userType===4? 'Player Account':'Coach Account'}</h6>
              
              {/* <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/> */}

              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-4">
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={firstName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="firstName" id="firstName" autoComplete="firstName" className="form-control" placeholder="First Name"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={lastName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="lastName" id="lastName" autoComplete="lastName" className="form-control" placeholder="Last Name"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="address" id="address" autoComplete="address" className="form-control" placeholder="Address"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        // value={country}
                        name="country"
                        onChange={(e, value) => {handleChangeCountry(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" id="country" autoComplete="country" className="form-control form-select" placeholder="Country"
                      >
                        <option value="">Select Country</option>
                        {Locations.map((country) => {
                        return <option
                          key={country.id} 
                          value={country.name+'|'+country.currency_symbol}>
                          {country.name}
                        </option>
                      })}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        value={state}
                        onChange={(e, value) => {handleChangeState(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="state" id="state" autoComplete="state" className="form-control form-select" placeholder="State"
                      >
                        <option value="">Select State/Region</option>
                        {stateData?.length > 0? stateData.map((state) => {
                        return <option
                          key={state.id} 
                          value={state.name}>
                          {state.name}
                        </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                </div>

                {cityData?.length > 0? <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        className="form-control form-select"
                        // value={city}
                        onChange={(e, value) => {handleChangeCity(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" placeholder="City"
                      >
                        <option value="">Select City</option>
                        {cityData?.length > 0? cityData.map((city) => {
                          return <option
                            key={city.id} 
                            value={city.name+'#'+city.longitude+'#'+city.latitude}>
                            {city.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth" required></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        DOB
                      </span>
                    </div>
                  </div>
                </div>
                :
                <div className="input-group input-group-sm my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    <i className="fa fa-calendar"></i>
                  </span>
                  <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth" required></input>
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    DOB
                  </span>
                </div>}

                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-shopping-bag"></i>
                      </span>
                      <select 
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} name="email" id="email" className="form-control form-select" placeholder='Area of Perfection'
                        onChange={(e, value) => {handleSkill(e, value)}}
                        value={userType===4? 'Area of Perfection':'Area of Specialization'}
                      >
                        <option >{userType===4? 'Area of Perfection':'Area of Specialization'}</option>
                        {skillOptionData?.length > 0? skillOptionData.map((skillOption) => {
                          return <option
                            key={skillOption.id} 
                            value={skillOption.id}>
                            {skillOption.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={area} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="area" id="area" autoComplete="area" className="form-control" placeholder="District"></input>
                    </div>
                  </div>
                </div>

                <div style={{}} className="">
                  {skillData?.length > 0? skillData.map((skill) => {
                    return <span 
                      key={skill.id}
                      style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D", fontSize:10}} className="badge py-1 my-1 rounded-pill">
                        {skill.name}
                    </span>
                  }): <></>}
                  
                </div>

                {userType===4?
                <div className='row'>
                  <div className="input-group input-group-sm my-1">
                    <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                      <i className="fa fa-phone"></i>
                    </span>
                    <input required={userType===4?true:false} onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" className="form-control" placeholder="Phone"></input>
                  </div>
                </div>
                
                :
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required={userType===3?true:false} onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone"></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-money"></i>
                      </span>
                      <input required={userType===3?true:false} onChange={handleChange} value={rate} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="rate" id="rate" autoComplete="rate" className="form-control" placeholder="Charge/hr"></input>
                    </div>
                  </div>
                  
                </div>
                }
                <div className="input-group input-group-sm my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    <i className="fa fa-lock"></i>
                  </span>
                  <input required onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                    :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                  </span>
                </div>

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={term === 0? false: true} onChange={handleChangeTerm} value={term}/>
                  <h6 onClick={handleOpen} className='my-0' style={{color:'#16307D', fontSize:11}}>I have read and accept all of the Terms of Service <i style={{color: '#F8BC16'}} className='fa fa-arrow-right'></i></h6>
                </div>

                <button
                  disabled={term === 0? true: submitting}
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">
                    Sign up
                </button>
              </form>
            
            </div>
          </div>}

        </div>

        {/* mobile */}
        <div className="main-box m-screen pb-2 overflow-y-auto" style={{backgroundColor:'#16307D', height:'100vh'}}>

          <PageHeaderTwo screen={'mobile'}/>

          {loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
                {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
            </div>
          ) :
          <div className="d-flex justify-content-center my-5 px-3">
            <div className="rounded-4 d-flex flex-column justify-content-center py-2 mx-auto" style={{width:'100%', height:'100%', backgroundColor:'#FFFFFF', border:"solid 2px #F8BC16"}}>
              <img alt='ddk' src= { userType===4? "./assets/images/logo/kickball.png": "./assets/images/logo/holdball.png"} className="rounded-circle mx-auto mt-2 mb-1" height="50" width="50"></img>
              <h6 style={{color:'#16307D'}} className="mx-auto mt-0 mb-1">{userType===4? 'Player Account':'Coach Account'}</h6>
              
              {/* <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/> */}
              <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-center my-auto mx-3">
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={firstName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="firstName" id="firstName" autoComplete="firstName" className="form-control" placeholder="First Name"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-user"></i>
                      </span>
                      <input required onChange={handleChange} value={lastName} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="lastName" id="lastName" autoComplete="lastName" className="form-control" placeholder="Last Name"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <input required onChange={handleChange} value={email} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="email" name="email" id="email" autoComplete="email" className="form-control" placeholder="Email"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={address} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="address" id="address" autoComplete="address" className="form-control" placeholder="Address"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-phone"></i>
                      </span>
                      <input required onChange={handleChange} value={phone} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="phone" id="phone" autoComplete="phone" className="form-control" placeholder="Phone"></input>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        // value={country}
                        name="country"
                        onChange={(e, value) => {handleChangeCountry(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" id="country" autoComplete="country" className="form-control form-select" placeholder="Country"
                      >
                        <option value="">Select Country</option>
                        {Locations.map((country) => {
                        return <option
                          key={country.id} 
                          value={country.name+'|'+country.currency_symbol}>
                          {country.name}
                        </option>
                      })}
                      </select>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        value={state}
                        onChange={(e, value) => {handleChangeState(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="state" id="state" autoComplete="state" className="form-control form-select" placeholder="State"
                      >
                        <option value="">Select State/Regoin</option>
                        {stateData?.length > 0? stateData.map((state) => {
                        return <option
                          key={state.id} 
                          value={state.name}>
                          {state.name}
                        </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                </div>

                {cityData?.length > 0? <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <select 
                        className="form-control form-select"
                        // value={city}
                        onChange={(e, value) => {handleChangeCity(e, value)}}
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" placeholder="City"
                      >
                        <option value="">Select City</option>
                        {cityData?.length > 0? cityData.map((city) => {
                          return <option
                            key={city.id} 
                            value={city.name+'#'+city.longitude+'#'+city.latitude}>
                            {city.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth" required></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        DOB
                      </span>
                    </div>
                  </div>
                </div>
                :
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} onChange={handleChange} value={dob} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth" required></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        DOB
                      </span>
                    </div>
                  </div>
                </div>}

                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-shopping-bag"></i>
                      </span>
                      <select 
                        style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} name="email" id="email" className="form-control form-select" placeholder='Area of Perfection'
                        onChange={(e, value) => {handleSkill(e, value)}}
                        value={userType===4? 'Area of Perfection':'Area of Specialization'}
                      >
                        <option >{userType===4? 'Area of Perfection':'Area of Specialization'}</option>
                        {skillOptionData?.length > 0? skillOptionData.map((skillOption) => {
                          return <option
                            key={skillOption.id} 
                            value={skillOption.id}>
                            {skillOption.name}
                          </option>
                        }): null}
                      </select>
                    </div>
                  </div>
                  <div style={{}} className="">
                    {skillData?.length > 0? skillData.map((skill) => {
                      return <span 
                        key={skill.id}
                        style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D", fontSize:10}} className="badge py-1 my-1 rounded-pill">
                          {skill.name}
                      </span>
                    }): <></>}
                    
                  </div>

                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-location"></i>
                      </span>
                      <input required onChange={handleChange} value={area} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="area" id="area" autoComplete="area" className="form-control" placeholder="District"></input>
                    </div>
                  </div>
                </div>

                {userType===4?
                <div className="input-group input-group-sm my-1">
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    <i className="fa fa-lock"></i>
                  </span>
                  <input required={userType===4?true:false} onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                  <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                    {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                    :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                  </span>
                </div>
                :
                <div className='row'>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-money"></i>
                      </span>
                      <input required={userType===3?true:false} onChange={handleChange} value={rate} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="rate" id="rate" autoComplete="rate" className="form-control" placeholder="Charge/hr"></input>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="input-group input-group-sm my-1">
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        <i className="fa fa-lock"></i>
                      </span>
                      <input required={userType===3?true:false} onChange={handleChange} value={password} style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type={showPassword ? "text" : "password"} name="password" id="password" className="form-control" placeholder="Enter Password"></input>
                      <span style={{backgroundColor:'#F8BC16', border:"solid 1px #16307D"}} className="input-group-text text-white">
                        {showPassword?<i onClick={handleShowPassword} className="fa fa-eye-slash"></i>
                        :<i onClick={handleShowPassword} className="fa fa-eye"></i>}
                      </span>
                    </div>
                  </div>
                </div>
                }

                <div className="form-check my-0 mx-auto ">
                  <input style={{backgroundColor:'#F8BC16', color:'#16307D'}} className="form-check-input mt-0" type="checkbox" id="remember_me" checked={term === 0? false: true} onChange={handleChangeTerm} value={term}/>
                  <h6 onClick={handleOpen} className='my-0' style={{color:'#16307D', fontSize:11}}>I have read and accept all of the Terms of Service <i style={{color: '#F8BC16'}} className='fa fa-arrow-right'></i></h6>
                </div>

                <button 
                  disabled={term === 0? true: submitting}
                  style={{color:'#FFFFFF', backgroundColor:'#F8BC16', width:'120px', border:"solid 1px #16307D"}} type="submit" className="btn btn-sm btn-block rounded-4 mx-auto">
                    Sign up
                  </button>
              </form>
            
            </div>
          </div>}

        </div>
      </div>
    </>
  )
}

export default RegisterPage
