import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { updateProg } from '../../redux/UserSlice';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux'

const CustomAlert = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [status, setStatus] = useState('');

    const handleOpen = (msg, status) => {
        setMsg(msg)
        setStatus(status)
        setShow(true)
    };

    const dispatch = useDispatch();
    const handleClose = () => {
        setShow(false)
        dispatch(updateProg())
    };

    useImperativeHandle(
        ref,
        () => ({
          handleOpen(msg, status) {handleOpen(msg, status)},
          handleClose() {handleClose()},
        }),
    )

    // let statuses = [
    //     'primary',
    //     'secondary',
    //     'success',
    //     'danger',
    //     'warning',
    //     'info',
    //     'light',
    //     'dark',
    // ]

    if (show) {
        switch (status) {
        // switch (props.status) {
            case 'success':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'warning':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'info':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'primary':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'secondary':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'danger':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'light':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            case 'dark':
                return (
                    <Alert variant={status} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{msg? msg: ''}</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
            default:
                return (
                    <Alert variant='warning' onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Unknown status</Alert.Heading>
                        {/* <p></p> */}
                    </Alert>
                );
                // throw new Error('Unknown status');
        }
        
    }
    // return <Button onClick={() => setShow(true)}>Show Alert</Button>;
})
export default CustomAlert