import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"

url =  domainUrl

export const createIdCardType= createAsyncThunk(
  'id_card_type/create',
  async (
    { 
      firstName,
      lastName,
      address,
      email,
      phone,
      password,
      userType,
      long,
      lat,
      dob,
      city,
      state,
      country,
      area,
      skills,
    }, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/api/id_card_type/create_id_card_type`,
        { 
          firstName,
          lastName,
          address,
          email,
          phone,
          password,
          userType,
          long,
          lat,
          dob,
          city,
          state,
          country,
          area,
          skills,
        },
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const editIdCardType= createAsyncThunk(
  'id_card_type/edit',
  async (
    { 
      firstName,
      lastName,
      address,
      email,
      phone,
      password,
      userType,
      long,
      lat,
      dob,
      city,
      state,
      country,
      area,
      skills,
    }, 
    { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      // console.log('ax', firstName)
      const { data } = await axios.post(
        `${url}/api/id_card_type/edit_id_card_type`,
        { 
          firstName,
          lastName,
          address,
          email,
          phone,
          password,
          userType,
          long,
          lat,
          dob,
          city,
          state,
          country,
          area,
          skills,
        },
        config
      )

      return data

    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteIdCardType= createAsyncThunk(
  'id_card_type/delete',
  async ({idCardTypeId}, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/api/id_card_type/delete_id_card_type/${idCardTypeId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const showIdCardType= createAsyncThunk(
  'id_card_type/show',
  async ({idCardTypeId}, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/api/id_card_type/show_id_card_type/${idCardTypeId}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getIdCardTypes = createAsyncThunk(
  'id_card_type/get',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }

      const { data } = await axios.get(`${url}/id_card_types`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)