import { useSelector, useDispatch } from 'react-redux'
import { NavLink, Outlet, Link, useNavigate } from 'react-router-dom'
import LoginPage from '../home/LoginPage';
// import { getUsers } from '../routes/UserAction';

const ProtectedRoute = () => {
  const user = useSelector((state) => state.persist.user);

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
    navigate(url);
  };

  // show unauthorized screen if no user is found in redux store
  const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null
  if ((userToken === null) || (!(userToken.length>1))) {
    // return navigateToRoute('login')
    window.location.href = "/login";
    return null
  }
  if (user?.userToken === null) {
    // return navigateToRoute('login')
    window.location.href = "/login";
    return null
  }

  return <Outlet />
}

export default ProtectedRoute
