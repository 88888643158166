import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { createExpertise, editExpertise } from '../../routes/ExpertiseAction';

import ErrorDialog from '../../components/Alert/ErrorDialog';
import CustomAlert from '../../components/Alert/CustomAlert';

const ExpertiseForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const errorDialogRef = useRef()
    const alertRef = useRef()

    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({
        only_coach: false,
        only_player: false,
        for_all: true,
        description: "",
        status: 0,
        name: ""
    });

    useImperativeHandle(
        ref,
        () => ({
        clearData() {clearData()},
        handleEdit(data) {
            handleEdit(data)
        },

        handleSubmit() {
            
            if (description !== '' && name !== '') {

                if (edit) {
                    dispatch(editExpertise(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                } else {
                    dispatch(createExpertise(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                    
                }
            
            
            } else {
                handleErrorDialog('Please make sure you fill the form correctly')
            }

        }

    }),)


    const handleAlert = (status, message) => {
        setMessage(message)
        setAlertType(status)
        alertRef.current.handleOpen();
    };

    const handleErrorDialog = (message) => {
        setMessage(message)
        errorDialogRef.current.handleOpen();
    };

    const { description, status, name, only_coach, only_player, for_all, } = formData;

    const handleChange = (e) => {
        if (e.target.name === 'status') {
        setFormData({ ...formData, [e.target.name]: e.target.checked? 0: 1 });
        } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleChangeToggle = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked? true: false });
    }

    const handleEdit = (data) => {
        setEdit(true)
        setFormData(data)

    };

    const clearData = () => {
        setFormData({ ...formData, 
            description: '', 
            status: 0, 
            name: '',
            only_coach: false,
            only_player: false,
            for_all: true,
        }
    );
    setEdit(false)
  };

  return (
    <>
        <ErrorDialog message={message} ref={errorDialogRef}/>
        <CustomAlert message={message} status={alertType} ref={alertRef}/>

        <form id='expertise-form'>

            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">Name</label>
                    <input type="text" value={name} onChange={handleChange} name='name' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter name"/>
                </div>
            </div>
            
            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">Description</label>
                    <input type="text" value={description} name='description' onChange={handleChange} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter value"/>
                </div>
            </div>

            <div className='row ml-5'>
                <div className="form-check form-switch col-6 mx-auto">
                    <input name='only_coach' className="form-check-input" type="checkbox" role="switch" onChange={handleChangeToggle} checked={only_coach}/>
                    <label className="form-check-label">Coach only</label>
                </div>
                <div className="form-check form-switch col-6 mx-auto">
                    <input name='only_player' className="form-check-input" type="checkbox" role="switch" onChange={handleChangeToggle} checked={only_player}/>
                    <label className="form-check-label">Player only</label>
                </div>
            </div>

            <div className='row ml-5'>
                <div className="form-check form-switch col-6 mx-auto">
                    <input name='for_all' className="form-check-input" type="checkbox" role="switch" onChange={handleChangeToggle} checked={for_all}/>
                    <label className="form-check-label">For all</label>
                </div>
                <div className="form-check form-switch col-6 mx-auto">
                    <input name='status' className="form-check-input" type="checkbox" role="switch" onChange={handleChange} checked={status===0?true:false}/>
                    <label className="form-check-label">Status</label>
                </div>
            </div>
            
        </form>
        
    </>
  );
})

export default ExpertiseForm