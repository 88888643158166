import React from "react";
// import './CardCSSOne.css'

import { timeFormat, domain } from "../../../dummyData";


const RemarkCard = (props) => {

    return (
        <>
            {/* latest player feedback */}
            <div className="row" style={{marginLeft: '2vw', marginRight: '2vw',}}>
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem", minWidth:"200px"}}>
                    <div className="d-flex flex-row my-2">
                        <img width="30" height="30" alt='ddk' src={props.data?.user?.image? domain+props.data?.user?.image: "./assets/images/1.jpg"} className="rounded-circle"></img>
                        <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'10px', color:'#16307D'}} className="py-1">{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                    </div>

                    <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 14, textAlign: 'center', fontWeight: 'bolder'}}>
                        {props.data?.message}
                    </h6>

                    <hr style={{color:'#16307D', fontSize: 14, fontWeight: 'bolder', backgroundColor:'#16307D'}} className="mx-2"/>

                    <div className="row my-1 mx-auto" style={{color:'#16307D', fontSize: 14, fontStyle: 'italic', textAlign: 'center', fontWeight: 'bold'}}>
                        
                        {props.data?.description}

                    </div>

                    <h6 style={{color:'#008BEB', fontSize:14, marginTop:'5px'}}>Sent: {timeFormat(props.data?.created_at)}</h6>
                </div>
            </div>
        </>
    );

}

export default RemarkCard