/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Index from "./Index"
import { getSubPackages, deleteSubPackage } from '../routes/SubPackageAction';
import { getPackages } from '../routes/PackageAction';

import CustomModal from '../components/Modal/CustomModal';
import ConfirmDialog from '../components/Alert/ConfirmDialog';
import SubPackageForm from "./Form/SubPackageForm";
import { timeTable } from "../dummyData";

const DashboardSubPackage = (props) => {
    const { subPackages } = useSelector((state) => state.inventory)

    const subPackageFormRef = useRef()
    const modalRef = useRef()
    const confirmDialogRef = useRef()
    
    const dispatch = useDispatch();

    const [pageData, setPageData] = useState([]);
    const [pagePackages, setPagePackages] = useState([]);
    const [row, setRow] = useState({});
    const [action, setAction] = useState('Add');

    // const [message, setMessage] = useState('');

    const fetchData = () => {
        dispatch(getSubPackages())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageData(editableData);
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPackages = () => {
        dispatch(getPackages())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPagePackages(editableData);
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const deleteRow = () => {
        dispatch(deleteSubPackage(row.id))
        .then(response =>{

          fetchData();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchData()
        fetchPackages()
    
    }, [])

    const handleOpen = () => {
        modalRef.current.handleOpen();
        sleep(0).then(() => {
            subPackageFormRef.current.clearData();
        });
        
    };

    const handleDelete = (data) => {
        setRow(data)
        sleep(0).then(() => {
            confirmDialogRef.current.handleOpen();
        });
        
        
    };

    const handleDeleteResponse = () => {
        deleteRow()
    }

    const handleResponse = (success) => {
        fetchData();
        sleep(0).then(() => {
            modalRef.current.handleClose();
            setAction('Add')
        });
    }

    const handleSubmit = () => {
        subPackageFormRef.current.handleSubmit();
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleEdit = (data) => {
        setAction('Edit')

        // modalRef.current.handleOpen()
        // .then(() => {
        //     subPackageFormRef.current.handleEdit(data);
        // });

        modalRef.current.handleOpen()
        sleep(0).then(() => {
          subPackageFormRef.current.handleEdit(data);
        });
    };

    const View = (props) => {
        return (
            <>
                <CustomModal 
                    title={action+' Sub Package Form'} 
                    tableForm={<SubPackageForm 
                            handleResponse={handleResponse}
                            ref={subPackageFormRef} 
                            packages={pagePackages}
                        />} 
                    ref={modalRef}
                    handleSubmit={handleSubmit}
                    
                />

                <ConfirmDialog 
                    message={'Are you sure you want to delete this row?'} 
                    ref={confirmDialogRef}
                    handleDeleteResponse={handleDeleteResponse}
                />
                <div className="container-fluid">
            
                                
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Sub Packages</h1>
                        
                        <a onClick={handleOpen} href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Add New
                        </a>
                    </div>

                </div>


                <div className="container-fluid">


                    {/* <h1 className="h3 mb-2 text-gray-800">Tables</h1>
                    <p className="mb-4">DataTables is a third party plugin.</p> */}

                    
                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Sub Packages Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Currency</th>
                                            <th>Cost</th>
                                            <th>Package</th>
                                            <th>Created date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                    {pageData.map((row) => (
                                            <tr key={row.id}><td>{row.name}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                     : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{row.currency}</td>
                                                <td>{row.cost}</td>
                                                <td>{row.package?.name}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                <td>
                                                    <i onClick={() => {handleEdit(row)}}  className="fas fa-edit text-success mr-2"></i>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-trash text-danger ml-2"></i>
                                                </td>
                                            </tr>
                                        ))}
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return (
        <Index view={<View/>} />
    )
}

export default DashboardSubPackage
