import { createSlice } from '@reduxjs/toolkit';

import {
  getCoachExpertise,
  deleteCoachExpertise,
  showCoachExpertise,
  editCoachExpertise,
  createCoachExpertise,
} from '../routes/CoachExpertiseAction';
import {
  getPlayerExpertise,
  deletePlayerExpertise,
  showPlayerExpertise,
  editPlayerExpertise,
  createPlayerExpertise,
} from '../routes/PlayerExpertiseAction';
import {
  getRemarks,
  deleteRemark,
  showRemark,
  editRemark,
  createRemark,
} from '../routes/RemarkAction';
import {
  getDisputes,
  deleteDispute,
  showDispute,
  editDispute,
  createDispute,
} from '../routes/DisputeAction';
import {
  getFlags,
  deleteFlag,
  showFlag,
  editFlag,
  createFlag,
} from '../routes/FlagAction';
import {
  getCountries,
  deleteCountry,
  showCountry,
  editCountry,
  createCountry,
} from '../routes/CountryAction';
import {
  getExpertise,
  deleteExpertise,
  showExpertise,
  editExpertise,
  createExpertise,
} from '../routes/ExpertiseAction';
import {
  getVerifications,
  deleteVerification,
  showVerification,
  editVerification,
  createVerification,
} from '../routes/VerificationAction';
import {
  getVerificationLevels,
  deleteVerificationLevel,
  showVerificationLevel,
  editVerificationLevel,
  createVerificationLevel,
} from '../routes/VerificationLevelAction';
import {
  getIdCardTypes,
  deleteIdCardType,
  showIdCardType,
  editIdCardType,
  createIdCardType,
} from '../routes/IdCardTypeAction';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  remarks: null,
  remark: null,
  disputes: null,
  dispute: null,
  flags: null,
  flag: null,
  verifications: null,
  verification: null,
  verificationLevels: null,
  verificationLevel: null,
  idCardTypes: null,
  idCardType: null,
  countries: null,
  country: null,
  coachExpertises: null,
  coachExpertise: null,
  playerExpertises: null,
  playerExpertise: null,
  expertises: null,
  expertise: null,
}

// initialState.blockedUsers = [1,2,6,7];
// initialState.newMessages = [0,];

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
      selectFlag: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.flags.map( el => el.id ).indexOf(action.payload);
        newState.flag = newState.flags[index];

        // fetch message here
        return newState;
      },
      selectCountry: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.countries.map( el => el.id ).indexOf(action.payload);
        newState.country = newState.flags[index];

        // fetch message here
        return newState;
      },
      selectDispute: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.disputes.map( el => el.id ).indexOf(action.payload);
        newState.dispute = newState.disputes[index];

        // fetch message here
        return newState;
      },
      selectRemark: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.remarks.map( el => el.id ).indexOf(action.payload);
        newState.remark = newState.remarks[index];

        // fetch message here
        return newState;
      },
      selectVerification: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.verifications.map( el => el.id ).indexOf(action.payload);
        newState.verification = newState.verifications[index];

        // fetch message here
        return newState;
      },
      selectVerificationLevel: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.verificationLevels.map( el => el.id ).indexOf(action.payload);
        newState.verificationLevel = newState.verificationLevels[index];

        // fetch message here
        return newState;
      },
      selectIdCardType: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.idCardTypes.map( el => el.id ).indexOf(action.payload);
        newState.idCardType = newState.idCardTypes[index];

        // fetch message here
        return newState;
      },
      selectExpertise: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.expertises.map( el => el.id ).indexOf(action.payload);
        newState.expertise = newState.expertises[index];

        // fetch message here
        return newState;
      },
      selectCoachExpertise: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.coachExpertises.map( el => el.id ).indexOf(action.payload);
        newState.coachExpertise = newState.coachExpertises[index];

        // fetch message here
        return newState;
      },
      selectPlayerExpertise: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.playerExpertises.map( el => el.id ).indexOf(action.payload);
        newState.playerExpertise = newState.playerExpertises[index];

        // fetch message here
        return newState;
      },
    },

    extraReducers: {

      // start

      // getCoachExpertise
      [getCoachExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCoachExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.coachexpertises = payload.coachExpertises;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCoachExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createCoachExpertise
      [createCoachExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createCoachExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createCoachExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editCoachExpertise
      [editCoachExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editCoachExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editCoachExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showCoachExpertise
      [showCoachExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showCoachExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.coachExpertise = payload.coachExpertise;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showCoachExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteCoachExpertise
      [deleteCoachExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteCoachExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteCoachExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPlayerExpertise
      [getPlayerExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPlayerExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.playerexpertises = payload.playerExpertises;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPlayerExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createPlayerExpertise
      [createPlayerExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createPlayerExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createPlayerExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editPlayerExpertise
      [editPlayerExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editPlayerExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editPlayerExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPlayerExpertise
      [showPlayerExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPlayerExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.PlayerExpertise = payload.PlayerExpertise;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPlayerExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deletePlayerExpertise
      [deletePlayerExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deletePlayerExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deletePlayerExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },



      // getExpertise
      [getExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getExpertise.fulfilled]: (state, { payload }) => {
        // console.log('payload', payload)
        let newState = { ...state };
        newState.expertises = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createExpertise
      [createExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editExpertise
      [editExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showExpertise
      [showExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.expertise = payload.expertise;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteExpertise
      [deleteExpertise.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteExpertise.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteExpertise.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getCountries
      [getCountries.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCountries.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.countries = payload.countries;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCountries.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createCountry
      [createCountry.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createCountry.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createCountry.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editCountry
      [editCountry.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editCountry.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editCountry.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showCountry
      [showCountry.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showCountry.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.country = payload.country;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showCountry.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteCountry
      [deleteCountry.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteCountry.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteCountry.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // getIdCardTypes
      [getIdCardTypes.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getIdCardTypes.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.idCardTypes = payload.idCardTypes;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getIdCardTypes.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createIdCardType
      [createIdCardType.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createIdCardType.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createIdCardType.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editIdCardType
      [editIdCardType.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editIdCardType.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editIdCardType.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showIdCardType
      [showIdCardType.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showIdCardType.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.idCardType = payload.idCardType;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showIdCardType.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteIdCardType
      [deleteIdCardType.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteIdCardType.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteIdCardType.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getRemarks
      [getRemarks.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getRemarks.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.remarks = payload.remarks;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getRemarks.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createRemark
      [createRemark.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createRemark.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createRemark.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editRemark
      [editRemark.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editRemark.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editRemark.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showRemark
      [showRemark.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showRemark.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.remark = payload.remark;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showRemark.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteRemark
      [deleteRemark.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteRemark.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteRemark.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getDisputes
      [getDisputes.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getDisputes.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.disputes = payload.disputes;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getDisputes.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createDispute
      [createDispute.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createDispute.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createDispute.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editDispute
      [editDispute.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editDispute.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editDispute.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showDispute
      [showDispute.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showDispute.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.dispute = payload.dispute;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showDispute.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteDispute
      [deleteDispute.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteDispute.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteDispute.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // getFlags
      [getFlags.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getFlags.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.flags = payload.flags;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getFlags.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createFlag
      [createFlag.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createFlag.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createFlag.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editFlag
      [editFlag.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editFlag.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editFlag.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showFlag
      [showFlag.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showFlag.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.flag = payload.flag;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showFlag.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteFlag
      [deleteFlag.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteFlag.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteFlag.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // getVerifications
      [getVerifications.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getVerifications.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.verifications = payload.verifications;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getVerifications.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createVerification
      [createVerification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createVerification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createVerification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editVerification
      [editVerification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editVerification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editVerification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showVerification
      [showVerification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showVerification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.verification = payload.verification;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showVerification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteVerification
      [deleteVerification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteVerification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteVerification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },



      // getVerificationLevels
      [getVerificationLevels.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getVerificationLevels.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.verificationLevels = payload.verificationLevels;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getVerificationLevels.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createVerificationLevel
      [createVerificationLevel.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createVerificationLevel.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createVerificationLevel.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editVerificationLevel
      [editVerificationLevel.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editVerificationLevel.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editVerificationLevel.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showVerificationLevel
      [showVerificationLevel.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showVerificationLevel.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.verificationLevel = payload.verificationLevel;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showVerificationLevel.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteVerificationLevel
      [deleteVerificationLevel.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteVerificationLevel.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteVerificationLevel.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      

      // end
    },

  })

// Action creators are generated for each case reducer function
export const { 
  selectCoachExpertise,
  selectCountry,
  selectPlayerExpertise,
  selectExpertise,
  selectDispute,
  selectFlag,
  selectIdCardType,
  selectRemark,
  selectVerification,
  selectVerificationLevel
} = systemSlice.actions

export default systemSlice.reducer