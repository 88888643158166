import React from "react";
// import './CardCSSOne.css'

import { timeFormat, domain, reduceText } from "../../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'


const CoachCard = (props) => {

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    return (
        <>
            {/* latest player feedback */}
            <div onClick={() => {navigateToRoute(`/coach?id=${props.data?.uuid}&&code=${props.data?.user?.uuid}`)}} className="row" style={{marginLeft: '2vw', marginRight: '2vw',}}>
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem", minWidth:"200px"}}>
                    <div className="d-flex flex-row my-2">
                        <img alt='ddk' src={props.data?.user?.image? domain+props.data?.user?.image: "./assets/images/1.jpg"} className="rounded-circle" width="30" height="30"></img>
                        {(props.data?.user?.user_id === userData.id)? 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px'}}>You</h6> 
                        : 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontWeight: 'bolder'}}>
                               {/* {props.data?.user? reduceText(`${props.data?.user?.last_name+' '+props.data?.user?.first_name}`, 22, '...') : ''} */}

                                {props.data?.user? props.data?.user?.first_name: ''} {props.data?.user? props.data?.user?.last_name: ''}
                            </h6>
                        }

                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontWeight: 'bolder'}}>
                            {props.data?.rate_per_hour? '£ '+props.data?.rate_per_hour+'/hr': ''}
                        </h6>

                    </div>

                    {props.data?.sessions?.length > 0?
                    
                        <>{props.data?.sessions?.length > 20?
                        <div className="d-flex flex-row mx-auto my-1">
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                        </div>
                        : props.data?.sessions?.length > 15?
                        <div className="d-flex flex-row mx-auto my-1">
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star-half-stroke"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        </div>
                        : props.data?.sessions?.length > 10?
                        <div className="d-flex flex-row mx-auto my-1">
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star-half-stroke"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        </div>
                        : props.data?.sessions?.length > 5?
                        <div className="d-flex flex-row mx-auto my-1">
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star-half-stroke"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        </div>
                        : 
                        <div className="d-flex flex-row mx-auto my-1">
                            <i style={{color:'#F9CC2E'}} className="fa-solid fa-star-half-stroke"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                            <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        </div>}
                        </>
                    : 

                    <div className="d-flex flex-row mx-auto my-1">
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                        <i style={{color:'#F9CC2E'}} className="fa-regular fa-star"></i>
                    </div>
                    }

                    <h6 className="mx-auto" style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>
                        {props.data?.user?.city? props.data?.user?.city+',': ''} {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                    </h6>

                    <div className="row my-1 mx-2">
                        {props.data?.user?.description? reduceText(props.data?.user?.description, 40, '...') : ''}
                    </div>
                    <hr className="mx-2"/>
                    <div className="row px-2" style={{maxWidth:'100%'}}>
                        <h6 style={{fontWeight:'bolder'}}>Area of Specialization</h6>
                        <div style={{}} className="">
                            {props.data?.expertise?.length > 0 ?
                                props.data?.expertise?.slice(-2)?.map((row) => (
                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise?.name}</span>
                                ))
                            : <h5 style={{color:'#16307D', fontSize: 12}}>No Expertise...</h5>}
                            
                        </div>
                    </div>
                    <div className="d-flex flex-row my-2 justify-content-between">
                        <h6 style={{color:'#008BEB', fontSize:14, marginTop:'5px'}}>Joined:  {timeFormat(props.data?.user?.created_at)}</h6>
                        <i onClick={() => {navigateToRoute(`/coach?id=${props.data?.uuid}&&code=${props.data?.user?.uuid}`)}} style={{color:'#008BEB', marginTop:'5px'}} className="fa-solid fa-eye"></i>
                    </div>
                    
                </div>
            </div>
        </>
    );

}

export default CoachCard