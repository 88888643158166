import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

const CustomModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  // const handleOpen = async () => {
  //   return setShow(true)
  // };
  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleSubmit = () => {
    props.handleSubmit()
  };


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title?props.title: 'Form'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.tableForm? props.tableForm : 'Form not available'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default CustomModal