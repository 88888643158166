import React, { useImperativeHandle, useState, forwardRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

const MediaModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');

  
  const handleOpen = (title, media) => {
    setTitle(title)
    setMedia(media)
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  useImperativeHandle(
    ref,
    () => ({
      handleOpen(title, media) {handleOpen(title, media)},
    }),
  )


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title?title: 'View'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            <img
              alt="view"
              src={media}
              className="img-fluid"
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default MediaModal