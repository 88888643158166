import React, { useState, useEffect } from "react";
import CoachProfileBanner from "../../components/Banners/CoachBanner";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import PlayerCard from "../../components/Cards/Player/PlayerCard";
import PlayerNearCard from "../../components/Cards/Player/PlayerNearCard";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getAllPlayers } from '../../routes/UserAction';


const UserPlayers = () => {

    const dispatch = useDispatch();
    const { user, searchWord, loading } = useSelector((state) => state.persist.user)
    const userData = user;
    const coaches = userData.allCoaches;

    const [pageData, setPageData] = useState([]);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchData = () => {
        dispatch(getAllPlayers({cat: 'all', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
        //   let resData = coaches;
          const editableData = resData.map(o => ({ ...o }));
        //   console.log('res', editableData)
          setPageData(editableData);
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
            console.log(error)
            //   showAlert(error, error)
        })
    }

    useEffect(() => {

        fetchData()
    
    }, [])

    return(
        <>
            <div className="divider"></div>
            <Header page={'player'}/>

            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>

                <div>
                    <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Players</h6>
                    <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={pageData.length<=3? (pageData.length): null} data={pageData} card={PlayerCard}/>
                    </div>
                </div>

                <div>
                    <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Players Near You</h6>
                    <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>
                    <div className="px-5">
                        <SlickHorizontalScroll slidesToShow={pageData.filter(el => el.user?.city === userData.city)?.length<=3? (pageData.filter(el => el.user?.city === userData.city)?.length) : null} data={pageData.filter(el => el.user?.city === userData.city)} card={PlayerNearCard}/>
                    </div>
                </div>
            </>}

            <Footer/>
        </>
        
    )
}

export default UserPlayers