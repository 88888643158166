import React, { useState } from "react"
import { useNavigate, Link } from 'react-router-dom'

const PageHeader = (props) => {
  return (
    props.screen === 'big'? 
    <div className="d-flex flex-row justify-content-between">
      <div className=""></div>
      <div className="d-flex justify-content-center" style={{marginLeft:'15vw'}}>
        <span className="rounded-bottom-5" style={{backgroundColor:'#FFFFFF', padding:'15px', borderLeft:"solid 2px #F8BC16", borderRight:"solid 2px #F8BC16", borderBottom:"solid 2px #F8BC16"}}>
          <img alt='ddk' src="./assets/images/logo/logo.png" className="" width="80" height="70"></img>
        </span>
      </div>
      <div className="d-flex flex-row" style={{marginRight:'10px', marginTop:'20px'}}>
        <h6 style={{marginBottom:'0', marginTop:'5px', color:'#FFFFFF', fontSize:10}}>{props.txt1?props.txt1:'Already have an account'}</h6>
        <Link className="my-0" to={props.url?props.url:'/login'}>
          <span style={{marginLeft:'20px', color:'#F8BC16', border:"solid 1px #F8BC16", height:'25px'}} className="badge px-3 rounded-pill">
            {props.txt?props.txt:'Login'}
          </span>
        </Link>
      </div>
      
    </div>

    :
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex justify-content-center" style={{marginLeft:'1px'}}>
        <span className="rounded-bottom-5" style={{backgroundColor:'#FFFFFF', padding:'20px', borderLeft:"solid 2px #F8BC16", borderRight:"solid 2px #F8BC16", borderBottom:"solid 2px #F8BC16"}}>
          <img alt='ddk' src="./assets/images/logo/logo.png" className="" width="50" height="40"></img>
        </span>
      </div>
      <div className="d-flex flex-row" style={{marginRight:'15px', marginLeft:'10px', marginTop:'20px'}}>
        <h6 style={{marginBottom:'0', marginTop:'5px', color:'#FFFFFF', fontSize:10}}>{props.txt1?props.txt1:'Already have an account'}</h6>
        <Link className="my-0" to={props.url?props.url:'/login'}>
          <span style={{marginLeft:'20px', color:'#F8BC16', border:"solid 1px #F8BC16", height:'25px'}} className="badge px-2 rounded-pill">
            {props.txt?props.txt:'Login'}
          </span>
        </Link>
      </div>
      
    </div>
  )
}

export default PageHeader
