import { useState, useEffect, useRef, useCallback } from "react"
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'

const GOOGLE_API_KEY = 'AIzaSyA1TIMjDTjVUPm4cRKqWmczVWEzP775hAw'

const MapContainer = props => {
    
    // sets state for blank listing
    const [listing, setListing] = useState({
      showingInfoWindow: false,  // Hides or shows the InfoWindow
      activeMarker: {},          // Shows the active marker upon click
      latLng: props.location,
      name: 'emjay',
      canChoose: props.canChoose
    })

    // ------------------- Google Maps start -------------------

    // sets ref for google map to allow finding it
    const refMap = useRef(null);

    useEffect(()=>{
        refMap.current.map.panTo(props.location)
    },[props.location])

    // map styling
    const mapStyles = {
      width: '100%',
      height: '88%',
      padding: '0px',
    };

    const updatePosition = () => {
      if (listing.canChoose === false) {
        setListing({ 
          ...listing, 
          latLng: props.location,
          showingInfoWindow: true
        });
      }
      props.getMapData(listing.latLng)
      
    }

    // handles centering of marker on map move
    const handleBoundsChanged = () => {
      if (refMap.current) {
        if (listing.canChoose === true) {
          // console.log('hbchange')
          const mapCenter = {
            lat: refMap.current.map.center.lat(),
            lng: refMap.current.map.center.lng()
          }
          setListing({...listing, latLng: mapCenter})
          // console.log('hbchange', listing)
          props.getMapData(listing.latLng)
        }
      }
    };

    const onMarkerClick = (props, marker, e) => {
      setListing({ 
        ...listing, 
        latLng: props.position,
        activeMarker: marker,
        showingInfoWindow: true
      });
      updatePosition()
    }

    const onClose = props => {
      if (listing.showingInfoWindow) {
        setListing({ 
          ...listing, 
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };

    // -------------------  Google Maps end  -------------------

    return (
      <Map
          google={props.google}
          zoom={14}
          style={mapStyles}

          // streetViewControl={false}
          // fullscreenControl= {false}
          // mapTypeControl= {false}
          // zoomControl= {false}

          initialCenter={listing.latLng}
          // onClick={onMapClicked}
          ref={refMap}
          onBoundsChanged={useCallback(handleBoundsChanged,[handleBoundsChanged])} 
      >
          <Marker 
            onClick={onMarkerClick}
            position={listing.latLng}
            // name={listing.name}
            // title={listing.name}
          />
          <InfoWindow
            marker={listing.activeMarker}
            visible={false}
            // visible={listing.showingInfoWindow}
            onClose={onClose}
          >
            <div>
              <h4>{listing.name}</h4>
              {/* <h4>{this.state.selectedPlace.name}</h4> */}
            </div>
          </InfoWindow>
      </Map>
    )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY
})(MapContainer)