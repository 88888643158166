/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux'
import Index from "./Index"

import { getFlags, deleteFlag } from '../routes/FlagAction';
import CustomModal from '../components/Modal/CustomModal';
import ConfirmDialog from '../components/Alert/ConfirmDialog';
import FlagForm from "./Form/FlagForm";
import { timeTable } from "../dummyData";

import { domain } from '../dummyData';
let url =  domain;

const DashboardFlag = (props) => {

    const flagFormRef = useRef()
    const modalRef = useRef()
    const confirmDialogRef = useRef()
    
    const dispatch = useDispatch();

    const [pageData, setPageData] = useState([]);
    const [row, setRow] = useState({});
    const [action, setAction] = useState('Add');

    // const [message, setMessage] = useState('');

    const fetchData = () => {
        dispatch(getFlags())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageData(editableData);
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const deleteRow = () => {
        dispatch(deleteFlag(row.id))
        .then(response =>{

          fetchData();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchData()
    
    }, [])

    const handleOpen = () => {
        modalRef.current.handleOpen();
        sleep(0).then(() => {
            flagFormRef.current.clearData();
        });
        
    };

    const handleDelete = (data) => {
        setRow(data)
        sleep(0).then(() => {
            confirmDialogRef.current.handleOpen();
        });
        
        
    };

    const handleDeleteResponse = () => {
        deleteRow()
    }

    const handleResponse = (success) => {
        // fetchData();
        // sleep(0).then(() => {
        //     modalRef.current.handleClose();
        //     setAction('Add')
        // });
    }

    const handleSubmit = () => {
        flagFormRef.current.handleSubmit();
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleEdit = (data) => {
        setAction('Edit')

        // modalRef.current.handleOpen()
        // .then(() => {
        //     flagFormRef.current.handleEdit(data);
        // });

        modalRef.current.handleOpen()
        sleep(10).then(() => {
          flagFormRef.current.handleEdit(data);
        });
    };
    // const handleEdit = (data) => {
    //     setAction('Edit');
    
    //     modalRef.current.handleOpen();
        
    //     // Ensure flagFormRef is defined before accessing its methods
    //     if (flagFormRef.current) {
    //         sleep(0).then(() => {
    //             flagFormRef.current.handleEdit(data);
    //         });
    //     }
    // };

    const View = (props) => {
        return (
            <>
                <CustomModal 
                    title={action+' Flag Form'} 
                    tableForm={<FlagForm 
                            handleResponse={handleResponse}
                            ref={flagFormRef} 
                        />} 
                    ref={modalRef}
                    handleSubmit={handleSubmit}
                    
                />

                <ConfirmDialog 
                    message={'Are you sure you want to delete this row?'} 
                    ref={confirmDialogRef}
                    handleDeleteResponse={handleDeleteResponse}
                />

                <div className="container-fluid">
            
                                
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Flags</h1>
                        
                        {/* <a href="#" onClick={handleOpen} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Add New
                        </a> */}
                    </div>

                </div>


                <div className="container-fluid">


                    {/* <h1 className="h3 mb-2 text-gray-800">Tables</h1>
                    <p className="mb-4">DataTables is a third party plugin.</p> */}

                    
                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Flags Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Value</th>
                                            <th>Type</th>
                                            <th>Created date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>

                                        {pageData.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.name}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                     : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>
                                                    {row.type === 3
                                                    ? 
                                                    <img alt='ddk' src= { row.url? url+row.url: "./assets/images/logo/holdball.png"} className="rounded-circle mx-auto mt-2 mb-1" height="40" width="40"></img>
                                                    : row.value}

                                                </td>
                                                <td>{
                                                    row.type === 1? 'Text' : (row.type === 2? 'Number': (row.type === 3? 'Image': 'Percentage'))
                                                }</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                <td>
                                                    <i onClick={() => {handleEdit(row)}}  className="fas fa-edit text-success mr-2"></i>
                                                    {/* <i onClick={() => {handleDelete(row)}} className="fas fa-trash text-danger ml-2"></i> */}
                                                </td>
                                            </tr>
                                        ))}
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return (
        <Index view={<View/>} />
    )
}

export default DashboardFlag
