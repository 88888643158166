import React, { useState, useEffect } from "react";
import CoachProfileBanner from "../../components/Banners/CoachBanner";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import { rowData, domainUrl } from "../../dummyData";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import CoachCard from "../../components/Cards/Coach/CoachCard";
import PlayerCard from "../../components/Cards/Player/PlayerCard";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getAllCoaches, getAllPlayers, stripeAccount } from '../../routes/UserAction';
import { getUnreadChats } from '../../routes/ChatAction';



const UserCoachDashboard = () => {

    const dispatch = useDispatch();
    const { user, loading, searchWord } = useSelector((state) => state.persist.user)
    const userData = user;
    const coaches = userData.allCoaches;

    const sessionSli = useSelector((state) => state.session)
    const sLoading = sessionSli.loading;

    const [pageCoachData, setPageCoachData] = useState([]);
    const [pageSessionData, setPageSessionData] = useState([]);
    const [pagePlayerData, setPagePlayerData] = useState([]);

    const [pageNo, setPageNo] = useState(1);

    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const manualStripe = () => {
        // console.log('called')
        dispatch(stripeAccount ('retrieve_payment')).then(response =>{});
        dispatch(stripeAccount ('retrieve_account')).then(response =>{});
    }

    const fetchUnread = () => {
        dispatch(getUnreadChats());
        // .then(response =>{})
    }

    const fetchCoach = () => {
        dispatch(getAllCoaches({cat: 'main', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageCoachData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchPlayer = () => {
        dispatch(getAllPlayers({cat: 'main', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPagePlayerData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    // const handleSearch = (word) => {
    //     dispatch(updateKeyWord(word))
    // }

    useEffect(() => {

        fetchCoach();
        // fetchSession();
        fetchPlayer();

        manualStripe();

        fetchUnread ();

        // console.log('key', searchWord)
    
    }, [searchWord])

    return(
        <>
            <div className="divider"></div>
            <Header page={'home'}/>

            {/* {(loading || sLoading)? ( */}
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>
                {userData?.userType === 4? <>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Coaches</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            <SlickHorizontalScroll slidesToShow={pageCoachData.length<=3? (pageCoachData.length) : null} data={pageCoachData} card={CoachCard}/>
                        </div>
                    </div>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>New Players</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            <SlickHorizontalScroll slidesToShow={pagePlayerData.length<=3? (pagePlayerData.length) : null} data={pagePlayerData} card={PlayerCard}/>
                        </div>
                    </div>
                </> : <>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>New Players</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            <SlickHorizontalScroll slidesToShow={pagePlayerData.length<=3? (pagePlayerData.length) : null} data={pagePlayerData} card={PlayerCard}/>
                        </div>
                    </div>
                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Coaches</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            <SlickHorizontalScroll slidesToShow={pageCoachData.length<=3? (pageCoachData.length) : null} data={pageCoachData} card={CoachCard}/>
                        </div>
                    </div>
                </>}
            

            
            </>}

            <Footer/>
        </>
        
    )
}

export default UserCoachDashboard