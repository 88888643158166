import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


const ConfirmDialog = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(true)
    };

    const handleClose = () => {
        setShow(false)
    };

    const handleResponse = () => {
        props.handleDeleteResponse()
        setShow(false)
    };

    useImperativeHandle(
        ref,
        () => ({
          handleOpen() {handleOpen()},
          handleClose() {handleClose()},
        }),
    )

    return (
        <>
            <Alert show={show} variant="success">
                <Alert.Heading>NextLevel Confirmation Dialog</Alert.Heading>
                <p>
                    {props.message? props.message: ''}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={handleResponse} variant="outline-danger">
                        Yes
                    </Button>
                    <Button onClick={() => setShow(false)} variant="outline-success">
                        No
                    </Button>
                </div>
            </Alert>

            {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}
        </>

    )
})
export default ConfirmDialog