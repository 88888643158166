/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Index from "./Index"
import { getUsers } from '../routes/UserAction';
import { getSessions } from '../routes/SessionAction';
import { getSessionBookings } from '../routes/SessionBookingAction';

const DashboardIndex = (props) => {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)

    // console.log(useSelector((state) => state))

    const { sessions, bookings } = useSelector((state) => state.session)
    const userData = user;

    const [sessionData, setSessionData] = useState(0);
    const [playerData, setPlayerData] = useState(0);
    const [coachData, setCoachData] = useState(0);
    const [bookingData, setBookingData] = useState(0);

    useEffect(() => {
    
        dispatch(getSessions({searchword:'', cat: ''}))
        .then(response =>{
          let resData = response.payload.data;
        //   let resData = sessions;
          const editableData = resData?.map(o => ({ ...o }));
          setSessionData(editableData?.length);
          
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
          console.log(error)
        //   showAlert(error, error)
        })


        dispatch(getSessionBookings())
        .then(response =>{
          let resData = response.payload.data;
        //   let resData = bookings;
          const editableData = resData?.map(o => ({ ...o }));
          setBookingData(editableData?.length);
    
        //   console.log('res', editableData)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
          console.log(error)
        //   showAlert(error, error)
        })


        dispatch(getUsers())
        .then(response =>{
          let resData = response.payload.data;
        //   let resData = user.users;
          const editableData = resData?.map(o => ({ ...o }));

          const ccount = editableData?.filter( el => el.userType ===  3)?.length;
          const pcount = editableData?.filter( el => el.userType ===  4)?.length;

          setCoachData(ccount);
          setPlayerData(pcount);
        //   setPageData({...pageData, coaches: editableData?.map( el => { return el.userType ===  4})?.length});
    
          // console.log('res', response.payload.message)
          // console.log('res', response.payload.success)
          // console.log('res', response.payload.data)
        })
        .catch((error) => {
          console.log(error)
        //   showAlert(error, error)
        })
    
    }, [])

    const View = (props) => {
        return (
            <>
                <div className="container-fluid">
            
                                
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        {/* <select className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <option>Option 1</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                        </select>
                        <a href=".." className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Add New
                        </a> */}
                    </div>

                
                    <div className="row">

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Total Players</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{playerData}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Total Coaches</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{coachData}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Bookings
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-auto">
                                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{bookingData}</div>
                                                </div>
                                                {/* <div className="col">
                                                    <div className="progress progress-sm mr-2">
                                                        <div className="progress-bar bg-info" role="progressbar"
                                                            style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Sessions</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{sessionData}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return (
        <Index view={<View />} />
    )
}

export default DashboardIndex
