import React, { useState, useEffect } from "react"
import "./home.css"
import PageHeader from "./PageHeader"
import { useNavigate, Link } from 'react-router-dom'
import PushNotification from '../components/Alert/PushNotification';
import { domainUrl } from '../dummyData';

const HomePage = () => {

  const [flagData, setFlagData] = useState([
    // {id: 1, name: 'app_email', value: 'info@nextlevel-football.com'},
  ]);

  useEffect(() => {

    let url =  `${domainUrl}/guest_flags`

    fetch(url)
    .then(response => {
      return response.json()
    })
    .then(data => {
      // console.log('tee', data.data)
      setFlagData(data.data)
      PushNotification(data.data?.filter(el => (el.name == 'app-register-push'))?.length? data.data?.filter(el => (el.name === 'app-register-push'))[0]?.value : "Welcome to Next Level Football in case of challenge don't hesitate to contact us", 'contact')
      // setFlagData(data.data)
    })

     

    // PushNotification("Welcome to Next Level Football in case of challenge don't hesitate to contact us", 'contact')
  }, [])
  


  return (
    <div className="main-box" style={{height:'100%', weight: '100%'}}>
      <div className="b-screen" style={{}}>

        <PageHeader screen={'big'}/>


        <div className="d-flex flex-row justify-content-between" style={{}}>
          <div className="d-flex flex-column justify-content-center">
            <Link to={'/'} style={{marginLeft:'20vw'}} className="text-white"><i className="fa fa-arrow-left"></i></Link>
          </div>
          <div className="d-flex flex-column justify-content-center" style={{height: '100vh'}}>
            <h1 className="mx-auto" style={{marginBottom:'0', marginTop:'5px', color:'#F8BC16', fontSize:50}}>Train & Work</h1>
            <h5 className="mx-auto" style={{color:'#FFFFFF', fontSize:25}}>with nextlevel</h5>
            <div className="mx-auto" style={{color:'#FFFFFF', maxWidth:'20vw', fontSize:15, textAlign:'center'}}>

              {flagData?.filter(el => (el.name == 'app-register-text'))?.length? flagData?.filter(el => (el.name === 'app-register-text'))[0]?.value : "find the best coaches in your local area and take your game to the Next Level Coaches - earn money on your own time with Complete flexibility"}
              
            </div>
            <Link to={'/register'} className='my-0 mx-auto'><span style={{backgroundColor:'#F8BC16', color:'#FFFFFF', border:"solid 1px #F8BC16", height:'30px', width:'120px', paddingTop:'6px', marginTop:'25px'}} className="badge rounded-pill mx-auto">
              Get Started
            </span></Link>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <Link to={'/register'} style={{marginRight:'20vw'}} className="text-white"><i className="fa fa-arrow-right"></i></Link>
          </div>
        </div>

      </div>

      {/* mobile */}
      <div className="m-screen" style={{height: '100%'}}>

        <PageHeader screen={'mobile'}/>

        <div className="d-flex flex-row justify-content-between" style={{height: '100%', weight: '100%'}}>
          <div className="d-flex flex-column justify-content-center">
            <Link to={'/register'} style={{marginLeft:'7vw', color:'#F8BC16'}} className=""><i className="fa fa-arrow-left"></i></Link>
          </div>
          <div className="d-flex flex-column justify-content-center" style={{height: '100vh', weight: '100vw'}}>
            <h1 className="mx-auto" style={{marginBottom:'5px', marginTop:'10px', color:'#F8BC16', fontSize:'10vw'}}>Train & Work</h1>
            <h5 className="mx-auto d-flex justify-content-center" style={{color:'#FFFFFF', fontSize:'7vw'}}>with nextlevel</h5>
            <p className="mx-3" style={{color:'#FFFFFF', fontSize:'6vw', textAlign:'center'}}>
            
              {flagData?.filter(el => (el.name == 'app-register-text'))?.length? flagData?.filter(el => (el.name === 'app-register-text'))[0]?.value : "find the best coaches in your local area and take your game to the Next Level Coaches - earn money on your own time with Complete flexibility"}

            </p>
            <Link to={'/register'} className='my-0 mx-auto'><span style={{backgroundColor:'#F8BC16', color:'#FFFFFF', border:"solid 1px #F8BC16", height:'30px', width:'120px', paddingTop:'6px', marginTop:'25px'}} className="badge rounded-pill mx-auto">
              Get Started
            </span></Link>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <Link to={'/register'} style={{marginRight:'7vw', color:'#F8BC16'}} className=""><i className="fa fa-arrow-right"></i></Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HomePage
