import { useEffect, useState } from "react"
import ErrorDialog from "../Alert/ErrorDialog"
import MapContainer from './MapContainer'
import ViewMapContainer from "./ViewMapContainer"
const CurrentLocation = props => {
    const [location, setLocation] = useState({ 
        lat: 51.501364, 
        lng: -0.141890
    })

    const getMapData = (data) => {
        props.getMapData(data)
    };

    const success = position => {
        const coordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }
        // console.log(position)
        setLocation(coordinates)
        props.getMapData(location)
    }
    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                   navigator.geolocation.getCurrentPosition(success)
                } else {
                    // const errorDialogRef = useRef()
                    // console.log('result', result)
                    return alert('Location cannot be detected!')
                    // <ErrorDialog msg={'Location cannot be detected!'}/>
                }
            });
        }
    },[])

    return (
        props.viewMap? 
        <ViewMapContainer
            viewData={props.viewData? props.viewData: null} 
            viewMap={props.viewMap? props.viewMap:null}
            canChoose={props.canChoose? props.canChoose: true}  
            location={location} 
            getMapData={getMapData}
        />
        :
        <MapContainer
            canChoose={props.canChoose? props.canChoose: true}  
            location={location} 
            getMapData={getMapData}
        />
        

    )
}
export default CurrentLocation