import "./App.css"
import HomePage from "./home/HomePage"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"

import LoginPage from "./home/LoginPage"
import SignUp from "./home/RegisterPage"
import ForgotPassword from "./home/ForgetPasswordPage"
import VerifyCodePage from "./home/ResetCodePage"
import ResetPassword from "./home/ResetPasswordPage"
import EditProfile from "./home/EditProfilePage"

import Checkout from "./Pages/Checkout/Checkout"
import Pricing from "./Pages/Checkout/Pricing"
import Contact from "./Pages/Auth/ContactUs"

import CheckoutForm from "./Pages/Checkout/CheckoutForm"
import PaymentResponse from "./Pages/Checkout/PaymentResponse"
import AccountResponse from "./Pages/Checkout/AccountResponse"
import PaymentResponseF from "./Pages/Checkout/PaymentResponseF"
import NotActive from "./Pages/Checkout/NotActive"

import LandingPage from "./Pages/Users/LandingPage"
import UserCoaches from "./Pages/Users/UserCoaches"
import UserPlayers from "./Pages/Users/UserPlayers"
import UserSessions from "./Pages/Users/UserSessions"
import Service from "./Pages/Users/Services"
import Chat from "./Pages/Users/Chat"
import UserSession from "./Pages/Users/Session"
import UserPlayer from "./Pages/Users/UserPlayer"
import UserCoach from "./Pages/Users/UserCoach"


import Dashboard from "./Dashboard/DashboardIndex"

import Coaches from "./Dashboard/DashboardCoach"
import AContact from "./Dashboard/DashboardContact"
import Levels from "./Dashboard/DashboardLevel"
import Players from "./Dashboard/DashboardPlayer"
import Expertises from "./Dashboard/DashboardExpertise"
import Flags from "./Dashboard/DashboardFlag"
import Packages from "./Dashboard/DashboardPackage"
import Sessions from "./Dashboard/DashboardSession"
import Bookings from "./Dashboard/DashboardBooking"
import SubPackages from "./Dashboard/DashboardSubPackage"
import Subscriptions from "./Dashboard/DashboardSubscription"

import NotFound from "./Pages/Auth/NotFound"

import ProtectedRoute from './routes/ProtectedRoute'

function App() {
  // for inactive mode
  // return (
  //   <Router>
  //     <Routes>
  //       <Route path="*" element={<NotActive/>} />
  //     </Routes>
  //   </Router>
  // );

  return (
    <>
      
      <Router>
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          {/* <Route path='/singlepage/:id' element={<SinglePage />} /> */}
        
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/reset_password' element={<ResetPassword />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path='/verify_code' element={<VerifyCodePage />} />
          <Route path='/payment_response' element={<PaymentResponse />} />
          <Route path='/payment_response_fail' element={<PaymentResponseF />} />
          <Route path='/account_response' element={<AccountResponse />} />
          {/* <Route exact path='/contact' element={<Contact />} /> */}
          
          <Route element={<ProtectedRoute />}>
            <Route exact path='/contact' element={<Contact />} />

            <Route path='/edit_profile' element={<EditProfile />} />
            <Route exact path='/pricing' element={<Pricing />} />
            {/* <Route exact path='/contact' element={<Contact />} /> */}
            {/* <Route exact path='/checkout' element={<Checkout />} /> */}
            <Route path='/checkout' element={<CheckoutForm />} />
          
            <Route exact path='/coach_profile' element={<UserCoach/>} />
            <Route exact path='/landing_page' element={<LandingPage/>} />
            <Route exact path='/player_profile' element={<UserPlayer/>} />
            
            <Route exact path='/coaches' element={<UserCoaches/>} />
            <Route exact path='/players' element={<UserPlayers/>} />
            <Route exact path='/sessions' element={<UserSessions/>} />
            <Route exact path='/service' element={<Service/>} />
            <Route exact path='/chat' element={<Chat/>} />

            <Route exact path='/session' element={<UserSession/>} />
            <Route exact path='/player' element={<UserPlayer/>} />
            <Route exact path='/coach' element={<UserCoach/>} />

            <Route exact path='/index' element={<Dashboard/>} />
            <Route exact path='/a_coaches' element={<Coaches/>} />
            <Route exact path='/a_levels' element={<Levels/>} />
            <Route exact path='/a_players' element={<Players/>} />
            <Route exact path='/a_bookings' element={<Bookings/>} />
            <Route exact path='/a_contacts' element={<AContact />} />
            {/* <Route exact path='/a_alerts' element={<Alerts/>} />
            <Route exact path='/a_cards' element={<Cards/>} />
            <Route exact path='/a_coach_expertises' element={<CoachExpertises/>} />
            <Route exact path='/a_player_expertises' element={<PlayerExpertises/>} />
            <Route exact path='/a_currencies' element={<Currencies/>} />
            <Route exact path='/a_disputes' element={<Disputes/>} /> */}
            <Route exact path='/a_expertises' element={<Expertises/>} />
            <Route exact path='/a_flags' element={<Flags/>} />
            {/* <Route exact path='/a_groups' element={<Groups/>} />
            <Route exact path='/a_group_users' element={<GroupUsers/>} /> */}
            <Route exact path='/a_packages' element={<Packages/>} />
            <Route exact path='/a_sessions' element={<Sessions/>} />
            {/* <Route exact path='/a_session_users' element={<SessionUsers/>} />
            <Route exact path='/a_session_media' element={<SessionMedia/>} /> */}
            <Route exact path='/a_sub_packages' element={<SubPackages/>} />
            <Route exact path='/a_subscriptions' element={<Subscriptions/>} />
            {/* <Route exact path='/a_tags' element={<Tags/>} />
            <Route exact path='/a_training_locations' element={<TrainingLocations/>} />
            <Route exact path='/a_verifications' element={<Verifications/>} />
            <Route exact path='/a_visibilities' element={<Visibilities/>} /> */}
          

          </Route>
          
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </Router>
    </>
  )
}

export default App
