import { createSlice } from '@reduxjs/toolkit';
import {
  getSessions,
  deleteSession,
  showSession,
  editSession,
  createSession,
} from '../routes/SessionAction';
import {
  getSessionBookings,
  deleteSessionBooking,
  showSessionBooking,
  editSessionBooking,
  createSessionBooking,
} from '../routes/SessionBookingAction';
import {
  getVisibilities,
  deleteVisibility,
  showVisibility,
  editVisibility,
  createVisibility,
} from '../routes/VisibilityAction';
import { 
  socketOnSession, 
  socketOnBookSession,
  socketOnVisibility, 
} from '../routes/SocketAction';


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  sessions: null,
  bookings: null,
  session: null,
  mySessions: null,
  myBookings: null,

  visibilities: null,
  myVisibilities: null,
}

// initialState.blockedUsers = [1,2,6,7];
// initialState.newMessages = [0,];

export const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
      selectSession: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.sessions.map( el => el.id ).indexOf(action.payload);
        newState.session = newState.sessions[index];

        // fetch message here
        return newState;
      },
      setMySession: (state, action) => {
        console.log(action);
        let newState = { ...state };
        newState.mySessions = newState.sessions.map( el => { return el.userId ==  action.payload});
        // newState.mySessions = newState.sessions[index];

        // fetch message here
        return newState;
      },
      setMyVisibilities: (state, action) => {
        console.log(action);
        let newState = { ...state };
        newState.myVisibilities = newState.visibilities.map( el => { return el.userId ==  action.payload});
        // newState.mySessions = newState.sessions[index];

        // fetch message here
        return newState;
      },
      setMyBookings: (state, action) => {
        console.log(action);
        let newState = { ...state };
        // let session = newState.sessions.map( el => el.userId ).indexOf(action.payload);
        newState.myBookings = newState.sessions.map( el => { return el.userId ==  action.payload});
        // newState.myBookings = newState.sessions[index];

        // fetch message here
        return newState;
      }
    },

    extraReducers: {
      // on socket visibility
      [socketOnVisibility.pending]: (state) => {
        state.loading = true
      },
      [socketOnVisibility.fulfilled]: (state, { payload }) => {
        const newState = { ...state };
        console.log('visibility', payload)

        const user_id = parseInt(payload.user_id);
        const visibilities = payload.visibilities;

        // if (newState.selectedUser) {
        //   if (user_id === newState.selectedUser.id) {
        //     newState.messages = [];
        //     newState.selectedUser = null;
        //   }
        // }
        newState.visibilities = visibilities;
        newState.loading = false;

        return newState;
      },
      [socketOnVisibility.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // on socket sessions
      [socketOnSession.pending]: (state) => {
        state.loading = true
      },
      [socketOnSession.fulfilled]: (state, { payload }) => {
        const newState = { ...state };
        console.log('sessions', payload)

        const user_id = parseInt(payload.user_id);
        const sessions = payload.sessions;

        // if (newState.selectedUser) {
        //   if (user_id === newState.selectedUser.id) {
        //     newState.messages = [];
        //     newState.selectedUser = null;
        //   }
        // }
        newState.sessions = sessions;
        newState.loading = false;

        return newState;
      },
      [socketOnSession.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // on socket bookings
      [socketOnBookSession.pending]: (state) => {
        state.loading = true
      },
      [socketOnBookSession.fulfilled]: (state, { payload }) => {
        console.log('bookings', payload)
        const newState = { ...state };

        const user_id = parseInt(payload.user_id);
        const bookings = payload.bookings;

        // if (newState.selectedUser) {
        //   if (user_id === newState.selectedUser.id) {
        //     newState.messages = [];
        //     newState.selectedUser = null;
        //   }
        // }
        newState.bookings = bookings;
        // newState.success = true;
        newState.loading = false;

        return newState;
      },
      [socketOnBookSession.rejected]: (state, { payload }) => {
        state.loading = false
      },



      // start
      // showSession
      [showSession.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showSession.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.session = payload.session;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showSession.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editSession
      [editSession.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editSession.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editSession.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createSession
      [createSession.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createSession.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createSession.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteSession
      [deleteSession.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteSession.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteSession.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getSessions
      [getSessions.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getSessions.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.sessions = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getSessions.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getSessionBookings
      [getSessionBookings.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getSessionBookings.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.bookings = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getSessionBookings.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showSessionBooking
      [showSessionBooking.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showSessionBooking.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.booking = payload.booking;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showSessionBooking.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editSessionBooking
      [editSessionBooking.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editSessionBooking.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editSessionBooking.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteSessionBooking
      [deleteSessionBooking.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteSessionBooking.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteSessionBooking.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createSessionBooking
      [createSessionBooking.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createSessionBooking.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.bookings = payload.bookings;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createSessionBooking.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getVisibilities
      [getVisibilities.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getVisibilities.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.visibilities = payload.visibilities;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getVisibilities.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createVisibility
      [createVisibility.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createVisibility.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createVisibility.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editVisibility
      [editVisibility.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editVisibility.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editVisibility.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showVisibility
      [showVisibility.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showVisibility.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.visibility = payload.visibility;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showVisibility.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteVisibility
      [deleteVisibility.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteVisibility.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteVisibility.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      

      // end
    },

  })

// Action creators are generated for each case reducer function
export const { selectSession, setMyBookings, setMySession } = sessionSlice.actions

export default sessionSlice.reducer