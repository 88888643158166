import React, { useState, useEffect } from "react";
import CoachBanner from "../../components/Banners/CoachBanner";
import CoachBannerMy from "../../components/Banners/CoachBannerMy";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import CoachCard from "../../components/Cards/Coach/CoachCard";
import SessionCard from "../../components/Cards/Session/SessionCard";
import PastMultiSessionCard from "../../components/Cards/Session/PastMultiSessionCard";
import MultiSessionCard from "../../components/Cards/Session/MultiSessionCard";
import RemarkCard from "../../components/Cards/Session/RemarkCard";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getAllCoaches, showCoach } from '../../routes/UserAction';

import MyCalendar from "../../components/Calendars/MyCalendar";


const UserCoach = () => {

    const dispatch = useDispatch();
    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    // const userId =new URLSearchParams(search).get("code");

    const { user, searchWord, loading } = useSelector((state) => state.persist.user)
    const userData = user;

    const sessionSli = useSelector((state) => state.session)
    const sLoading = sessionSli.loading;
    const chatSli = useSelector((state) => state.chat)
    const cLoading = chatSli.loading;
    const sysSli = useSelector((state) => state.system)
    const sysLoading = sysSli.loading;

    const [pageCoachData, setPageCoachData] = useState({});
    const [pageCoachesData, setPageCoachesData] = useState([]);
    const [isMine, setIsMine] = useState(false);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchCoaches = () => {
        dispatch(getAllCoaches({cat: 'single', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageCoachesData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const fetchCoach = () => {
        dispatch(showCoach(id))
        .then(response =>{
          setPageCoachData(response.payload.data);

          if (userData.id === response.payload.data?.user?.id) {
            setIsMine(true)
          }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchCoach();
        fetchCoaches();
    
    }, [id])

    return(
        <>
            <div className="divider"></div>
            <Header page={'coach'}/>

            {(loading || sLoading || cLoading || sysLoading) ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>

                {isMine === true? <CoachBannerMy fetchCoach={fetchCoach} data={pageCoachData}/> : <CoachBanner fetchCoach={fetchCoach} data={pageCoachData}/>}

                <div className="d-flex justify-content-center my-2">
                    {pageCoachData?.sessions?
                    <div className="d-flex flex-row">
                        {pageCoachData.expertise?.length > 0 ?
                            pageCoachData.expertise?.map((row) => (
                                <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                            ))
                        : <h5 className="my-3" style={{color:'#16307D', fontSize: 12}}>No Expertise...</h5>}
                    </div>
                    : <></>}
                </div>
                <h6 style={{textAlign:'center', color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>{user.description}</h6>


                
                {isMine === true? <>

                    <div className="p-3">
                        <MyCalendar userType="coach" data={pageCoachData?.sessions} duser={pageCoachData}/>
                    </div>

                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Remarks</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            {pageCoachData?.user?.remarks?
                                <SlickHorizontalScroll slidesToShow={pageCoachData?.user?.remarks?.length<=3? (pageCoachData?.user?.remarks?.length) : null} data={pageCoachData?.user?.remarks} card={RemarkCard}/>
                            : <></>}
                        </div>
                    </div>
                </>:<>
                    <div className="p-3">
                        {/* <MyCalendar userType="player" data={pageCoachData?.sessions?.filter(el => (el.is_private === false || el.member === true))} duser={pageCoachData}/> */}
                        <MyCalendar 
                            userType="player" 
                            data={pageCoachData?.sessions?.filter(el => 
                                el.is_private === false || el.bookings?.some(booking => booking?.user_id === userData?.id)
                            )} 
                            duser={pageCoachData}
                        />
                    </div>

                    <div>
                        <h6 style={{color:'#16307D', marginTop:'1px', marginBottom:'0', marginLeft:"4px"}}>Similar Coaches</h6>
                        <hr className="" style={{color:'#16307D', backgroundColor:'#16307D', height:'2px', marginTop:'1px', marginBottom:'5',}}/>

                        <div className="px-5">
                            <SlickHorizontalScroll slidesToShow={pageCoachesData?.length<=3? (pageCoachesData?.length) : null} data={pageCoachesData} card={CoachCard}/>
                        </div>
                    </div>
                </>}
            </>}

            <Footer/>
        </>
        
    )
}

export default UserCoach