import React from "react";

import { timeFormat, domain } from "../../../dummyData";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { deleteSessionBooking } from "../../../routes/SessionBookingAction";

const PlayerPaidCard = (props) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    // console.log(props.data)

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const handleRemove = () => {
        dispatch(deleteSessionBooking(props.data?.id))
        .then(response =>{
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            {/*  */}
            <div className="row mx-2">
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem"}}>
                    <div className="d-flex justify-content-center my-2" style={{paddingLeft:'5vw'}}>
                        <img style={{width:'20vh', height:'20vh', marginTop:'10px'}} alt='ddk' src={props.data?.user?.image? domain+props.data?.user.image: "./assets/images/1.jpg"} className="rounded-circle"></img>
                        
                        {props.data?.status === 5?
                            <span style={{marginLeft:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"dotted 1px #16307D", height:'20px'}} className="badge py-1 my-1 rounded-pill">Paid</span>
                        :
                            <span onClick={() => {handleRemove()}} style={{marginLeft:'5px', backgroundColor:'#E1F2FE', color:'red', border:"dotted 1px #16307D", height:'20px'}} className="badge py-1 my-1 rounded-pill"><i className="fa fa-close"></i></span>
                        }

                    </div>
                    <div className="d-flex justify-content-center my-2">
                        {(props.data?.user?.user_id === userData.id)? 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>You</h6> 
                        : 
                            <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>{props.data?.user? props.data?.user.first_name: ''} {props.data?.user? props.data?.user.last_name: ''}</h6>
                        }
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#67BEFA', fontSize:12}}>Joined {timeFormat(props.data?.user?.created_at)}</h6>
                    </div>
                    {/* <div className="d-flex flex-row" style={{color:'#000000', fontSize:14, marginTop:'5px'}}>
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-location-dot"></i>
                        {props.data?.user?.address}, {props.data?.user?.country}
                        <h6 style={{ color:'#008BEB', fontSize:10, marginBottom:'0', marginTop:'0', marginLeft:'5px'}} className="py-1">2km near you</h6>
                    </div> */}
                    <hr/>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:12}}>Needs help in:</h6>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="d-flex flex-row">
                            {props.data?.expertise?.length > 0 ?
                                props.data?.expertise?.slice(-2)?.map((row) => (
                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                ))
                            : <h5 style={{color:'#16307D', fontSize: 12}}>No Expertise...</h5>}
                        </div>
                    </div>
                    <span onClick={() => {navigateToRoute(`/player?id=${props.data?.player?.uuid}&&code=${props.data?.user?.uuid}`)}} style={{backgroundColor:'#16307D', border:"solid 1px #16307D", height:'30px', marginTop:'10px'}} className="badge py-2 px-1 my-1 rounded">
                        <i style={{marginRight:'10px'}} className="fa-solid fa-message"></i>
                        View
                    </span>
                    
                </div>
            </div>
        </>
    );

}

export default PlayerPaidCard