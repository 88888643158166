import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { createSubPackage, editSubPackage } from '../../routes/SubPackageAction';
// import { getPackages } from '../../routes/PackageAction';

import ErrorDialog from '../../components/Alert/ErrorDialog';
import CustomAlert from '../../components/Alert/CustomAlert';

const SubPackageForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const errorDialogRef = useRef()
    const alertRef = useRef()

    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    // const [packages, setPackages] = useState([]);

    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        package_id: 0,
        currency: "",
        cost: "",
        status: 0,
    });

    // const fetchData = () => {
    //     dispatch(getPackages())
    //     .then(response =>{
    //       let resData = response.payload.data;
    //       const editableData = resData.map(o => ({ ...o }));
    //       setPackages(editableData);
    //       // console.log('res', response.payload.data)
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
    // }

    useImperativeHandle(
        ref,
        () => ({
        clearData() {clearData()},
        handleEdit(data) {
            handleEdit(data)
        },

        handleSubmit() {
            
            if (package_id !== 0 && description !== '' && name !== '') {
                // console.log('data sent', formData)

                if (edit) {
                    dispatch(editSubPackage(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                } else {
                    dispatch(createSubPackage(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                    
                }
            
            
            } else {
                handleErrorDialog('Please make sure you fill the form correctly')
            }

        }

    }),)


    const handleAlert = (status, message) => {
        setMessage(message)
        setAlertType(status)
        alertRef.current.handleOpen();
    };

    const handleErrorDialog = (message) => {
        setMessage(message)
        errorDialogRef.current.handleOpen();
    };

    const { 
        name,
        description,
        package_id,
        currency,
        cost,
        status,
    } = formData;

    const handleChange = (e) => {
        if (e.target.name === 'status') {
        setFormData({ ...formData, [e.target.name]: e.target.checked? 0: 1 });
        } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleEdit = (data) => {
        // fetchData()
        setEdit(true)
        setFormData(data)

    };

    const clearData = () => {
        // fetchData()
        setFormData({ ...formData, 
            name: "",
            description: "",
            package_id: 0,
            currency: "",
            cost: "",
            status: 0,
        }
    );
    setEdit(false)
  };

  return (
    <>
        <ErrorDialog message={message} ref={errorDialogRef}/>
        <CustomAlert message={message} status={alertType} ref={alertRef}/>

        <form id='sub-package-form'>

            <div className='row'>
                <div className="mb-3 col-6">
                    <label className="form-label">Package</label>
                    <select name='package_id' value={package_id} onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value="">Select Package</option>
                        {props.packages?.length > 0? 
                        props.packages?.map((row) => {
                           return <option key={row.id} value={row.id}>{row.name}</option>
                        })
                        : <></>}
                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">Name</label>
                    <input type="text" value={name} onChange={handleChange} name='name' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter name"/>
                </div>
            </div>

            <div className='row'>
                <div className="mb-3 col-6">
                <label className="form-label">Currency</label>
                    <select name='currency' value={currency} onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value="">Select Currency</option>
                        <option value="usd">USD</option>
                        <option value="£">Euro</option>
                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">Cost</label>
                    <input type="text" value={cost} onChange={handleChange} name='cost' className="form-control form-control-sm" placeholder="enter cost"/>
                </div>
            </div>
            
            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">Description</label>
                    <input type="text" value={description} name='description' onChange={handleChange} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter value"/>
                </div>
                <div className="form-check form-switch col-3 mx-auto">
                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleChange} checked={status===0?true:false}/>
                    <label className="form-check-label">Status</label>
                </div>
            </div>
            
        </form>
        
    </>
  );
})

export default SubPackageForm