import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {checkout} from '../../routes/PaymentAction';

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function CheckoutForm() {
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
        setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
        setMessage(
            "Order canceled -- continue to shop around and checkout when you're ready."
        );
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        // dispatch(checkout({email: 'admin@gmail.com', paymentMode: 'card'}));
        dispatch(checkout({
          email: userData?.email, 
          paymentMode: 'card',
          type: 'subscription',
          id: 1,
        }));
    }

    return message ? (
        <Message message={message} />
    ) : (
      <section>
        <div className="product">
          <img
            src="https://i.imgur.com/EHyR2nP.png"
            alt="The cover of Stubborn Attachments"
          />
          <div className="description">
          <h3>Stubborn Attachments</h3>
          <h5>$20.00</h5>
          </div>
        </div>
        <form onSubmit={handleSubmit} method="POST">
          <button type="submit">
            Checkout
          </button>
        </form>
      </section>
    );
}