/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import {logout} from '../routes/UserAction';

const Index = (props) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    const handleClick = () => { 
        dispatch(logout());
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (userData?.userType !== 1) navigate('/home_page')
    }, [])

    return (
        <div id="page-top">
            
            <div id="wrapper">

                {/*  */}
                
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        
                    
                    <Link  to={'/'} className="sidebar-brand d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <i className="fas fa-laugh-wink"></i>
                        </div>
                        <div className="sidebar-brand-text mx-3">
                            {userData?.firstName? userData.firstName:""}
                            <sup>1</sup>
                        </div>
                    </Link>
        
                    
                    <hr className="sidebar-divider my-0"/>
        
                    
                    <li className="nav-item active">
                        <Link  to={'/index'} className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>
        
                    
                    <hr className="sidebar-divider"/>
        
                    
                    <div className="sidebar-heading">
                        System Management
                    </div>
        
                    
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseTwo">
                            <i className="fas fa-fw fa-cog"></i>
                            <span>System</span>
                        </a>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">System Pages:</h6>
                                <Link  to={'/a_flags'} className="collapse-item">Flags</Link>
                                <Link  to={'/a_expertises'} className="collapse-item">Expertise</Link>
                                <Link  to={'/a_levels'} className="collapse-item">V-Levels</Link>
                            </div>
                        </div>
                    </li>


                    <hr className="sidebar-divider"/>
        
                    
                    <div className="sidebar-heading">
                        Inventory Management
                    </div>
        
                    
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#collapseFour"
                            aria-expanded="true" aria-controls="collapseFour">
                            <i className="fas fa-fw fa-wrench"></i>
                            <span>Inventories</span>
                        </a>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Inventory Pages:</h6>
                                <Link  to={'/a_packages'} className="collapse-item">Packages</Link>
                                <Link  to={'/a_sub_packages'} className="collapse-item">Sub Packages</Link>
                                <Link  to={'/a_subscriptions'} className="collapse-item">Subscriptions</Link>
                                <Link  to={'/a_sessions'} className="collapse-item">Sessions</Link>
                                <Link  to={'/a_bookings'} className="collapse-item">Bookings</Link>
                            </div>
                        </div>
                    </li>
        
                    {/* <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                            <i className="fas fa-fw fa-wrench"></i>
                            <span>Test</span>
                        </a>
                        <div className="collapse collapse-horizontal" id="collapseWidthExample">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Packages:</h6>
                                <a className="collapse-item" href="utilities-color.html">Colors</a>
                                <a className="collapse-item" href="utilities-border.html">Borders</a>
                                <a className="collapse-item" href="utilities-animation.html">Animations</a>
                                <a className="collapse-item" href="utilities-other.html">Other</a>
                            </div>
                        </div>
                    </li> */}
        
                    
                    <hr className="sidebar-divider"/>
        
                    
                    <div className="sidebar-heading">
                        User Management
                    </div>
        
                    
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#collapseThree"
                            aria-expanded="true" aria-controls="collapseThree">
                            <i className="fas fa-fw fa-users"></i>
                            <span>Users</span>
                        </a>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">User Pages:</h6>
                                <Link  to={'/a_players'} className="collapse-item">Players</Link>
                                <Link  to={'/a_coaches'} className="collapse-item">Coaches</Link>
                                <Link  to={'/a_contacts'} className="collapse-item">Contacts</Link>
                                {/* <Link  to={'/a_actions'} className="collapse-item">Action Logs</Link> */}
                            </div>
                        </div>
                    </li>
        
                    
                    <li className="nav-item">
                        <Link  to={'/a_flags'} className="nav-link">
                            <i className="fas fa-fw fa-chart-area"></i>
                            <span>Home Page</span>
                        </Link>
                    </li>
        
                    
                    <hr className="sidebar-divider d-none d-md-block"/>
        
                    
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>
        
                    
                    <div className="sidebar-card d-none d-lg-flex">
                        <img className="sidebar-card-illustration mb-2" src="./assets/images/logo/whitelogo.png" alt="..."/>
                        <p className="text-center mb-2"><strong>Next Level</strong></p>
                    </div>
        
                </ul>
        
                
                <div id="content-wrapper" className="d-flex flex-column">
        
                    
                    <div id="content">
        
                        
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        
                            
                            <button 
                                id="sidebarToggleTop" 
                                className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>
        
                            
                            <form
                                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                <div className="input-group">
                                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                        aria-label="Search" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button">
                                            <i className="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
        
                            
                            <ul className="navbar-nav ml-auto">
        
                                
                                <li className="nav-item dropdown no-arrow d-sm-none">
                                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-search fa-fw"></i>
                                    </a>
                                    
                                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                        aria-labelledby="searchDropdown">
                                        <form className="form-inline mr-auto w-100 navbar-search">
                                            <div className="input-group">
                                                <input type="text" className="form-control bg-light border-0 small"
                                                    placeholder="Search for..." aria-label="Search"
                                                    aria-describedby="basic-addon2"/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>
        
                                
                                <li className="nav-item dropdown no-arrow mx-1">
                                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-bell fa-fw"></i>
                                        
                                        <span className="badge badge-danger badge-counter">3+</span>
                                    </a>
                                    
                                    <div className="dropdown-list dropdown-menu dropdown-menu-left shadow animated--grow-in"
                                        aria-labelledby="alertsDropdown">
                                        <h6 className="dropdown-header">
                                            Alerts Center
                                        </h6>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <div className="mr-3">
                                                <div className="icon-circle bg-primary">
                                                    <i className="fas fa-file-alt text-white"></i>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="small text-gray-500">December 12, 2019</div>
                                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                                            </div>
                                        </a>
                                        <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                                    </div>
                                </li>
        
                                
                                <li className="nav-item dropdown no-arrow mx-1">
                                    <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-envelope fa-fw"></i>
                                        
                                        <span className="badge badge-danger badge-counter">7</span>
                                    </a>
                                    
                                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="messagesDropdown">
                                        <h6 className="dropdown-header">
                                            Message Center
                                        </h6>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <div className="dropdown-list-image mr-3">
                                                <img className="rounded-circle" src="dashboard/img/undraw_profile_1.svg"
                                                    alt="..."/>
                                                <div className="status-indicator bg-success"></div>
                                            </div>
                                            <div className="font-weight-bold">
                                                <div className="text-truncate">Hi there! I am wondering.</div>
                                                <div className="small text-gray-500">Emily Fowler · 58m</div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                                    </div>
                                </li>
        
                                <div className="topbar-divider d-none d-sm-block"></div>
                                
                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userData?.firstName? userData.firstName:""}</span>
                                        <img className="img-profile rounded-circle"
                                            src="dashboard/img/undraw_profile.svg" alt="ll"/>
                                    </a>
                                    

                                    <div className="dropdown-menu dropdown-menu-left shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <a className="dropdown-item" href="#">
                                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Profile
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Settings
                                        </a>
                                        <a className="dropdown-item" href="#">
                                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Activity Log
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a onClick={handleClick} className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </a>
                                    </div>
                                </li>
        
                            </ul>
        
                        </nav>


                        {props.view}


        
                    </div>


                    <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                                <span>&copy; next level {new Date().getFullYear()}</span>
                            </div>
                        </div>
                    </footer>
        
                </div>
        
            </div>
        
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <a className="btn btn-primary" href="login.html">Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default Index
