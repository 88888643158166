import React, { useState, useEffect } from "react";
import CoachProfileBanner from "../../components/Banners/CoachBanner";
import SlickHorizontalScroll from "../../components/Horizontal/SlickHorizontalScroll";
import { rowData } from "../../dummyData";
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getSessions } from '../../routes/SessionAction';

import MyCalendar from "../../components/Calendars/MyCalendar3";


const UserSessions = () => {

    const dispatch = useDispatch();
    // console.log(useSelector((state) => state.session))
    const { searchWord, loading } = useSelector((state) => state.persist.user)
    const { sessions, isloading } = useSelector((state) => state.session)
    const sessionData = sessions;
    const sessionSli = useSelector((state) => state.session)
    const sLoading = sessionSli.loading;

    const [pageData, setPageData] = useState([]);

    const [pageNo, setPageNo] = useState(1);
    const handleLoadPlus = () => { 
        setPageNo(pageNo+1); 
    };
    const handleLoadMinus = () => { 
        setPageNo(pageNo >1? pageNo-1: 1); 
    };

    const fetchData = () => {
        dispatch(getSessions({cat: 'all', searchword: searchWord, page:pageNo}))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setPageData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchData();
    
    }, [])

    return(
        <>
            <div className="divider"></div>
            <Header page={'session'}/>
            {/* <CoachProfileBanner/> */}

            {(loading || sLoading) ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                    {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
                </div>
            ) :<>

                <div className="p-3">
                    {pageData.length? <MyCalendar userType="player" data={pageData.filter(el => (el.is_private === false || el.member === true || el.mine === true))} />: null}
                </div>

            </>}

            <Footer/>
        </>
        
    )
}

export default UserSessions