import React, { useRef } from 'react';
import './banner.css';

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, domain } from "../../dummyData";
import { sendMessage } from "../../routes/ChatAction";
import MediaModal from '../Modal/MediaModal';


const CoachBanner = (props) => {

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        // window.location.href = url;
        // navigate(url);
        // navigate(`/chat?id=${props?.data.user?.uuid}&&type=user`)
    };

    const displayModallRef = useRef()
    const handleDisplay = (title, media) => {
        displayModallRef.current.handleOpen(title, media);
        
    };

    const dispatch = useDispatch();
    const handleSendMessage = () => {
        dispatch(sendMessage({
            msg: "Hi, I am "+props?.data.user?.first_name+'.',
            type: 0,
            to_id: props?.data.user?.id,
        }))
        .then(response =>{
            navigate(`/chat?id=${props?.data.user?.uuid}&&type=user`)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <MediaModal ref={displayModallRef}/>
            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img onClick={() => {handleDisplay(`${props.data?.user?.first_name} ${props.data?.user?.last_name}`, props.data.user?.image? domain+props.data.user?.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data.user?.image? domain+props.data.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.created_at)}</h6>
                            <span style={{marginLeft:'20px',  border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                {props.data?.user?.status === 0? 'Active' : 'In Active'}
                            </span>
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D'}}>Sessions({props.data?.bookings?.length})</h6>
                        <div className="d-flex justify-content-center">
                            <h3 style={{marginBottom:'0', marginTop:'0', color:'#16307D'}}>
                                <i onClick={() => {handleSendMessage()}} style={{}} className="fa-solid fa-message"></i>
                            </h3>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-column">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={() => {handleSendMessage()}}>Contact</span>
                        <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={() => {navigateToRoute(`/coaches`)}}>Coaches</span>
                    </div>
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #16307D", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img alt='ddk' onClick={() => {handleDisplay(`${props.data?.user?.first_name} ${props.data?.user?.last_name}`, props.data.user?.image? domain+props.data.user?.image:"./assets/images/1.jpg")}} src= {props.data?.user?.image? domain+props.data?.user.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20}}>{props.data?.user?.first_name} {props.data?.user?.last_name}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.user?.state? props.data?.user?.state+',': ''} {props.data?.user?.country}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Joined since: {timeFormat(props.data?.user?.createdAt)}</h6>
                        
                    {/* </div> */}
                </div>
                <div className="d-flex justify-content-center">
                    <span style={{ border:"solid 1px #16307D", maxWidth:'150px', color:'#16307D'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        {props.data?.user?.status === 0? 'Active' : 'In Active'}
                    </span>
                </div>
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D'}}>Sessions({props.data?.bookings?.length})</h6>
                    <div className="d-flex justify-content-center">
                        <h3 style={{marginBottom:'0', marginTop:'0', color:'#16307D'}}>
                            <i onClick={() => {handleSendMessage()}} style={{}} className="fa-solid fa-message"></i>
                        </h3>
                    </div>
                    
                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={() => {handleSendMessage()}}>Contact</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={() => {navigateToRoute(`/coaches`)}}>Coaches</span>
                    </div>
                    
                </div>
                
            </div>
            
        </>
    );

}

export default CoachBanner