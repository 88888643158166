import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'


const Guide = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const modalRef = useRef()

    const [edit, setEdit] = useState(false);

    const handleOpen = () => {
        modalRef.current.handleOpen();
        
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    return (
    <>

        <div id='guide'>

            <div className='row'>
                <div className="mb-3 col-6">
                    <label className="form-label">Name</label>
                    <input type="text" value={''} onChange={console.log('hi')} name='name' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter name"/>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">Type</label>
                    <select name='type' value={''} onChange={console.log('hi')} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value="1">Text</option>
                        <option value="2">Number</option>
                        <option value="3">%</option>
                    </select>
                </div>
            </div>
            
        </div>
        
    </>
  );
})

export default Guide