import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import {  deleteSession, editSession, createSession } from "../../routes/SessionAction";

import CustomAlert from '../Alert/CustomAlert';

const CreateSessionModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [coachState, setCoachState] = useState(props.cuser?.state || false);

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  // console.log('d', props?.title)
  // console.log('allp', props)
  const dispatch = useDispatch();

  const customAlertRef = useRef()

  const { user } = useSelector((state) => state.persist.user)
  const { loading, error, successMessage } = useSelector((state) => state.session)
  const userData = user;

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  const [sessionFormData, setSessionFormData] = useState({
    name: '',
    day: props?.eDate? timeTable(props?.eDate): '',
    description: '',
    max_booking: 1,
    min_booking: 1,
    training_location_id: 0,
    start_at: '',
    end_at: '',
    image: '',
    is_private: 1,
    is_online: 0,
    total: '0.00',
    tax: '0.00',
    coach_id: props.cuser?.id,
    status: 0,
  });

  const [coachUser, setCoachUser] = useState(props.cuser || null);

  const handleSessionFormChange = (e) => {
    if (e.target.name === 'status') {
        setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 0: 1 });
    } else if (e.target.name === 'is_private') {
        setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 1: 0 });
    } else if (e.target.name === 'is_online') {
        setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.checked? 1: 0 });
    } else if(e.target.name === 'image') {

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setSessionFormData({ ...sessionFormData, [e.target.name]: file });
        } else {
            setSessionFormData({ ...sessionFormData, [e.target.name]: '' });
            console.error("No file selected");
        }
    } else if (e.target.name === 'start_at') {
      const date = timeTable(props?.eDate) || ''; 
      const combinedDateTime = `${date}T${e.target.value}:00.000Z`;
      // console.log('com', combinedDateTime)
      setSessionFormData({ ...sessionFormData, [e.target.name]: combinedDateTime });
    } else if (e.target.name === 'end_at') {
      const date = timeTable(props?.eDate) || ''; 
      const combinedDateTime = `${date}T${e.target.value}:00.000Z`;
      setSessionFormData({ ...sessionFormData, [e.target.name]: combinedDateTime });
    } else {
      setSessionFormData({ ...sessionFormData, [e.target.name]: e.target.value });
    }
  }

  const submitData = () => {
    // console.log('newd', sessionFormData)
    if(coachUser?.account === 5) {
      if(sessionFormData.name === '') {
          return customAlertRef.current.handleOpen("Form filled incorrectly", 'danger');
      } else {
        return sessionFormData.id > 0? 
          dispatch(editSession({...sessionFormData, is_online:sessionFormData.is_online? 1: 0, is_private:sessionFormData.is_private? 1: 0}))
          : dispatch(createSession(sessionFormData))
          .then(response =>{
            if (error) {
              customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
          })
        .catch((error) => {
          console.log(error)
          customAlertRef.current.handleOpen(error, 'danger');
        })
      }
      
    } else {
      customAlertRef.current.handleOpen("Sorry it seems you haven't activated payment", 'danger');
    }
    
  }


  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props?.eDate? timeTable(props?.eDate): 'View'}</Modal.Title> */}
          <Modal.Title>{props?.title?props?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {coachUser?.state? <div className="d-flex justify-content-center my-2 flex-column">
            <input value={sessionFormData.name} onChange={handleSessionFormChange} className="form-control form-control-sm" required type="text" placeholder="Name" name='name'/> 

            <input onChange={handleSessionFormChange} className="form-control form-control-sm mt-1" type="file" placeholder="image" name='image' required/>

            <input value={sessionFormData.description? sessionFormData.description: ''} onChange={handleSessionFormChange} className="form-control form-control-sm mt-1" type="text" placeholder="about" name='description' required/>

            <input value={sessionFormData.end_at.split('T')[1]?.slice(0, 5) || ''} onChange={handleSessionFormChange} required className="form-control form-control-sm mt-1" type="time" name='end_at' placeholder="from"/>

            <input value={sessionFormData.start_at.split('T')[1]?.slice(0, 5) || ''} onChange={handleSessionFormChange} required className="form-control form-control-sm mt-1" name='start_at' type="time" placeholder="to"/>

            <input value={sessionFormData.total} onChange={handleSessionFormChange} required className="form-control form-control-sm mt-1" name='total' type="text" placeholder="cost"/>

            <input value={sessionFormData.max_booking?sessionFormData.max_booking: 1} onChange={handleSessionFormChange} className="form-control form-control-sm mt-1" type="number" placeholder="max_booking" name='max_booking'/>

            <div className="mb-3">
              {/* <label className="form-label">Training Location</label> */}
              <select value={sessionFormData.training_location_id? sessionFormData.training_location_id: ''} name='training_location_id' onChange={handleSessionFormChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                  <option value="">Select Location</option>
                  {coachUser?.trainingLocations?.length > 0? 
                  coachUser?.trainingLocations?.map((row) => {
                  return <option key={row.id} value={row.id}>{row.description
                  }</option>
                  })
                  : <></>}
              </select>
            </div>

            <div className='d-flex justify-content-center my-2 mx-auto flex-row'>
              <div className="form-check form-check-inline col-6">
                <input checked={sessionFormData.is_private === 1? true: false} onChange={handleSessionFormChange} className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" name='is_private'/>
                <label className="form-check-label" for="inlineCheckbox1">Private</label>
              </div>
              <div className="form-check form-check-inline col-6">
                <input checked={sessionFormData.is_online === 1? true: false} onChange={handleSessionFormChange} className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" name='is_online'/>
                <label className="form-check-label" for="inlineCheckbox2">Online</label>
              </div>
            </div>
            
          </div>: <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'10px', marginTop:'0', color:'red', textAlign:'center'}}>Please subscribe to create sessions</h6>}

        </Modal.Body>

        <Modal.Footer>
          <div className=''>
            {/* <span style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
              <i className="fa fa-save"></i>
            </span>
            <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle ml-2">
              <i className="fa fa-close"></i>
            </span> */}

            {coachUser?.state? <span onClick={submitData} style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              Submit
            </span>: null}
            <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>

          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default CreateSessionModal