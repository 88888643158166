import React from "react";

const CoachCardTwo = () => {

    return (
        <>

            {/*  */}
            <div className="row mx-2">
                <div className="card text-bg-white mb-3 mx-2" style={{maxWidth:"18rem"}}>
                    <div className="d-flex justify-content-center my-2" style={{paddingLeft:'5vw'}}>
                        <img style={{width:'20vh', height:'20vh', marginTop:'10px'}} alt='ddk' src="./assets/images/1.jpg" className="rounded-circle"></img>
                        <span style={{marginLeft:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"dotted 1px #16307D", height:'20px'}} className="badge py-1 my-1 rounded-pill">$ Paid</span>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:14}}>Muhammed Jamiu</h6>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#67BEFA', fontSize:12}}>created 2days ago</h6>
                    </div>
                    <div className="d-flex flex-row" style={{color:'#000000', fontSize:14, marginTop:'5px'}}>
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-location-dot"></i>
                        Manchester Park, UK
                        <h6 style={{ color:'#008BEB', fontSize:10, marginBottom:'0', marginTop:'0', marginLeft:'5px'}} className="py-1">2km near you</h6>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-center my-2">
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#000000', fontSize:12}}>Needed help in:</h6>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="d-flex flex-row">
                            <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Passing</span>
                            <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Kicking</span>
                            <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Ball</span>
                        </div>
                    </div>
                    <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", height:'30px', marginTop:'10px'}} className="badge py-2 px-1 my-1 rounded">
                        <i style={{marginRight:'10px'}} className="fa-solid fa-message"></i>
                        Send Proposal
                    </span>
                    
                </div>
            </div>
        </>
    );

}

export default CoachCardTwo