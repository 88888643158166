import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column vh-100 bg-primary">
        <h1 className="display-1 fw-bold text-white">404</h1>
        <h4 className="text-white">Sorry page not found</h4>
        <Link to={'/'} className="btn btn-danger" role="button">
          Back To Home
        </Link>
    </div>
    
  );
}