import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {contact} from '../../routes/UserAction';
import CustomAlert from '../Alert/CustomAlert';
import { domainUrl } from '../../dummyData';
import { Link, useNavigate } from "react-router-dom";
import TermsModal from '../Modal/TermsModal';
import Guide from "../../Dashboard/Form/Guide";

const Footer = () => {

    const { loading, user, error, success, successMessage } = useSelector((state) => state.persist.user)
    const dispatch = useDispatch();
    const customAlertRef = useRef()

    const guideRef = useRef()
    const modalRef = useRef()
    const handleOpen = () => {
        modalRef.current.handleOpen();
        
    };

    // const navigate = useNavigate();
    // const navigateToRoute = (url) => {
    //     // 👇️ navigate to /contacts
    //     navigate(url);
    // };

    const [errorMsg, setErrorMsg] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        msg: "",
        email: "",
    });

    const {
        name, 
        email,
        msg,
    } = formData;

    const [flagData, setFlagData] = useState([
        // {id: 1, name: 'app_email', value: 'info@nextlevel-football.com'},
    ]);
    
    useEffect(() => {

        let url =  `${domainUrl}/guest_flags`

        fetch(url)
        .then(response => {
            return response.json()
        })
        .then(data => {
            // console.log('tee', data.data)
            setFlagData(data.data)
        })
    }, [])

    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitting(true);
        
        if (email !== '' && name !== '' && msg!= '' ) {
        
            dispatch(contact(formData))
            .then(response =>{
                setSubmitting(true);


                if (error) {
                    // console.log('er', error)
                    setErrorMsg(error);
                    customAlertRef.current.handleOpen(error, 'danger');
                    setSubmitting(false);
                
                } else {
                    if (successMessage !== '') {
                        customAlertRef.current.handleOpen(successMessage, 'success');
                        setFormData({
                            name: "",
                            msg: "",
                            email: "",
                        });
                        setSubmitting(false);
                    }
                }
            }).catch((error) => {
                setSubmitting(false);
                customAlertRef.current.handleOpen(error, 'danger');
            });
        } else {
            customAlertRef.current.handleOpen('Form filled incorrectly!', 'danger');
            setSubmitting(false);
        }
    };

    return (
        loading ? (
            <div className="loader-container">
                <div className="spinner"></div>
                {/* <h6 style={{color: 'white', textAlign:'center'}}>loading please wait...</h6> */}
            </div>
        ) :
        <>
            <TermsModal 
                title={'Terms of service'}
                // tableForm={<Guide 
                // ref={guideRef} 
                // />} 
                ref={modalRef}
                // handleSubmit={''}
                
            />
            <footer style={{backgroundColor: '#16307D'}} className="text-center text-lg-start border border-blue mt-xl-5 pt-4">
                
                <div style={{backgroundColor: '#16307D'}} className="container p-4">
                
                    <div className="row">
                        
                        <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                            <h5 className="text-uppercase text-white mb-4">OUR WORLD</h5>

                            <ul className="list-unstyled mb-4">
                                <li className="mb-2">
                                    <Link to={'/contact'} className="text-white" data-abc="true">Contact | </Link>
                                    <Link to={'/contact'} className="text-white" data-abc="true">About US</Link>
                                </li>
                                <li className="mb-2">
                                    <a onClick={handleOpen} href="#!" className="text-white">Terms & Conditions</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#!" className="text-white">Mobile App</a>
                                </li>
                                <li className="mb-2">
                                    <Link className="text-white" to={'/pricing'}>Subscribe</Link>
                                </li>
                                
                                <li className="d-flex justify-content-center">
                                    <div className="d-flex my-4 align-items-center flex-row">
                                        <a href={flagData?.filter(el => (el.name == 'app-facebook-link'))?.length? flagData?.filter(el => (el.name === 'app-facebook-link'))[0]?.value : ''}><i className="fa-brands fa-xl text-white fa-facebook"></i></a>

                                        <a href={flagData?.filter(el => (el.name == 'app-twitter-link'))?.length? flagData?.filter(el => (el.name === 'app-twitter-link'))[0]?.value : ''}><i className="fa-brands fa-xl text-white fa-twitter mx-3"></i></a>

                                        <a href={flagData?.filter(el => (el.name == 'app-linkedin-link'))?.length? flagData?.filter(el => (el.name === 'app-linkedin-link'))[0]?.value : ''}><i className="fa-brands fa-xl text-white fa-linkedin"></i></a>

                                        <a href={flagData?.filter(el => (el.name == 'app-instagram-link'))?.length? flagData?.filter(el => (el.name === 'app-instagram-link'))[0]?.value : ''}><i className="fa-brands fa-xl text-white fa-instagram mx-3"></i></a>

                                        <a href={flagData?.filter(el => (el.name == 'app-store-link'))?.length? flagData?.filter(el => (el.name === 'app-store-link'))[0]?.value : ''}><i className="fa fa-xl text-white fa-store"></i></a>
                                        
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                            <img className="mb-2" src="./assets/images/logo/whitelogo.png" alt="" width="200" height="200"></img>
                        </div>
                        
                        {/* <div className="col-lg-3 col-md-4 mb-4 mb-lg-0">
                            <h5 className="text-uppercase text-white mb-4">Careers</h5>

                            <ul className="list-unstyled">
                                <li>
                                    <a href="#!" className="text-white">Jobs</a>
                                </li>
                            </ul>
                        </div> */}
                        
                        <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                            <h5 className="text-uppercase text-white mb-4">Contact us</h5>

                            <CustomAlert ref={customAlertRef} status={'danger'} msg={errorMsg}/>
                            <form onSubmit={handleSubmit}>
                                <div className="form-outline form-white mb-1">
                                    <input onChange={handleChange} value={name} type="text" id="form5Example5" className="form-control" placeholder="Name" name='name' required/>
                                </div>
                                <div className="form-outline form-white mb-1">
                                    <input onChange={handleChange} value={email} type="email" id="form5Exampless" name='email' className="form-control" placeholder="Email" required/>
                                </div>
                                <div className="form-outline">
                                    
                                    <textarea onChange={handleChange} value={msg} placeholder="Message" name="msg" id="msg" className="form-control mb-1" rows="3" required="required"></textarea>
                                </div>
                                <button disabled={submitting} className="btn btn-sm btn-primary" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                
                <div style={{backgroundColor:'#102669'}} className="text-center p-2 border-top border-blue">
                    {/* © 2020 Copyright: */}
                    <small className="d-block mb-1 text-white">&copy; {new Date().getFullYear()}
                        <a className="text-white" href="https://nextlevel-football.com/"> next level</a>
                    </small>
                </div>
            </footer>
        </>
    )
}

export default Footer