/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Index from "./Index"
import { getContacts, createContact, editContact } from '../routes/ContactAction';

import ConfirmDialog from '../components/Alert/ConfirmDialog';
import { timeTable } from "../dummyData";

const DashboardCoach = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.persist.user)
    const userData = user;

    const confirmDialogRef = useRef()

    const [pageData, setPageData] = useState([]);
    const [row, setRow] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [filter, setFilter] = useState('1');

    const fetchData = () => {
        dispatch(getContacts())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          
          setPageData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const editRow = () => {
        dispatch(editContact(row))
        .then(response =>{

          fetchData();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {

        fetchData()
    
    }, [])

    const handleDelete = (data) => {
        setRow(data)
        sleep(0).then(() => {
            confirmDialogRef.current.handleOpen();
        });
        
        
    };

    const handleDeleteResponse = () => {
        editRow()
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function getStartOfWeek(date) {
        const dt = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const offset = dt.getDate() - (dt.getDay() === 0 ? 6: dt.getDay() - 1);
        return new Date(dt.setDate(offset));
    }
    
    function getStartOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1)
    }
    
    function filterThisWeek(data, referenceDate = new Date()) {
        const lowerThreshold = getStartOfWeek(referenceDate);
        return data.filter(d => d.date >= lowerThreshold);
    }

    function filterThisMonth(data, referenceDate = new Date()) {
        const lowerThreshold = getStartOfMonth(referenceDate);
        const upperThreshold = getStartOfWeek(referenceDate);
        return data.filter(d => d.date >= lowerThreshold && d.date < upperThreshold);
    }

    const handleFilter = (e) => {
        if (e.target.name === 'filter') {
            setFilter(e.target.value);

            switch (e.target.value) {
                case '1':
                    return setFilterData(pageData);
                case '2':
                    return setFilterData(pageData);
                    // return setFilterData(pageData?.map( el => { return el.country === 1}));
                case '3':
                    return setFilterData(pageData);
                case '4':
                    return setFilterData(pageData);
                default: return setFilterData(pageData);
            }
        }
    };

    const View = (props) => {
        return (
            <>
                <ConfirmDialog 
                    message={'Are you sure you want to delete this row?'} 
                    ref={confirmDialogRef}
                    handleDeleteResponse={handleDeleteResponse}
                />
                <div className="container-fluid">
            
                                
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Contacts</h1>
                        {/* <select name="filter" value={filter} onChange={handleFilter} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"> 
                        */}
                        {/* <select className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <option>All</option>
                            <option>This week</option>
                            <option>This Month</option>
                            <option>This Week</option>
                        </select>
                        <a href=".." className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                            <i className="fas fa-download fa-sm text-white-50"></i> Add New
                        </a> */}
                    </div>

                </div>




                <div className="container-fluid">


                    {/* <h1 className="h3 mb-2 text-gray-800">Tables</h1>
                    <p className="mb-4">DataTables is a third party plugin.</p> */}

                    
                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Coaches Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                                <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Msg</th>
                                            <th>Start date</th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {filter > 1? filterData.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.name}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                     : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{row.msg}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                {/* <td>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-trash text-danger ml-2"></i>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-trash text-danger ml-2"></i>
                                                </td> */}
                                            </tr>
                                        )) :
                                        pageData.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.name}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                     : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{row.msg}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                {/* <td>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-edit text-danger ml-2"></i>
                                                    <i onClick={() => {handleDelete(row)}} className="fas fa-edit text-danger ml-2"></i>
                                                </td> */}
                                            </tr>
                                        ))
                                        }
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    return (
        <Index view={<View/>} />
    )
}

export default DashboardCoach
