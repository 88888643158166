import { createSlice } from '@reduxjs/toolkit';
import { 
  signIn, 
  logout, 
  signUp,
  editUser,
  getUsers,
  deleteAccount,
  showAccount,
  getCountryCoaches,
  getAllCoaches,
  getCountryPlayers,
  getAllPlayers,
  updatePlayer,
  updateCoach,
  getPlayer,
  getCoach,
  showPlayer,
  showCoach,
  contact,
  deleteStripeAccount,
  forgotPassword,
  verifyCode,
  resetPassword,
  // stripeAccount,
} from '../routes/UserAction';
import { 
  socketOnUser,
} from '../routes/SocketAction';

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  searchWord: '',

  users: null,

  allUsers: null,

  player: null,
  coach: null,

  countryPlayers: null,
  countryCoaches: null,

  statePlayers: null,
  stateCoaches: null,

  cityPlayers: null,
  cityCoaches: null,

  allPlayers: null,
  allCoaches: null,
  
  userCoach: null,
  userPlayer: null,
  user: null,
}

// initialState.blockedUsers = [1,2,6,7];
// initialState.selectedUser = initialState.users[0];
// initialState.messages = messages;
// console.log(initialState.messages)
// initialState.newMessages = [0,];

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
      updateProg: (state, action) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      updateKeyWord: (state, action) => {
        // console.log('action', action.payload);
        let newState = { ...state };
        let newWord = action.payload;
        newState.searchWord = newWord;
        return newState;
      },

      selectUser: (state, action) => {
        // console.log(action);
        let newState = { ...state };
        let index = newState.users.map( el => el.id ).indexOf(action.payload);
        newState.selectedUser = newState.users[index];

        // fetch message here
        return newState;
      },

      selectPlayer: (state, action) => {
        let newState = { ...state };
        let player = null;
        if ( newState.countryPlayers !== null ) {
        let index= newState.countryPlayers.map( el => el.id ).indexOf(action.payload);
          newState.player = newState.users[index];
        } else {
        let index= newState.allPlayers.map( el => el.id ).indexOf(action.payload);
          newState.player = newState.users[index];
        }
        return newState;
      },

      filterUserPlayer: (state, action) => {
        let newState = { ...state };
        let player = null;
        if ( newState.countryPlayers !== null ) {
        let index= newState.countryPlayers.map( el => el.id ).indexOf(action.payload);
          newState.userPlayer = newState.users[index];
        } else {
        let index= newState.allPlayers.map( el => el.id ).indexOf(action.payload);
          newState.userPlayer = newState.users[index];
        }
        return newState;
      },

      selectCoach: (state, action) => {
        let newState = { ...state };
        let coach = null;
        if ( newState.countryCoaches !== null ) {
        let index= newState.countryCoaches.map( el => el.id ).indexOf(action.payload);
          newState.coach = newState.users[index];
        } else {
        let index= newState.allCoaches.map( el => el.id ).indexOf(action.payload);
          newState.coach = newState.users[index];
        }
        return newState;
      },

      filterUserCoach: (state, action) => {
        let newState = { ...state };
        let Coach = null;
        if ( newState.countryCoaches !== null ) {
        let index= newState.countryCoaches.map( el => el.id ).indexOf(action.payload);
          newState.userCoach = newState.users[index];
        } else {
        let index= newState.allCoaches.map( el => el.id ).indexOf(action.payload);
          newState.userCoach = newState.users[index];
        }
        return newState;
      },

      filterCountryPlayers: (state, action) => {
        let newState = { ...state };
        if ( newState.allPlayers !== null ) {
          newState.countryPlayers = newState.allPlayers.map( el => { return el.country == action.payload} );
        } else {
          newState.countryPlayers = newState.countryPlayers.map( el => { return el.country == action.payload} );
        }
        
        return newState;
      },
      filterCountryCoaches: (state, action) => {
        let newState = { ...state };
        if ( newState.allCoaches !== null ) {
          newState.countryCoaches = newState.allCoaches.map( el => { return el.country == action.payload} );
        } else {
          newState.countryCoaches = newState.countryCoaches.map( el => { return el.country == action.payload} );
        }
        
        return newState;
      },

      filterStatePlayers: (state, action) => {
        let newState = { ...state };
        if ( newState.allPlayers !== null ) {
          newState.statePlayers = newState.allPlayers.map( el => { return el.state == action.payload} );
        } else {
          newState.statePlayers = newState.statePlayers.map( el => { return el.state == action.payload} );
        }
        
        return newState;
      },
      filterStateCoaches: (state, action) => {
        let newState = { ...state };
        if ( newState.allCoaches !== null ) {
          newState.stateCoaches = newState.allCoaches.map( el => { return el.state == action.payload} );
        } else {
          newState.stateCoaches = newState.stateCoaches.map( el => { return el.state == action.payload} );
        }
        
        return newState;
      },

      filterCityPlayers: (state, action) => {
        let newState = { ...state };
        if ( newState.allPlayers !== null ) {
          newState.cityPlayers = newState.allPlayers.map( el => { return el.city == action.payload} );
        } else {
          newState.cityPlayers = newState.cityPlayers.map( el => { return el.city == action.payload} );
        }
        
        return newState;
      },
      filterCityCoaches: (state, action) => {
        let newState = { ...state };
        if ( newState.allCoaches !== null ) {
          newState.cityCoaches = newState.allCoaches.map( el => { return el.city == action.payload} );
        } else {
          newState.cityCoaches = newState.cityCoaches.map( el => { return el.city == action.payload} );
        }
        
        return newState;
      },
    },

    extraReducers: {
      // on socket user
      [socketOnUser.pending]: (state) => {
        state.loading = true
      },
      [socketOnUser.fulfilled]: (state, { payload }) => {
        console.log('user', payload)
        const newState = { ...state };

        const user_id = parseInt(payload.user_id);
        const users = payload.users;

        if (newState.selectedUser) {
          if (user_id === newState.selectedUser.id) {
            newState.messages = [];
            newState.selectedUser = null;
          }
        }
        newState.users = users;
        // newState.success = true;
        newState.loading = false;

        return newState;
      },
      [socketOnUser.rejected]: (state, { payload }) => {
        state.loading = false
      },

      // edit user
      [editUser.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editUser.fulfilled]: (state, { payload }) => {
        let newState = { ...state };

        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.userToken = payload.token;
        newState.successMessage = 'Updated successfully!';
        
        return newState;
      },
      [editUser.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // forgotPassword
      [forgotPassword.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [forgotPassword.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [forgotPassword.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      // verifyCode
      [verifyCode.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [verifyCode.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [verifyCode.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      // resetPassword
      [resetPassword.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [resetPassword.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = null;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Email sent successfully';

        return newState;
      },
      [resetPassword.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      
      // login user
      [signIn.pending]: (state) => {
        state.loading = true
        state.error = null
      },
      [signIn.fulfilled]: (state, { payload }) => {
        let newState = { ...state };

        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.userToken = payload.token;
        
        return newState;
      },
      [signIn.rejected]: (state, { payload }) => {
        // console.log('pload', payload)
        state.loading = false
        state.error = payload
      },

      // register user
      [signUp.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [signUp.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        // newState.userToken = payload.token;
        newState.successMessage = 'Registered successfully!';

        return newState;
      },
      [signUp.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // contact
      [contact.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [contact.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Sent successfully!';

        return newState;
      },
      [contact.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      
      // get users
      [getUsers.pending]: (state) => {
        state.loading = true
      },
      [getUsers.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        newState.users = payload.users;
        // newState.success = true;
        newState.loading = false;
        
        return newState;
      },
      [getUsers.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // logout
      [logout.pending]: (state) => {
        state.loading = true
      },
      [logout.fulfilled]: (state, { payload }) => {
        localStorage.removeItem('userToken') // delete token from storage
        localStorage.clear();
        // window.location.href = "/login";

        let newState = { ...state };
        newState.user = null;
        // newState.success = true;
        newState.loading = false;
        newState.userToken = null;
        
        return newState;
      },
      [logout.rejected]: (state, { payload }) => {
        state.loading = false
        
        localStorage.removeItem('userToken') // delete token from storage
        localStorage.clear();
        // window.location.href = "/login";
      },

      // deleteAccount
      [deleteAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        localStorage.removeItem('userToken') // delete token from storage
        newState.user = null;
        newState.loading = false;
        newState.userToken = null;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showAccount
      [showAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.user = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getCountryCoaches
      [getCountryCoaches.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCountryCoaches.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.countryCoaches = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCountryCoaches.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getAllCoaches
      [getAllCoaches.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getAllCoaches.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.allCoaches = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getAllCoaches.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getCountryPlayers
      [getCountryPlayers.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCountryPlayers.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.countryPlayers = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCountryPlayers.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getAllPlayers
      [getAllPlayers.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getAllPlayers.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.allPlayers = payload.data;
        newState.success = true;
        newState.loading = false;
    
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getAllPlayers.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // updatePlayer
      [updatePlayer.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [updatePlayer.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [updatePlayer.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // updateCoach
      [updateCoach.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [updateCoach.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userCoach = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [updateCoach.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPlayer
      [getPlayer.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPlayer.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPlayer.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getCoach
      [getCoach.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCoach.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userCoach = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCoach.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPlayer
      [showPlayer.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPlayer.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userPlayer = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPlayer.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showCoach
      [showCoach.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showCoach.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.userCoach = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showCoach.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },


      // delete stripe account
      [deleteStripeAccount.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteStripeAccount.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteStripeAccount.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // stripe account
      // [stripeAccount.pending]: (state) => {
      //   let newState = { ...state };
      //   newState.error = null;
      //   newState.successMessage = '';
      //   newState.success = false;
      //   newState.loading = true;

      //   return newState;
      // },
      // [stripeAccount.fulfilled]: (state, { payload }) => {
      //   // console.log(payload)
      //   let newState = { ...state };
      //   newState.success = true;
      //   newState.loading = false;
      //   newState.successMessage = 'Done successfully!';

      //   return newState;
      // },
      // [stripeAccount.rejected]: (state, { payload }) => {
      //   let newState = { ...state };
      //   newState.error = payload;
      //   newState.successMessage = '';
      //   newState.success = false;
      //   newState.loading = false;

      //   return newState;
      // },
      
      // end
    },

  })

// Action creators are generated for each case reducer function
export const { selectUser, selectPlayer, filterCityCoaches, filterCityPlayers, filterCountryCoaches, filterAllCoaches, filterAllPlayers, filterCountryPlayers, filterPlayer, filterCoach, filterStateCoaches, filterStatePlayers, selectCoach, filterUserCoach, updateKeyWord, updateProg } = userSlice.actions

export default userSlice.reducer