import { createSlice } from '@reduxjs/toolkit';

import {
  getCurrencies,
  deleteCurrency,
  showCurrency,
  editCurrency,
  createCurrency,
} from '../routes/CurrencyAction';
import {
  getPackages,
  deletePackage,
  showPackage,
  editPackage,
  createPackage,
} from '../routes/PackageAction';
import {
  getSubPackages,
  deleteSubPackage,
  showSubPackage,
  editSubPackage,
  createSubPackage,
} from '../routes/SubPackageAction';
import {
  getSubscriptions,
  deleteSubscription,
  showSubscription,
  editSubscription,
  createSubscription,
} from '../routes/SubscriptionAction';
import {
  getPayments,
  deletePayment,
  showPayment,
  editPayment,
  createPayment,
} from '../routes/PaymentAction';
import { 
  socketOnSession,
  socketOnBookSession,
} from '../routes/SocketAction';


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  packages: null,
  package: null,
  subPackages: null,
  subPackage: null,
  subscriptions: null,
  subscription: null,
  currencies: null,
  currency: null,
  payments: null,
  payment: null,
}

// initialState.blockedUsers = [1,2,6,7];
// initialState.newMessages = [0,];

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
      selectPackage: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.packages.map( el => el.id ).indexOf(action.payload);
        newState.package = newState.packages[index];

        // fetch message here
        return newState;
      },
      selectSubPackage: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.subPackages.map( el => el.id ).indexOf(action.payload);
        newState.subPackage = newState.subPackages[index];

        // fetch message here
        return newState;
      },
      selectSubscription: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.subscriptions.map( el => el.id ).indexOf(action.payload);
        newState.subscription = newState.subscriptions[index];

        // fetch message here
        return newState;
      },
      selectCurrency: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.currencies.map( el => el.id ).indexOf(action.payload);
        newState.currency = newState.currencies[index];

        // fetch message here
        return newState;
      },
      selectPayment: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.payments.map( el => el.id ).indexOf(action.payload);
        newState.payment = newState.payments[index];

        // fetch message here
        return newState;
      },
      
    },

    extraReducers: {
      // on socket block
      [socketOnSession.pending]: (state) => {
        state.loading = true
      },
      [socketOnSession.fulfilled]: (state, { payload }) => {
        const newState = { ...state };
        console.log('block', payload)

        const user_id = parseInt(payload.user_id);
        const users = payload.users;

        if (newState.selectedUser) {
          if (user_id === newState.selectedUser.id) {
            newState.messages = [];
            newState.selectedUser = null;
          }
        }
        newState.users = users;
        newState.loading = false;

        return newState;
      },
      [socketOnSession.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // on socket user
      [socketOnBookSession.pending]: (state) => {
        state.loading = true
      },
      [socketOnBookSession.fulfilled]: (state, { payload }) => {
        console.log('user', payload)
        const newState = { ...state };

        const user_id = parseInt(payload.user_id);
        const users = payload.users;

        if (newState.selectedUser) {
          if (user_id === newState.selectedUser.id) {
            newState.messages = [];
            newState.selectedUser = null;
          }
        }
        newState.users = users;
        // newState.success = true;
        newState.loading = false;

        return newState;
      },
      [socketOnBookSession.rejected]: (state, { payload }) => {
        state.loading = false
      },



      // start
      // getPackages
      [getPackages.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPackages.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.packages = payload.packages;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPackages.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createPackage
      [createPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editPackage
      [editPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPackage
      [showPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.package = payload.package;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deletePackage
      [deletePackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deletePackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deletePackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getSubPackages
      [getSubPackages.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getSubPackages.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.subPackages = payload.subPackages;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getSubPackages.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createSubPackage
      [createSubPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createSubPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createSubPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editSubPackage
      [editSubPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editSubPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editSubPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showSubPackage
      [showSubPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showSubPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.subPackage = payload.subPackage;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showSubPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteSubPackage
      [deleteSubPackage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteSubPackage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteSubPackage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getSubscriptions
      [getSubscriptions.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getSubscriptions.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.subscriptions = payload.subscriptions;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getSubscriptions.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createSubscription
      [createSubscription.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createSubscription.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createSubscription.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editSubscription
      [editSubscription.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editSubscription.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editSubscription.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showSubscription
      [showSubscription.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showSubscription.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.subscription = payload.subscription;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showSubscription.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteSubscription
      [deleteSubscription.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteSubscription.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteSubscription.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getCurrencies
      [getCurrencies.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getCurrencies.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.currencies = payload.currencies;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getCurrencies.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createCurrency
      [createCurrency.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createCurrency.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createCurrency.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editCurrency
      [editCurrency.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editCurrency.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editCurrency.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showCurrency
      [showCurrency.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showCurrency.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.currency = payload.currency;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showCurrency.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteCurrency
      [deleteCurrency.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteCurrency.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteCurrency.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getPayments
      [getPayments.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getPayments.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.payments = payload.payments;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getPayments.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createPayment
      [createPayment.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createPayment.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createPayment.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editPayment
      [editPayment.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editPayment.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editPayment.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showPayment
      [showPayment.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showPayment.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.payment = payload.payment;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showPayment.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deletePayment
      [deletePayment.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deletePayment.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deletePayment.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      

      // end
    },

  })

// Action creators are generated for each case reducer function
export const {
  selectCurrency,
  selectPackage,
  selectPayment,
  selectSubPackage,
  selectSubscription,
} = inventorySlice.actions

export default inventorySlice.reducer