import { createSlice } from '@reduxjs/toolkit';
import {
  getChatHistory,
  getUnreadChats,
  deleteChat,
  getMessage,
  sendMessage,
} from '../routes/ChatAction';
import {
  getAlerts,
  deleteAlert,
  showAlert,
  editAlert,
  createAlert,
} from '../routes/AlertAction';
import {
  getNotifications,
  deleteNotification,
  showNotification,
  editNotification,
  createNotification,
} from '../routes/NotificationAction';
import { 
  socketOnChat, 
  socketOnNotification, 
  socketOnAlert,
} from '../routes/SocketAction';


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
  successMessage: '',

  newChat: 0,
  currentType: '',
  currentId: 0,

  alerts: null,
  notifications: null,
  chats: null,

  chat: null,
  alert: null,
  notification: null,

  newMessages: [],
}

// initialState.blockedUsers = [1,2,6,7];
initialState.newMessages = [
  {chatId: 0, times: 0}
];

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
      setNewChatcount: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.newChat = newValue;

        return newState;
      },
      setCurrentType: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentType = newValue;

        return newState;
      },
      setCurrentId: (state, action) => {
        // console.log(action.payload);
        let newState = { ...state };
        let newValue = action.payload;
        newState.currentId = newValue;

        return newState;
      },

      selectChat: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.chats.map( el => el.id ).indexOf(action.payload);
        newState.chat = newState.chats[index];

        // fetch message here
        return newState;
      },

      selectAlert: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.alerts.map( el => el.id ).indexOf(action.payload);
        newState.alert = newState.alerts[index];

        return newState;
      },

      selectNotification: (state, action) => {
        console.log(action);
        let newState = { ...state };
        let index = newState.notifications.map( el => el.id ).indexOf(action.payload);
        newState.notification = newState.notifications[index];

        // fetch message here
        return newState;
      }
    },

    extraReducers: {
      // on socket chats
      [socketOnChat.pending]: (state) => {
        state.loading = true
      },
      [socketOnChat.fulfilled]: (state, { payload }) => {
        const newState = { ...state };
        console.log('chats', payload)

        const user_id = parseInt(payload.user_id);
        const authId = parseInt(payload.authId);
        const chatId = parseInt(payload.chatId);
        const chats = payload.chats;

        if (newState.chat) {
          if (chatId === newState.chat.chatId) {

            newState.messages = chats;
            // return newState;
          } else {
            if (authId === newState.chats.chatId.toId) {
              const newMsg = [...newState.newMessages];
              let unread = 1;
              if (newState.newMessages.map( el => { return el.id === chatId}).length > 0){
                unread = newState.newMessages.filter(item => item === payload.chatId);
              }
              newMsg.filter(item => item !== payload.chatId).push({id:chatId, times: unread});
              newState.newMessages = newMsg;
            }
            
            // newState.newMessages = newState.newMessages.push(user_id);
            // return newState;
          }
        }

        newState.chats = chats;
        newState.loading = false;

        return newState;
      },
      [socketOnChat.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // on socket alert
      [socketOnAlert.pending]: (state) => {
        state.loading = true
      },
      [socketOnAlert.fulfilled]: (state, { payload }) => {
        console.log('alert', payload)
        const newState = { ...state };

        const user_id = parseInt(payload.user_id);
        const alerts = payload.alerts;

        newState.alerts = alerts;
        // newState.success = true;
        newState.loading = false;

        return newState;
      },
      [socketOnAlert.rejected]: (state, { payload }) => {
        state.loading = false
      },
      // on socket notification
      [socketOnNotification.pending]: (state) => {
        state.loading = true
      },
      [socketOnNotification.fulfilled]: (state, { payload }) => {
        console.log('notification', payload)
        const newState = { ...state };

        const user_id = parseInt(payload.user_id);
        const notifications = payload.notifications;

        newState.notifications = notifications;
        // newState.success = true;
        newState.loading = false;

        return newState;
      },
      [socketOnNotification.rejected]: (state, { payload }) => {
        state.loading = false
      },



      // start
      // get unread chats
      [getUnreadChats.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.newChat = 0;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getUnreadChats.fulfilled]: (state, { payload }) => {
        let newState = { ...state };
        // console.log('pp', payload)
        newState.newChat = payload.data;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getUnreadChats.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.newChat = 0;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // sendMessage
      [sendMessage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [sendMessage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [sendMessage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getMessage
      [getMessage.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getMessage.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        let index = newState.chats.map( el => el.id ).indexOf(payload.chatId);
        newState.chat = newState.chats[index];
        // message read
        if(newState.newMessages.length > 0) {
          // if (newState.newMessages.includes(payload.user_id)) {
          if (newState.newMessages.map( el => { return el.id == payload.chatId}).length > 0){
            newState.newMessages = newState.newMessages.filter(item => item !== payload.chatId);
          }
        }

        return newState;
      },
      [getMessage.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteChat
      [deleteChat.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteChat.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteChat.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getChatHistory
      [getChatHistory.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getChatHistory.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.chats = payload.chats;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getChatHistory.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getAlerts
      [getAlerts.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getAlerts.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.alerts = payload.alerts;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getAlerts.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createAlert
      [createAlert.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createAlert.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createAlert.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editAlert
      [editAlert.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editAlert.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editAlert.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showAlert
      [showAlert.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showAlert.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.alert = payload.alert;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showAlert.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteAlert
      [deleteAlert.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteAlert.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteAlert.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // getNotifications
      [getNotifications.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [getNotifications.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.notifications = payload.notifications;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [getNotifications.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // createNotification
      [createNotification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [createNotification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [createNotification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // editNotification
      [editNotification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [editNotification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [editNotification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // showNotification
      [showNotification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [showNotification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        newState.notification = payload.notification;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [showNotification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },

      // deleteNotification
      [deleteNotification.pending]: (state) => {
        let newState = { ...state };
        newState.error = null;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = true;

        return newState;
      },
      [deleteNotification.fulfilled]: (state, { payload }) => {
        // console.log(payload)
        let newState = { ...state };
        // newState.userPlayer = payload.user;
        newState.success = true;
        newState.loading = false;
        newState.successMessage = 'Done successfully!';

        return newState;
      },
      [deleteNotification.rejected]: (state, { payload }) => {
        let newState = { ...state };
        newState.error = payload;
        newState.successMessage = '';
        newState.success = false;
        newState.loading = false;

        return newState;
      },
      

      // end
    },

  })

// Action creators are generated for each case reducer function
export const { selectChat, selectAlert, selectNotification, setNewChatcount, setCurrentId, setCurrentType } = chatSlice.actions

export default chatSlice.reducer