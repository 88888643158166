import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { createPackage, editPackage } from '../../routes/PackageAction';

import ErrorDialog from '../../components/Alert/ErrorDialog';
import CustomAlert from '../../components/Alert/CustomAlert';

const PackageForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    const errorDialogRef = useRef()
    const alertRef = useRef()

    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        currency: "",
        cost: "",
        duration: "",
        duration_value: "1",
        status: 0,
        image: '',
    });

    useImperativeHandle(
        ref,
        () => ({
        clearData() {clearData()},
        handleEdit(data) {
            handleEdit(data)
        },

        handleSubmit() {
            
            if (duration !== '' && description !== '' && name !== '' && currency !== '') {

                if (edit) {
                    dispatch(editPackage(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                } else {
                    dispatch(createPackage(formData))
                    .then(response =>{

                        if (response.payload.success) {
                            handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            props.handleResponse(true);
                        } else {
                            handleAlert('error', 'Error saving data');
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                    
                }
            
            
            } else {
                handleErrorDialog('Please make sure you fill the form correctly')
            }

        }

    }),)


    const handleAlert = (status, message) => {
        setMessage(message)
        setAlertType(status)
        alertRef.current.handleOpen();
    };

    const handleErrorDialog = (message) => {
        setMessage(message)
        errorDialogRef.current.handleOpen();
    };

    const { 
        name,
        description,
        currency,
        cost,
        duration,
        duration_value,
        status,
        image,
    } = formData;

    const handleChange = (e) => {
        if (e.target.name === 'status') {
        setFormData({ ...formData, [e.target.name]: e.target.checked? 0: 1 });
        } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleEdit = (data) => {
        setEdit(true)
        setFormData(data)

    };

    const clearData = () => {
        setFormData({ ...formData, 
            name: "",
            description: "",
            currency: "",
            cost: "",
            duration: "",
            duration_value: "1",
            image: '',
            status: 0,
        }
    );
    setEdit(false)
  };

  return (
    <>
        <ErrorDialog message={message} ref={errorDialogRef}/>
        <CustomAlert message={message} status={alertType} ref={alertRef}/>

        <form id='package-form'>

            <div className='row'>
                <div className="mb-3 col-6">
                    <label className="form-label">Name</label>
                    <input type="text" value={name} onChange={handleChange} name='name' className="form-control form-control-sm" placeholder="enter name"/>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">File</label>
                    <input type="file" value={image} onChange={handleChange} name='image' className="form-control form-control-sm" placeholder="enter image"/>
                </div>
            </div>

            <div className='row'>
                <div className="mb-3 col-6">
                <label className="form-label">Currency</label>
                    <select name='currency' value={currency} onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value="">Select Currency</option>
                        <option value="usd">USD</option>
                        <option value="£">Euro</option>
                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">Cost</label>
                    <input type="text" value={cost} onChange={handleChange} name='cost' className="form-control form-control-sm" placeholder="enter cost"/>
                </div>
            </div>

            <div className='row'>
                <div className="mb-3 col-6">
                    <label className="form-label">Duration</label>
                    <select name='duration' value={duration} onChange={handleChange} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value="">Select Duration</option>
                        <option value="year">Yearly</option>
                        <option value="month">Monthly</option>
                        <option value="week">Weekly</option>
                        <option value="day">Daily</option>
                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">Duration Value</label>
                    <input type="text" value={duration_value} onChange={handleChange} name='duration_value' className="form-control form-control-sm" placeholder="enter duration value"/>
                </div>
            </div>
            
            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">Description</label>
                    <input type="text" value={description} name='description' onChange={handleChange} className="form-control form-control-sm" placeholder="enter description"/>
                </div>
                <div className="form-check form-switch col-3 mx-auto">
                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleChange} checked={status===0?true:false}/>
                    <label className="form-check-label">Status</label>
                </div>
            </div>
            
        </form>
        
    </>
  );
})

export default PackageForm