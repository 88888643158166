import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../dummyData';

let url = 'https://emychatserver.herokuapp.com';
url = 'http://localhost:3003';
url = "http://127.0.0.1:8000/api"

url =  domainUrl

  // on new message
  export const socketOnChat = createAsyncThunk(
    'user/socketOnChat',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_message/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on user
  export const socketOnUser = createAsyncThunk(
    'user/socketOnUser',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_user/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on alert
  export const socketOnAlert = createAsyncThunk(
    'usere/socketOnalert',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_alert/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on notification
  export const socketOnNotification = createAsyncThunk(
    'usere/socketOnnotification',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_notification/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on visibility
  export const socketOnVisibility = createAsyncThunk(
    'usere/socketOnvisibility',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_visibility/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on session
  export const socketOnSession = createAsyncThunk(
    'usere/socketOnsession',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_session/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )

  // on sessionBooking
  export const socketOnBookSession = createAsyncThunk(
    'usere/socketOnsessionBooking',
    async (user_id, { getState, rejectWithValue }) => {
      try {
        // get user data from store
      const { persist } = getState()
      
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${persist.user.userToken}`,
        },
      }
  
        const { data } = await axios.get(`${url}/socket_on_session_booking/${user_id}`, config)

        return data
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )
