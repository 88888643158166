import React, { useState, useEffect, useRef } from "react";

import { setCurrentType, setCurrentId } from '../../redux/ChatSlice';

import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadChats, getChatHistory, sendMessage, editChat, getMessage, deleteChat, leaveGroup, joinMeeting, getMeeting, } from "../../routes/ChatAction";

import { socketOnChat } from '../../routes/SocketAction';
import { getDisputes, editDispute } from "../../routes/DisputeAction";
import { getSessions, showSession } from "../../routes/SessionAction";
import { getUserDeleted, createNewUserDeleted } from "../../routes/UserDeletedAction";
import { checkout } from "../../routes/PaymentAction";
import CustomAlert from '../../components/Alert/CustomAlert';

import { compDate, reduceText, timeFormat, timeFormatNow, timeTable, domain } from "../../dummyData";

import Pusher from 'pusher-js';


const Chat = () => {

    const dispatch = useDispatch();
    const search = useLocation().search;
    const id =new URLSearchParams(search).get("id");
    const type =new URLSearchParams(search).get("type");

    const { user } = useSelector((state) => state.persist.user)
    const { loading, error, successMessage, currentType, currentId } = useSelector((state) => state.chat)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()

    const [isChat, setIsChat] = useState(false);
    const [isMeeting, setIsMeeting] = useState(false);
    const [isDispute, setIsDispute] = useState(false);
    const [isSession, setIsSession] = useState(false);
    const [unreadMessage, setUnreadMessage] = useState(false);

    const [isRequest, setIsRequest] = useState(false);
    const [isPendingRequest, setIsPendingRequest] = useState(false);

    const [chatCurrentData, setChatCurrentData] = useState(null);
    const [sessionCurrentData, setSessionCurrentData] = useState(null);
    const [disputeCurrentData, setDisputeCurrentData] = useState(null);
    const [meetingCurrentData, setMeetingCurrentData] = useState();
    const [groupCurrentData, setGroupCurrentData] = useState();

    const [chatData, setChatData] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [disputeData, setDisputeData] = useState([]);
    const [meetingData, setMeetingData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const [deletedData, setDeletedData] = useState([]);

    const [hideCenter, setHideCenter] = useState(false);
    const [hideRight, setHideRight] = useState(false);
    const [hideLeft, setHideLeft] = useState(false);

    const [newSentMsg, setNewSentMsg] = useState('');
    const [newSentDispute, setNewSentDispute] = useState('');
    const [newSentSession, setNewSentSession] = useState('');

    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedDispute, setSelectedDispute] = useState(null);

    const sockets = () => {
        
        // const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV, {
		// 	cluster: 'ap2'
		// })

        // const pusher = new Pusher("a32e4b36b3aa1003ce1c", {
        //     cluster: 'ap2'
        // })
		// const channel1 = pusher.subscribe('channel_name1');
		// // You can bind more channels here like this
		// // const channel2 = pusher.subscribe('channel_name2')
		// channel1.bind('private_channel_id',function(data) {
		//     console.log(data)
		//     // Code that runs when channel1 listens to a new message
		// })
		
		// return (() => {
		// 	pusher.unsubscribe('channel_name1')
		// 	// pusher.unsubscribe('channel_name2')
		// })
    }

    const socketChat = () => {
        dispatch(socketOnChat(userData.id))
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setChatData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    function updateLocalStorage(id, type) {
    // function updateLocalStorage(id, type, data) {
        localStorage.setItem('currentId', id);
        localStorage.setItem('currentType', type);
        // localStorage.setItem('cdata', JSON.stringify(data));
        // setChatCurrentData(data);
        // handleChangeChat(data);
    }

    const handleChangeChat = (row) => {
        setSelectedChat(row.id)
        setChatCurrentData(row);

        let reqData = row
        row.type = 11 //add to deleted status 1 as read

        dispatch(editChat({...reqData, type: 11}))
        .then(response =>{
            
            dispatch(getChatHistory())
            .then(response =>{
                let resData = response.payload.data;
                setDeletedData(resData.deleted)
                setChatData(resData.chats);

                if (chatCurrentData !== null) {

                    let chatUpdate = resData.chats?.filter(el => el.id === row.id)[0]
                    setChatCurrentData(chatUpdate);
                    moveDown();
                }

                updateLocalStorage(row.id, 'chat')
                dispatch(setCurrentType('chat'))
                dispatch(setCurrentId(row.id))
                fetchUnread();
            })
            .catch((error) => {
                console.log(error)
            })

        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            moveDown();
        });
    }
    const handleChangeDispute = (row) => {
        setDisputeCurrentData(row);
        setSelectedDispute(row.id)

        if (row.chat !== null) {
            let reqData = row.chat
            row.type = 11 //add to deleted status 1 as read

            dispatch(editChat({...reqData, type: 11}))
            .then(response =>{
                
                dispatch(getChatHistory())
                .then(response =>{
                    let resData = response.payload.data;
                    setDeletedData(resData.deleted)
                    setDisputeData(resData.disputes);

                    if (disputeCurrentData !== null) {

                        let disputeUpdate = resData.disputes?.filter(el => el.id === row.id)[0]
                        setDisputeCurrentData(disputeUpdate);
                        moveDown();
                    }
                    updateLocalStorage(row.id, 'dispute')
                    dispatch(setCurrentType('dispute'))
                    dispatch(setCurrentId(row.id))
                    fetchUnread();
                })
                .catch((error) => {
                    console.log(error)
                })

            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                moveDown();
            });
        }
        
    }
    const handleChangeSession = (row) => {
        setSessionCurrentData(row);
        setSelectedSession(row.id)

        if (row.chat !== null) {

            let reqData = row.chat
            row.type = 11 //add to deleted status 1 as read

            dispatch(editChat({...reqData, type: 11}))
            .then(response =>{
                
                dispatch(getChatHistory())
                .then(response =>{
                    let resData = response.payload.data;
                    setDeletedData(resData.deleted)
                    setSessionData(resData.sessions);

                    if (sessionCurrentData !== null) {

                        let sessionUpdate = resData.sessions?.filter(el => el.id === row.id)[0]
                        setSessionCurrentData(sessionUpdate);
                        moveDown();
                    }
                    updateLocalStorage(row.id, 'session')
                    dispatch(setCurrentType('session'))
                    dispatch(setCurrentId(row.id))
                    fetchUnread();
                })
                .catch((error) => {
                    console.log(error)
                })

            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                moveDown();
            });
        }
        
    }
    const handleChangeMeeting = (row) => {
        setMeetingCurrentData(row);
        dispatch(setCurrentType('meeting'))
        dispatch(setCurrentId(row.id))
    }

    const updateMeeting = (row, action) => {
    }

    // const unreadCounter = () => {
    //     chatData?.filter(el => ((!(deletedData?.read?.includes(el.id))) && el.user_id !== userData.id))
    // }

    const fetchUnread = () => {
        dispatch(getUnreadChats());
        // .then(response =>{})
    }

    const messagesEndRef = useRef(null);
    const messagesEndRef2 = useRef(null);
    const moveDown = () => {
        // setUnreadMessage(!unreadMessage)

        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        messagesEndRef2.current?.scrollIntoView({ behavior: 'smooth' });

        // const section1 = document.querySelector( '#move-chat-div' );
        // section1.scrollIntoView( { behavior: 'smooth', block: 'start' } );

        // const section2 = document.querySelector( '#move-chat-div2' );
        // section2.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }

    const fetchChats = () => {
        dispatch(getChatHistory())
        .then(response =>{
            let resData = response.payload.data;
            setChatData(resData.chats);
            setDisputeData(resData.disputes);
            setSessionData(resData.sessions);
            setDeletedData(resData.deleted)

            
            // console.log("ID", currentId)
            // console.log("type", currentType)

            if (chatCurrentData !== null) {
                let chatUpdate = resData.chats?.filter(el => el.id === selectedChat)[0]
                setChatCurrentData(chatUpdate);
                moveDown();
            }
            if (sessionCurrentData !== null) {
                setSessionCurrentData(resData.sessions?.filter(el => el.id === selectedSession)[0])
                moveDown();
            }
            if (disputeCurrentData !== null) {
                setDisputeCurrentData(resData.disputes?.filter(el => el.id === selectedDispute)[0])
                moveDown();
            }
            fetchUnread ();
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const getChat = (id) => {
        setIsPendingRequest(false)
        setIsRequest(false)

        dispatch(getMessage(id))
        .then(response =>{
          let resData = response.payload.data;
          setChatCurrentData(resData);

        //   setIsSession(false)
        //   setIsChat(false)
        //   setIsDispute(false)
        //   setIsMeeting(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const updateChat = (row, action) => {

        // dispatch(getMessage(id))
        // .then(response =>{
        //   let resData = response.payload.data;
        //   setChatCurrentData(resData);

        //   setIsSession(false)
        //   setIsChat(true)
        //   setIsDispute(false)
        //   setIsMeeting(false)
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    }


    const fetchDisputes = () => {
        dispatch(getDisputes())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setDisputeData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const updateDisputes = (row, action) => {
        let reqData = row
        dispatch(editDispute(reqData))
        .then(response =>{
            triggerRefresh()
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const handleCreateInvoice = () => {
        let storedCurrentId = localStorage.getItem('currentId');
        let storedCurrentType = localStorage.getItem('currentType');

        if ((parseInt(storedCurrentId, 10) > 0) && (storedCurrentType === 'dispute')) {
        // if (isDispute === true && disputeCurrentData !== null) {
            if (textField !== '') {
                // Validation: Check if textField is a valid number
                const isValidNumber = !isNaN(textField) && /^\d+(\.\d{1,2})?$/.test(textField);
    
                if (isValidNumber) {
                    setNewSentDispute(textField)
                    dispatch(sendMessage({
                        invoiceable_type: "App\\Models\\Dispute",
                        invoiceable_id: disputeCurrentData?.id,
                        total: textField,
                        msg: `New Refund Invoice.`,
                        type: 5,
                        chat_id: disputeCurrentData.chat?.id,
                        description: `Refund invoice from ${userData?.first_name} to ${disputeCurrentData?.against?.first_name}.`,
                    }))
                    .then(response => {
                        triggerRefresh();
                        setNewSentDispute('')
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                } else {
                    customAlertRef.current.handleOpen('Invalid amount. Please enter a valid amount.', 'danger');
                    // console.log("Invalid amount. Please enter a valid number.");
                }
            }
        }
    }

    const payRefund = (invoice) => {

        if (invoice?.user?.id == userData?.id) {
            createUserDeleted('chat', invoice);
        } else {
            
            let reqData = {}
            dispatch(checkout({
                email: userData?.email, 
                paymentMode: 'card',
                type: 'refund',
                chat_id: invoice?.id,
                invoice_id: invoice?.description, //inv id
                description: `Refund from ${userData?.first_name} to ${invoice?.user?.first_name}.`,
            }))
            .then(response =>{
                // console.log(response.success? response?.message: response)
                if (response.error) {
                    if (response?.payload) {
                        customAlertRef.current.handleOpen(response?.payload, 'danger');
                    }
                }
                // if (error) {
                //     customAlertRef.current.handleOpen(error, 'danger');
                //     console.log(error)
                // }
                triggerRefresh()
            })
            .catch((error) => {
                customAlertRef.current.handleOpen(error, 'danger');
                console.log(error)
            })
        }
        
        
    }

    const handleSendMessage = () => {

        if (isChat === true) {
            let chat_id = chatCurrentData.id;

            if (textField !== '') {
                dispatch(sendMessage({
                    msg: textField,
                    type: 0,
                    chat_id: chat_id,
                }))
                .then(response =>{
                    setNewSentMsg(textField)
                    setTextField('')
    
                    dispatch(getChatHistory())
                    .then(response =>{
                        let resData = response.payload.data;
                        setDeletedData(resData.deleted)
                        setChatData(resData.chats);

                        if (chatCurrentData !== null) {
                            // console.log('cd2', chatCurrentData)

                            let chatUpdate = resData.chats?.filter(el => el.id === chat_id)[0]
                            setChatCurrentData(chatUpdate);
                            moveDown();
                        }
                        setNewSentMsg('')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
    
                    // triggerRefresh()
                })
                .catch((error) => {
                    console.log(error)
                })
            }

        } else if(isSession === true) {
            let session_id = sessionCurrentData?.id;

            if (sessionCurrentData?.chat !== null) {
                if (textField !== '') {
                    dispatch(sendMessage({
                        msg: textField,
                        type: 0,
                        chat_id: sessionCurrentData?.chat?.id,
                    }))
                    .then(response =>{
                        setNewSentMsg(textField)
                        setTextField('')
        
                        dispatch(getChatHistory())
                        .then(response =>{
                            let resData = response.payload.data;
                            setDeletedData(resData.deleted)
                            setSessionData(resData.sessions);
    
                            if (sessionCurrentData !== null) {
                                setSessionCurrentData(resData.sessions?.filter(el => el.id === session_id)[0])
                                moveDown();
                            }
                            setNewSentMsg('')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
        
                        // triggerRefresh()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            } else {
                if (textField !== '') {
                    dispatch(sendMessage({
                        msg: textField,
                        type: 0,
                        session_id: session_id
                    }))
                    .then(response =>{
                        setNewSentMsg(textField)
                        setTextField('')
        
                        dispatch(getChatHistory())
                        .then(response =>{
                            let resData = response.payload.data;
                            setDeletedData(resData.deleted)
                            setSessionData(resData.sessions);
    
                            if (sessionCurrentData !== null) {
                                setSessionCurrentData(resData.sessions?.filter(el => el.id === session_id)[0])
                                moveDown();
                            }
                            setNewSentMsg('')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
        
                        // triggerRefresh()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            }
            
        } else if(isDispute === true) {
            let dispute_id = disputeCurrentData.id;

            if (disputeCurrentData.chat !== null) {
                if (textField !== '') {
                    dispatch(sendMessage({
                        msg: textField,
                        type: 0,
                        chat_id: disputeCurrentData.chat?.id,
                    }))
                    .then(response =>{
                        setNewSentMsg(textField)
                        setTextField('')
        
                        dispatch(getChatHistory())
                        .then(response =>{
                            let resData = response.payload.data;
                            setDeletedData(resData.deleted)
                            setDisputeData(resData.disputes);
    
                            if (disputeCurrentData !== null) {
    
                                setDisputeCurrentData(resData.disputes?.filter(el => el.id === dispute_id)[0])
                                moveDown();
                            }
                            setNewSentMsg('')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            } else {
                if (textField !== '') {
                    dispatch(sendMessage({
                        msg: textField,
                        type: 0,
                    }))
                    .then(response =>{
                        setNewSentMsg(textField)
                        setTextField('')
        
                        dispatch(getChatHistory())
                        .then(response =>{
                            let resData = response.payload.data;
                            setDeletedData(resData.deleted)
                            setDisputeData(resData.disputes);
    
                            if (disputeCurrentData !== null) {
    
                                setDisputeCurrentData(resData.disputes?.filter(el => el.id === dispute_id)[0])
                                moveDown();
                            }
                            setNewSentMsg('')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            }
            
        }
        
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
            // alert(`Enter key pressed!`);
        }
    };

    const fetchSessions = () => {
        dispatch(getSessions())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setSessionData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const getSession = (id) => {
        dispatch(showSession(id))
        .then(response =>{
          let resData = response.payload.data;
          setSessionCurrentData(resData);

        //   setIsSession(true)
        //   setIsChat(false)
        //   setIsDispute(false)
        //   setIsMeeting(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const createPrivateSession = () => {
        // console.log('yessss')
        let storedCurrentId = localStorage.getItem('currentId');
        let storedCurrentType = localStorage.getItem('currentType');

        if ((parseInt(storedCurrentId, 10) > 0) && (storedCurrentType === 'chat')) {
        // if (isChat === true && chatCurrentData !== null) {
            // console.log('yessss2')
            if (textField !== '') {

                // Validation: Check if textField is a valid number
                const isValidNumber = !isNaN(textField) && /^\d+(\.\d{1,2})?$/.test(textField);
    
                if (isValidNumber) {

                    if (chatCurrentData?.user_id == userData.id) { // init by coach
                        if (chatCurrentData?.receiver?.user_type == 4) { // player
                            setNewSentSession(textField)
                            dispatch(sendMessage({
                                total: textField,
                                coach_id: userData.coach?.id,
                                type: 6,
                                msg: `New session.`,
                                description: `New session.`,
                                name: `New session`,
                                chat_id: chatCurrentData?.id,
                                user_id: chatCurrentData?.receiver?.id,
                            }))
                            .then(response =>{
                                triggerRefresh()
                                setNewSentSession('')
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        } else {
                            customAlertRef.current.handleOpen("Sorry the other user isn't a player", 'danger');
                        }
                    } else {
                        if (chatCurrentData?.user?.user_type == 4) { // player
                            setNewSentSession(textField)
                            dispatch(sendMessage({
                                total: textField,
                                coach_id: userData.coach?.id,
                                type: 6,
                                msg: `New session.`,
                                description: `New session.`,
                                name: `New session`,
                                chat_id: chatCurrentData?.id,
                                user_id: chatCurrentData?.user?.id,
                            }))
                            .then(response =>{
                
                                triggerRefresh()
                                setNewSentSession('')
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        } else {
                            customAlertRef.current.handleOpen("Sorry the other user isn't a player", 'danger');
                        }
                    }

                    
                } else {
                    customAlertRef.current.handleOpen('Invalid amount. Please enter a valid amount.', 'danger');
                }
            }
        }
        
    }
    const payPrivateSession = (session) => {

        if (session?.user?.id == userData?.id) {
            createUserDeleted('session', session);
        } else {
        
            dispatch(checkout({
                email: userData?.email, 
                paymentMode: 'card',
                type: 'session',
                id: session?.description, // session_id
                description: `Session ${session.name} booking between coach ${session?.user?.first_name} ${session?.user?.last_name} and player ${userData?.first_name} ${userData?.last_name}.`,
            }))
            .then(response =>{
                if (response.error) {
                    if (response?.payload) {
                        customAlertRef.current.handleOpen(response?.payload, 'danger');
                    }
                }
                triggerRefresh()
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
    const updateSession = (row, type) => {

        // dispatch(getMessage(id))
        // .then(response =>{
        //   let resData = response.payload.data;
        //   setChatCurrentData(resData);

        //   setIsSession(false)
        //   setIsChat(true)
        //   setIsDispute(false)
        //   setIsMeeting(false)
        // })
        // .catch((error) => {
        //     console.log(error)
        // })
    }

    const fetchUserDeleted = () => {
        dispatch(getUserDeleted())
        .then(response =>{
          let resData = response.payload.data;
          const editableData = resData.map(o => ({ ...o }));
          setDeletedData(editableData);
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const createUserDeleted = (type, row) => {
        let deletable_type = ""

        switch (type) {
            case 'chat': 
                deletable_type = "App\\Models\\Chat"
                break
            case 'dispute': 
                deletable_type = "App\\Models\\Dispute"
                break
            case 'session': 
                deletable_type = "App\\Models\\Session"
                break
            case 'meeting': 
                deletable_type = "App\\Models\\Meeting"
                break
            default:
                break
        }

        dispatch(createNewUserDeleted({
            deletable_type: deletable_type,
            deletable_id: row.id,
        }))
        .then(response =>{
          triggerRefresh()
        })
        .catch((error) => {
            console.log(error)
        })
    }



    useEffect(() => {

        fetchChats();

        if (type === 'user') {
            // getChat(id);

            setIsSession(false)
            setIsChat(true)
            setViewOption('chat')
            setIsDispute(false)
            setIsMeeting(false)

        } else if (type === 'meeting') {

            setIsSession(false)
            setIsChat(false)
            setIsDispute(false)
            setIsMeeting(true)
            setViewOption('meeting')

        } else if (type === 'session') {
            // getSession(id);

            setIsSession(true)
            setViewOption('session')
            setIsChat(false)
            setIsDispute(false)
            setIsMeeting(false)

        } else if (type === 'dispute') {
            // getDisputes(id);

            setIsSession(false)
            setIsChat(false)
            setIsDispute(true)
            setViewOption('dispute')
            setIsMeeting(false)

        }


        // Enable pusher logging - don't include this in production
        // Pusher.logToConsole = true;
        const pusher = new Pusher("a32e4b36b3aa1003ce1c", {
            cluster: 'mt1',
            forceTLS: true
        })
		const channel1 = pusher.subscribe('nl-chat');
		channel1.bind('nl-message', function(data) {
		    // console.log('sock', data)
		    // Code that runs when channel1 listens to a new message

            let socketReqType = data.message?.request_type
            let socketReqRowId = parseInt(data.message?.row_id, 10)
            let socketReqUserId = parseInt(data.message?.user_id, 10)
            // socketReqUserId = 3

            // console.log('dif', socketReqUserId+' '+userData.id)
            if ((socketReqType === 'chat') && (socketReqUserId !== userData.id)) {
                // console.log('p---1')
                // if (isChat === true) {
                dispatch(getChatHistory())
                .then(response =>{

                    let storedCurrentId = localStorage.getItem('currentId');
                    let storedCurrentType = localStorage.getItem('currentType');
                    // let storedCdata = localStorage.getItem('cdata');
                    // let cdata = storedCdata ? JSON.parse(storedCdata) : null;

                    let resData = response.payload.data;
                    setDeletedData(resData.deleted)
                    setChatData(resData.chats);

                    // console.log("ID", currentId)
                    // console.log("type", currentType)

                    setChatData(resData.chats);
                    setDisputeData(resData.disputes);
                    setSessionData(resData.sessions);
                    setDeletedData(resData.deleted)

                    
                    // console.log("ID", currentId)
                    // console.log("type", currentType)

                    // console.log("s ID", storedCurrentId)
                    // console.log("s type", storedCurrentType)

                    if ((parseInt(storedCurrentId, 10) > 0) && (storedCurrentType === 'chat')) {
                        // console.log("ccc")
                        let cdata = resData.chats?.filter(el => el.id === parseInt(storedCurrentId, 10))[0];
                        // updateLocalStorage(storedCurrentId, storedCurrentType, cdata);
                        setChatCurrentData(cdata);
                        handleChangeChat(cdata);
                        moveDown();
                        
                    }
                    if ((parseInt(storedCurrentId, 10) > 0) && (storedCurrentType === 'session')) {
                        // console.log("sss")
                        const sdata = resData.sessions?.filter(el => el.id === parseInt(storedCurrentId, 10))[0];
                        setSessionCurrentData(sdata)
                        handleChangeSession(sdata);
                        moveDown();
                        // setSessionCurrentData(resData.sessions?.filter(el => el.id === parseInt(storedCurrentId, 10)))
                    }
                    if ((parseInt(storedCurrentId, 10) > 0) && (storedCurrentType === 'dispute')) {
                        // console.log("ddd")
                        const ddata = resData.disputes?.filter(el => el.id === parseInt(storedCurrentId, 10))[0];
                        setDisputeCurrentData(ddata);
                        handleChangeDispute(ddata);
                        moveDown();
                        // setDisputeCurrentData(resData.disputes?.filter(el => el.id === parseInt(storedCurrentId, 10)))
                    }
                    fetchUnread ();
                })
                .catch((error) => {
                    console.log(error)
                })
                    

                // } else if(isSession === true) {

                //     console.log('--s-1--')   
                //     dispatch(getChatHistory())
                //     .then(response =>{
                //         console.log('--s-2--') 
                //         let resData = response.payload.data;
                //         setDeletedData(resData.deleted)
                //         setSessionData(resData.sessions);

                //         // if (sessionCurrentData !== null) {
                //         //     setSessionCurrentData(resData.sessions?.filter(el => el.id === session_id)[0])
                //         // }

                //         console.log("ID", currentId)
                //         console.log("type", currentType)

                //         if ((parseInt(currentId, 10) > 0) && (currentType === 'session')) {
                //             console.log("ssss")
                //             const sdata = resData.sessions?.filter(el => el.id === parseInt(currentId, 10))[0];
                //             setSessionCurrentData(sdata)
                //             handleChangeSession(sdata);
                //             // setSessionCurrentData(resData.sessions?.filter(el => el.id === parseInt(currentId, 10)))
                //         }
                //         fetchUnread ();
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //     })
                    
                // } else if(isDispute === true) {

                //     dispatch(getChatHistory())
                //     .then(response =>{
                //         let resData = response.payload.data;
                //         setDeletedData(resData.deleted)
                //         setDisputeData(resData.disputes);

                //         // if (disputeCurrentData !== null) {

                //         //     setDisputeCurrentData(resData.disputes?.filter(el => el.id === dispute_id)[0])
                //         // }

                //         console.log("ID", currentId)
                //         console.log("type", currentType)

                //         if ((parseInt(currentId, 10) > 0) && (currentType === 'dispute')) {
                //             console.log("dddd")
                //             const ddata = resData.disputes?.filter(el => el.id === parseInt(currentId, 10))[0];
                //             setDisputeCurrentData(ddata);
                //             handleChangeDispute(ddata);
                //             // setDisputeCurrentData(resData.disputes?.filter(el => el.id === parseInt(currentId, 10)))
                //         }
                //         fetchUnread ();
                        
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //     })

                // } else {
                //     console.log('--none is---') 
                //     dispatch(getChatHistory())
                //     .then(response =>{
                //         let resData = response.payload.data;
                //         setChatData(resData.chats);
                //         setDisputeData(resData.disputes);
                //         setSessionData(resData.sessions);
                //         setDeletedData(resData.deleted);
                //         fetchUnread ();
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //     })
                // }
            } else if ((socketReqType === 'meeting') && (socketReqUserId !== userData.id)) {

            }

		})
		
		return (() => {
			pusher.unsubscribe('nl-chat')
			// pusher.unsubscribe('channel_name2')
		})
    
    }, [id, type])

    const triggerRefresh = () => {
        fetchChats();
    }

    const [textField, setTextField] = useState("");
    const handleTextFieldChange = (e) => {
        setTextField(e.target.value);
    }

    const [viewOption, setViewOption] = useState("");
    const handleChangeView = (e) => {

        setViewOption(e.target.value)
        
        switch (e.target.value) {
            case 'chat': 
                setViewOption('chat')
                setIsSession(false)
                setIsDispute(false)
                setIsMeeting(false)
                return setIsChat(!isChat)
            case 'dispute': 
                setViewOption('dispute')
                setIsSession(false)
                setIsChat(false)
                setIsMeeting(false)
                return setIsDispute(!isDispute)
            case 'session': 
                setViewOption('session')
                setIsChat(false)
                setIsDispute(false)
                setIsMeeting(false)
                return setIsSession(!isSession)
            default:
                setViewOption('')
                setIsSession(false)
                setIsChat(false)
                setIsDispute(false)
                setIsMeeting(false)
                return
        }
    }

    const handleToggleView = (view) => {

        setViewOption(view)
        
        switch (view) {
            case 'chat': 
                setViewOption('chat')
                setIsSession(false)
                setIsDispute(false)
                setIsMeeting(false)
                return setIsChat(!isChat)
            case 'dispute': 
                setViewOption('dispute')
                setIsSession(false)
                setIsChat(false)
                setIsMeeting(false)
                return setIsDispute(!isDispute)
            case 'session': 
                setViewOption('session')
                setIsChat(false)
                setIsDispute(false)
                setIsMeeting(false)
                return setIsSession(!isSession)
            default:
                setViewOption('')
                setIsSession(false)
                setIsChat(false)
                setIsDispute(false)
                setIsMeeting(false)
                return
        }
    }



    const checkDeleted = (type, id) => {
        let data = deletedData
        if (data.length > 0) {
            switch (type) {
                case 'chat': 
                    // data = data?.filter(el => el.deletable_type === "App\\Models\\Chat")
                    if (data?.chat_deleted?.includes(id)) return true
                    return false
                    // break
                case 'dispute': 
                    if (data?.dispute_deleted?.includes(id)) return true
                    return false
                    // data = data?.filter(el => el.deletable_type === "App\\Models\\Dispute")
                    // break
                case 'session': 
                    if (data?.session_deleted?.includes(id)) return true
                    return false
                    // data = data?.filter(el => el.deletable_type === "App\\Models\\Session")
                    // break
                case 'meeting': 
                    // data = data?.filter(el => el.deletable_type === "App\\Models\\Meeting")
                    break
                default:
                    break
            }

            // if (data.includes(id)) return true
            // return false
        } else {
            return false
        }

        
    }
    
    return(
        <>
            <Header page={'chat'} noSearch={true}/>
            {/* <div className="divider"></div> */}

            <div className="mt-4 pt-5">

                <div>
                    <div className='d-flex justify-content-center'>
                        
                        <div className='col-6 col-lg-3 col-md-3'>
                            <div className="input-group input-group-sm my-1">
                                <span style={{border:"solid 1px #16307D"}} className="input-group-text text-white">
                                    <i style={{color: '#16307D'}} className="fa fa-envelope"></i>
                                </span>
                                <select 
                                    className="form-control form-select"
                                    value={viewOption}
                                    onChange={handleChangeView}
                                    style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="text" name="city" id="city" autoComplete="city" placeholder="City"
                                >
                                    <option value=''>Select</option>
                                    <option value='chat'>Chats</option>
                                    <option value='session'>Sessions</option>
                                    <option value='dispute'>Disputes</option>
                                    {/* <option value='session'>Meetings</option> */}
                                </select>
                            </div>
                        </div>
                        {/* <div className='col-6 col-lg-4 col-md-4'>
                            <div className="input-group input-group-sm col-md-6 col-lg-6 my-1">
                                <span style={{border:"solid 1px #16307D"}} className="input-group-text text-white">
                                    <i style={{color: '#16307D'}} className="fa fa-calendar"></i>
                                </span>
                                <input style={{backgroundColor:'#E1F2FE', border:"solid 1px #16307D", color:'#16307D'}} type="date" name="dob" id="dob" autoComplete="dob" className="form-control" placeholder="Date of Birth"></input>
                            </div>
                        </div> */}
                    
                    </div>
                </div>
                <CustomAlert ref={customAlertRef}/>
                <div className="mx-auto b-screen">
                    <div className="d-flex justify-content-between mx-3" style={{}}>
                        {/* right side history */}
                        <div className="col-3 mx-1 overflow-y-auto" style={{height:'80vh'}}>

                            {(isChat === true && viewOption === 'chat')? 
                            chatData?.length > 0 ? 
                                chatData?.map((row) => (
                                    checkDeleted('Chat', row?.id)? <></>:
                                    <div key={row.id}>
                                        {row.id === selectedChat? 
                                            <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                {row?.user?.id === userData.id? 
                                                    <img alt='ddk' src={row?.receiver?.image? domain+row?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                : 
                                                    <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                }
                                                <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.receiver?.first_name: row?.user.first_name }</h6>
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.msg, 20, '...')}</h6>
                                                </div>
                                                <div className="d-flex flex-column px-1 py-1">
                                                    <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                    
                                                    <span onClick={() => createUserDeleted('chat', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                        <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                        :
                                            <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                {row?.user?.id === userData.id? 
                                                    <img alt='ddk' src={row?.receiver?.image? domain+row?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                : 
                                                    <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                }
                                                <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.receiver?.first_name: row?.user.first_name }</h6>
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.msg, 20, '...')}</h6>
                                                </div>
                                                <div className="d-flex flex-column px-1 py-1">
                                                    <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                    {row?.chats?.filter(el => ((!(deletedData?.read?.includes(el.id))) && el.user_id !== userData.id))?.length > 0? 
                                                    <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chats?.filter(el => ((!(deletedData?.read?.includes(el.id))) && el.user_id !== userData.id))?.length}</span>
                                                    : <></>}
                                                    
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    
                                ))
                            : 
                                <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No chat history</h5>
                            :<></>}

                            {(isSession === true && viewOption === 'session')? 
                            sessionData?.filter(el => el.status === 0).length > 0 ? 
                                sessionData?.map((row) => (
                                    row.id === sessionCurrentData?.id? 
                                        <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                            <img alt='ddk' src={row?.image? domain+row?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            <div onClick={() => handleChangeSession(row)} className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.name }</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.name, 20, '...')}</h6>
                                            </div>
                                            <div className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                
                                                <span onClick={() => createUserDeleted('session', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                    <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                </span>
                                                
                                            </div>
                                        </div>
                                    :
                                        <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                            <img alt='ddk' src={row?.image? domain+row?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            <div onClick={() => handleChangeSession(row)} className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.name }</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.name, 20, '...')}</h6>
                                            </div>
                                            <div className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                {row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length > 0? 
                                                <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length}</span>
                                                : <></>}
                                                
                                            </div>
                                        </div>
                                ))
                            : 
                                <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No sessions</h5>
                            :<></>}

                            {(isDispute === true && viewOption === 'dispute')? 
                            disputeData?.length > 0 ? 
                                disputeData?.filter(el => el.status === 0).map((row) => (
                                    row.id === disputeCurrentData?.id? 
                                        <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                            {row?.user?.id === userData.id? 
                                                <img alt='ddk' src={row?.against?.image? domain+row?.against?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            : 
                                                <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            }
                                            <div onClick={() => handleChangeDispute(row)} className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.against?.first_name: row?.user.first_name }</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.description, 20, '...')}</h6>
                                            </div>
                                            <div className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                
                                                <span onClick={() => createUserDeleted('dispute', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                    <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                </span>
                                                
                                            </div>
                                        </div>
                                    :
                                        <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                            {row?.user?.id === userData.id? 
                                                <img alt='ddk' src={row?.against?.image? domain+row?.against?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            : 
                                                <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                            }
                                            <div onClick={() => handleChangeDispute(row)} className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.against?.first_name: row?.user.first_name }</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.description, 20, '...')}</h6>
                                            </div>
                                            <div className="d-flex flex-column px-1 py-1">
                                                <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                {row?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length > 0? 
                                                <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length}</span>
                                                : <></>}
                                                
                                            </div>
                                        </div>
                                ))
                            : 
                                <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No dispute</h5>
                            :<></>}
                            
                        </div>
                        
                        
                        <div className="col-7 d-flex flex-column">
                            <div style={{border:'2px solid #16307D', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">
                                <div className="d-flex flex-row justify-content-between mx-1 my-1">
                                    {isChat === true? 
                                        <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.first_name: chatCurrentData?.user?.first_name}</h6>
                                    :
                                      <>
                                        {isDispute === true? 
                                            <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{disputeCurrentData?.id === userData?.id? disputeCurrentData?.against?.first_name: disputeCurrentData?.user?.first_name}</h6>
                                        :
                                            <h6 style={{color:'#16307D', fontSize:14}} className="my-1">{sessionCurrentData?.name}</h6>
                                        }
                                      </>
                                    }
                                    
                                    {/* toggles */}
                                    <div className="d-flex flex-row justify-content-between">
                                        <span onClick={() => moveDown()} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                        |
                                        <span onClick={() => handleToggleView('chat')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-comments"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('dispute')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-legal"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>

                            {isMeeting === true?
                            <div style={{border:'2px solid #16307D', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 overflow-y-auto">
                                <img style={{border:'#16307D 3px solid', width:'100%', height:'100%'}} alt='ddk' src= "./assets/images/1.jpg" className=""></img>

                            </div>
                            
                            :
                            
                            <div style={{border:'2px solid #16307D', height:'60vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div2">

                                {(isChat === true && isPendingRequest === true)? 

                                    <div className="d-flex justify-content-center" style={{marginTop:'25vh'}}>

                                        <button style={{backgroundColor:'red', border:"solid 1px #16307D"}} className="btn text-white">Delete Request</button>
                                    </div>
                                
                                :

                                    <>

                                        {(isChat === true && isRequest === true)? 


                                            <div className="d-flex justify-content-center" style={{marginTop:'25vh'}}>

                                                <button style={{backgroundColor:'green', border:"solid 1px #16307D"}} className="btn text-white mr-2">Accept</button>

                                                <button style={{backgroundColor:'red', border:"solid 1px #16307D"}} className="btn text-white ml-2">Decline</button>
                                            </div>

                                        :   
                                            <>
                                                {(isChat === true && viewOption === 'chat')? <>

                                                    {chatCurrentData !== null? <>
                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                            {chatCurrentData?.user?.first_name} started this chat at {timeFormat(chatCurrentData?.created_at)}...
                                                        </h6>
                                                        

                                                        {chatCurrentData?.chats?.length > 0 ?
                                                            chatCurrentData?.chats?.map((row, index) => (
                                                                <div key={row.id}>
                                                                    <>
                                                                    {
                                                                        index === 0? 
                                                                            <div className="d-flex my-1">
                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                            </div>
                                                                        :
                                                                        <>
                                                                            {
                                                                                compDate(row.created_at, chatCurrentData?.chats[index - 1]?.created_at) !== true?
                                                                                <div className="d-flex my-1">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                </div>: <></>}
                                                                        </>
                                                                    }
                                                                    </>

                                                                    {row?.user_id === userData?.id?
                                                                        <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                            <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                    {row.type === 0?
                                                                                        row.msg 
                                                                                    :
                                                                                        'Not supported message'
                                                                                    }

                                                                                </div>
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    :
                                                                        <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                    {row.type === 0?
                                                                                        row.msg 
                                                                                    :
                                                                                        'Not supported message'
                                                                                    }
                                                                                </div>
                                                                                <div className="d-flex flex-row justify-content-end">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            ))

                                                            :

                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                        }

                                                        {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                            <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                    {newSentMsg}

                                                                </div>
                                                                <div className="d-flex flex-row justify-content-start">
                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                </div>
                                                            </div>
                                                        </div>: null}
                                                    </>: <>
                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome ...</h6>
                                                    </>}

                                                    
                                                </>: 
                                                <>
                                                    {(isSession === true && viewOption === 'session' && sessionCurrentData !== null )? <>
                                                        
                                                        {sessionCurrentData !== null? <>
                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                                {sessionCurrentData?.user?.first_name} started this session at {timeFormat(sessionCurrentData?.created_at)}...
                                                            </h6>

                                                            {sessionCurrentData?.chat?.chats?.length > 0 ?
                                                                sessionCurrentData?.chat?.chats?.map((row, index) => (
                                                                    <div key={row.id}>
                                                                        <>
                                                                        {
                                                                            index === 0? 
                                                                                <div className="d-flex my-1">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    compDate(row.created_at, chatCurrentData?.chats[index - 1]) !== true?
                                                                                    <div className="d-flex my-1">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                    </div>: <></>}
                                                                            </>
                                                                        }
                                                                        </>

                                                                        {row?.user_id === userData?.id?
                                                                        
                                                                            <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                                <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                        {row.type === 0?
                                                                                            row.msg 
                                                                                        :
                                                                                            <span onClick={() => payPrivateSession(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Pay for {row.msg}</span>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="d-flex flex-row justify-content-start">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        :
                                                                            <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                        {row.type === 0?
                                                                                            row.msg 
                                                                                        :
                                                                                            <span onClick={() => payPrivateSession(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Pay for {row.msg}</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="d-flex flex-row justify-content-end">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                ))

                                                                :

                                                                <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                            }
                                                            {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                        {newSentMsg}

                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start">
                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>: null}

                                                            {newSentSession? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                        <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Processing Invoice</span>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start">
                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>: null}
                                                        </>:<>
                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No session chat</h6>
                                                        </>}
                                                        
                                                        </>: <>
                                                            {(isDispute === true && viewOption === 'dispute')? <>
                                                                {disputeCurrentData !== null? <>
                                                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                                        {disputeCurrentData.user?.first_name} started this dispute chat at {timeFormat(disputeCurrentData.created_at)}...
                                                                    </h6>
                                                                    

                                                                    {disputeCurrentData.chat?.chats?.length > 0 ?
                                                                        disputeCurrentData.chat?.chats?.map((row, index) => (
                                                                            <div key={row.id}>
                                                                                <>
                                                                                {
                                                                                    index === 0? 
                                                                                        <div className="d-flex my-1">
                                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                        </div>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            compDate(row.created_at, chatCurrentData?.chats[index - 1]) !== true?
                                                                                            <div className="d-flex my-1">
                                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                            </div>: <></>}
                                                                                    </>
                                                                                }
                                                                                </>

                                                                                {row?.user_id === userData?.id?
                                                                                
                                                                                    <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                                        <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                            <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                                {row.type === 0?
                                                                                                    row.msg 
                                                                                                :
                                                                                                <>
                                                                                                {row.status === 5? 
                                                                                                    <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px red"}} className="badge py-2 my-1 rounded-pill">Cashed out {row.msg}</span>:
                                                                                                    <span onClick={() => payRefund(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Cash out {row.msg}</span>
                                                                                                    }
                                                                                                </>
                                                                                                }

                                                                                            </div>
                                                                                            <div className="d-flex flex-row justify-content-start">
                                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                
                                                                                :
                                                                                    <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                            <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                                {row.type === 0?
                                                                                                    row.msg 
                                                                                                :
                                                                                                <>
                                                                                                    {row.status === 5? 
                                                                                                    <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px red"}} className="badge py-2 my-1 rounded-pill">Cashed out {row.msg}</span>:
                                                                                                    <span onClick={() => payRefund(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Cash out {row.msg}</span>
                                                                                                    }
                                                                                                </>
                                                                                                
                                                                                                }
                                                                                            </div>
                                                                                            <div className="d-flex flex-row justify-content-end">
                                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                        <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        ))

                                                                        :

                                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                                    }
                                                                    {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                        <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                            <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                                {newSentMsg}

                                                                            </div>
                                                                            <div className="d-flex flex-row justify-content-start">
                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>: null}

                                                                    {newSentDispute? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                        <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                        <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                            <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Processing Invoice</span>
                                                                            </div>
                                                                            <div className="d-flex flex-row justify-content-start">
                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>: null}
                                                                </>:<>
                                                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No dispute chat</h6>
                                                                </>}
                                                                
                                                                </>: <>
                                                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome {userData.first_name}...</h6>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>}
                                            </>
                                        }

                                    </>
                                }
                                
                                <div ref={messagesEndRef} />
                            </div>}

                            <div style={{border:'2px solid #16307D', height:'10%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                <div className="d-flex flex-row justify-content-between">
                                    <div style={{width:'70%'}} className="d-flex flex-row justify-content-between">
                                        <span onClick={() => handleSendMessage()} style={{backgroundColor:'#5FF15C', border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-paper-plane"></i>
                                        </span>
                                        <input value={textField} onKeyPress={handleKeyPress} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between">
                                        {isDispute === true?
                                            <span onClick={() => handleCreateInvoice()} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-2 rounded-pill">Invoice</span> 
                                        : 
                                            <>{userData?.userType === 3? <span onClick={() => createPrivateSession()} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-2 rounded-pill">Send Offer</span> : <span style={{backgroundColor:'#E1F2FE', color:'grey', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-2 rounded-pill">Send Offer</span>}</>
                                        }
                                        

                                        {/* <span onClick={() => setIsMeeting(!isMeeting)} style={{backgroundColor:`${isMeeting? 'red': '#5FF15C'}`, border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-video-camera"></i>
                                        </span> */}
                                        <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-calendar"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('chat')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-comments"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('dispute')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-legal"></i>
                                        </span>
                                        <span onClick={() => moveDown()} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        {/* left side details */}
                        {isMeeting === true?
                            <div style={{border:'2px solid #16307D', height:"80vh"}} className="col-2 d-flex flex-column px-1 mx-1 my-1 rounded-2 overflow-y-auto">

                                <div style={{}}>
                                    <div className="row pt-2">
                                        <div style={{}} className="">
                                            <img style={{border:'2px solid #16307D'}} alt='ddk' src="./assets/images/1.jpg" className="" height="150px" width="100%"></img>
                                            
                                        </div>

                                        <div className="d-flex flex-row justify-content-center mt-1">
                                            <span onClick={() => handleToggleView('chat')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-pause"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('dispute')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-video-camera"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-volume-up"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-close"></i>
                                            </span>
                                        </div>

                                        <h6 className="" style={{fontWeight:'bolder', textAlign: 'center', margin: '0'}}>Muhammed Jamiu</h6>
                                    </div>
                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                </div>

                                <div style={{}}>
                                    <div className="row pt-2">
                                        <div style={{}} className="">
                                            <img style={{border:'2px solid #16307D'}} alt='ddk' src="./assets/images/1.jpg" className="" height="150px" width="100%"></img>
                                            
                                        </div>

                                        <div className="d-flex flex-row justify-content-center mt-1">
                                            <span onClick={() => handleToggleView('chat')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-pause"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('dispute')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-video-camera"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-volume-up"></i>
                                            </span>
                                            <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                                <i style={{color:'#16307D', fontSize:10}} className="fa fa-close"></i>
                                            </span>
                                        </div>

                                        <h6 className="" style={{fontWeight:'bolder', textAlign: 'center', margin: '0'}}>Jerry</h6>
                                    </div>
                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                </div>
                                
                                

                            </div>

                        :
                            <div style={{border:'2px solid #16307D', height:"80vh"}} className="col-2 d-flex flex-column px-1 mx-1 my-1 rounded-2 overflow-y-auto">
                                {(isChat === true && viewOption === 'chat')? 
                                <>
                                    {chatCurrentData !== null? <>
                                        <div className="my-1 mx-auto">

                                            {chatCurrentData?.user?.id === userData.id? 
                                                <img alt='ddk' src={chatCurrentData?.receiver?.image? domain+chatCurrentData?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                            : 
                                                <img alt='ddk' src={chatCurrentData?.user?.image? domain+chatCurrentData?.user?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                            }
                                        </div>
                                        
                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.first_name: chatCurrentData?.user?.first_name}</h6>
                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.receiver.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>
                                        <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                            {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.description: chatCurrentData?.user?.description}
                                        </div>
                                        <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                        <h6 style={{color:'#16307D', fontSize:14, textAlign: 'center'}} className="my-1 ml-2">About</h6>

                                        {chatCurrentData?.user?.id === userData.id?
                                        
                                            <Link to={chatCurrentData?.receiver?.user_type === 4? '/player?id='+chatCurrentData?.receiver?.player?.uuid : '/coach?id='+chatCurrentData?.receiver?.coach?.uuid}>View profile</Link>
                                        : 
                                            <Link to={chatCurrentData?.user?.user_type === 4? '/player?id='+chatCurrentData?.user?.player?.uuid : '/coach?id='+chatCurrentData?.user?.coach?.uuid}>View profile</Link>
                                        }

                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">First Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.first_name: chatCurrentData?.user?.first_name}</h6>
                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Last Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.last_name: chatCurrentData?.user?.last_name}</h6>
                                        
                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.receiver.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>

                                        <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                        <div className="row px-1" style={{maxWidth:'100%'}}>
                                            <h6 style={{fontWeight:'bolder', textAlign:'center'}}>Expertise</h6>
                                            <div style={{}} className="">

                                                {chatCurrentData?.user?.id === userData.id? 
                                                    chatCurrentData?.receiver?.expertise?.length > 0 ?
                                                        chatCurrentData?.receiver?.expertise.map((row) => (
                                                            <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                        ))
                                                    : <h5 style={{color:'#16307D', fontSize: 12, textAlign: 'center'}}>No Expertise...</h5>
                                                : 
                                                    chatCurrentData?.user?.expertise?.length > 0 ?
                                                        chatCurrentData?.user?.expertise.map((row) => (
                                                            <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                        ))
                                                    : <h5 style={{color:'#16307D', fontSize: 12, textAlign: 'center'}}>No Expertise...</h5>
                                                }
                                                
                                            </div>
                                        </div>
                                    </>:<>
                                        {/* Nothing */}
                                    </>}

                                    
                                </>:<>
                                    {(isDispute === true && viewOption === 'dispute')? 
                                        <>
                                            {disputeCurrentData !== null? <>
                                                <div className="my-1 mx-auto">

                                                    {chatCurrentData?.user?.id === userData.id? 
                                                        <img alt='ddk' src={chatCurrentData?.against?.image? domain+chatCurrentData?.against?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    : 
                                                        <img alt='ddk' src={chatCurrentData?.user?.image? domain+chatCurrentData?.user?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    }
                                                </div>

                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.id === userData?.id? chatCurrentData?.against?.first_name: chatCurrentData?.user?.first_name}</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.against?.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>
                                                    
                                                <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                                    {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.description: chatCurrentData?.user?.description}
                                                </div>
                                                <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About</h6>

                                                {chatCurrentData?.user?.id === userData.id?

                                                    <Link to={chatCurrentData?.against?.user_type === 4? '/player?id='+chatCurrentData?.against?.player?.uuid : '/coach?id='+chatCurrentData?.against?.coach?.uuid}>View profile</Link>
                                                    : 
                                                    <Link to={chatCurrentData?.user?.user_type === 4? '/player?id='+chatCurrentData?.user?.player?.uuid : '/coach?id='+chatCurrentData?.user?.coach?.uuid}>View profile</Link>
                                                }

                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">First Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.first_name: chatCurrentData?.user?.first_name}</h6>
                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Last Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.last_name: chatCurrentData?.user?.last_name}</h6>

                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.against?.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>

                                                <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                <div className="row px-1" style={{maxWidth:'100%'}}>
                                                    <h6 style={{fontWeight:'bolder', textAlign: 'center'}}>Expertise</h6>
                                                    <div style={{}} className="">

                                                        {chatCurrentData?.user?.id === userData.id? 
                                                            chatCurrentData?.against?.expertise?.length > 0 ?
                                                                chatCurrentData?.against?.expertise.map((row) => (
                                                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Expertise...</h5>
                                                        : 
                                                            chatCurrentData?.user?.expertise?.length > 0 ?
                                                                chatCurrentData?.user?.expertise.map((row) => (
                                                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Expertise...</h5>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </>:<>
                                                {/* nothing */}
                                            </>}
                                            
                                        </>
                                    :
                                        
                                        <>
                                            {(isSession === true && viewOption === 'session')? 
                                            <>
                                                {sessionCurrentData !== null? <>
                                                    <div className="my-1 mx-auto">

                                                        <img alt='ddk' src={chatCurrentData?.image? domain+chatCurrentData?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    </div>

                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.name}</h6>
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Created: {timeFormat(chatCurrentData?.created_at)}</h6>
                                                        
                                                    <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                                        {chatCurrentData?.description}
                                                    </div>
                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About</h6>

                                                    <Link to={'/session?id='+chatCurrentData?.uuid}>View</Link>
                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About Coach</h6>
                                                    <Link to={'/coach?id='+chatCurrentData?.user?.coach?.uuid}>View Coach</Link>

                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">Meetings</h6>

                                                    <div>
                                                        {chatCurrentData?.meetings?.length > 0 ?
                                                            chatCurrentData?.meetings?.map((row) => (
                                                                    <span key={row.id}>
                                                                        {row.status === 1? 
                                                                            <span onClick={() => {navigateToRoute(`/chat?id=${row.uuid}&&type=meeting`)}} style={{marginRight:'5px', backgroundColor:'green', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>

                                                                            :
                                                                                <>
                                                                                {row.status === 5? 
                                                                                    <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'red', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>
            
                                                                                    :
                                                                                    
                                                                                    <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'red', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>
                                                                                    
                                                                                }</>

                                                                        }
                                                                    </span>
                                                                    
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Meetings...</h5>
                                                        }
                                                    </div>

                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">Players</h6>
                                                    <div>
                                                        {chatCurrentData?.bookings?.length > 0 ?
                                                            chatCurrentData?.bookings?.map((row) => (
                                                                <div key={row.id} onClick={() => {navigateToRoute(`/player?id=${row.player?.uuid}&&code=${row.user?.uuid}`)}} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                                    <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>

                                                                    <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user.first_name }</h6>
                                                                    </div>
                                                                    
                                                                </div>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Meetings...</h5>
                                                        }
                                                    </div>
                                                </>: <>
                                                        {/* nothing */}
                                                </>}
                                                
                                            </>
                                        :
                                            
                                            <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1">No selection</h6>
                                        }
                                        </> 
                                    }
                                </>}

                            </div>
                        }

                    </div>
                </div>

                {/* mobile start */}

                <div className="px-auto m-screen">
                    <div className="d-flex flex-column col-12" style={{}}>
                        {hideLeft === true? <></>:
                            <>
                                {(isChat === true && viewOption === 'chat')? 
                                    chatData?.length > 0 ? 
                                        chatData?.map((row) => (
                                            checkDeleted('Chat', row?.id)? <></>:
                                            <div key={row.id}>
                                                {row.id === selectedChat? 
                                                    <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                        {row?.user?.id === userData.id? 
                                                            <img alt='ddk' src={row?.receiver?.image? domain+row?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                        : 
                                                            <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                        }
                                                        <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.receiver?.first_name: row?.user.first_name }</h6>
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.msg, 20, '...')}</h6>
                                                        </div>
                                                        <div className="d-flex flex-column px-1 py-1">
                                                            <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                            
                                                            <span onClick={() => createUserDeleted('chat', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                                <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                            </span>
                                                            
                                                        </div>
                                                    </div>
                                                :
                                                    <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                        {row?.user?.id === userData.id? 
                                                            <img alt='ddk' src={row?.receiver?.image? domain+row?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                        : 
                                                            <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                        }
                                                        <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.receiver?.first_name: row?.user.first_name }</h6>
                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.msg, 20, '...')}</h6>
                                                        </div>
                                                        <div className="d-flex flex-column px-1 py-1">
                                                            <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                            {row?.chats?.filter(el => ((!(deletedData?.read?.includes(el.id))) && el.user_id !== userData.id))?.length > 0? 
                                                            <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chats?.filter(el => ((!(deletedData?.read?.includes(el.id))) && el.user_id !== userData.id))?.length}</span>
                                                            : <></>}
                                                            
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            
                                        ))
                                    : 
                                        <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No chat history</h5>
                                    :<></>}

                                    {(isSession === true && viewOption === 'session')? 
                                    sessionData?.filter(el => el.status === 0).length > 0 ? 
                                        sessionData?.map((row) => (
                                            row.id === sessionCurrentData?.id? 
                                                <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                    <img alt='ddk' src={row?.image? domain+row?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    <div onClick={() => handleChangeSession(row)} className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.name }</h6>
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.name, 20, '...')}</h6>
                                                    </div>
                                                    <div className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                        
                                                        <span onClick={() => createUserDeleted('session', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                            <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            :
                                                <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                    <img alt='ddk' src={row?.image? domain+row?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    <div onClick={() => handleChangeSession(row)} className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.name }</h6>
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.name, 20, '...')}</h6>
                                                    </div>
                                                    <div className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                        {row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length > 0? 
                                                        <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length}</span>
                                                        : <></>}
                                                        
                                                    </div>
                                                </div>
                                        ))
                                    : 
                                        <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No sessions</h5>
                                    :<></>}

                                    {(isDispute === true && viewOption === 'dispute')? 
                                    disputeData?.length > 0 ? 
                                        disputeData?.filter(el => el.status === 0).map((row) => (
                                            row.id === disputeCurrentData?.id? 
                                                <div key={row.id} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                    {row?.user?.id === userData.id? 
                                                        <img alt='ddk' src={row?.against?.image? domain+row?.against?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    : 
                                                        <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    }
                                                    <div onClick={() => handleChangeDispute(row)} className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.against?.first_name: row?.user.first_name }</h6>
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.description, 20, '...')}</h6>
                                                    </div>
                                                    <div className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                        
                                                        <span onClick={() => createUserDeleted('dispute', row)} style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">
                                                            <i style={{color:'red', fontSize:16}} className="fa fa-close"></i>
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            :
                                                <div key={row?.id} style={{backgroundColor:'#F1F9FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                    {row?.user?.id === userData.id? 
                                                        <img alt='ddk' src={row?.against?.image? domain+row?.against?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    : 
                                                        <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>
                                                    }
                                                    <div onClick={() => handleChangeDispute(row)} className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user?.id === userData.id? row?.against?.first_name: row?.user.first_name }</h6>
                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{reduceText(row.description, 20, '...')}</h6>
                                                    </div>
                                                    <div className="d-flex flex-column px-1 py-1">
                                                        <h6 style={{color:'#16307D', fontSize:10}} className="my-1">{timeFormat(row?.created_at)}</h6>

                                                        {row?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length > 0? 
                                                        <span style={{color:'#FFFFFF', backgroundColor:'#16307D', width:'25px', height:'25px'}} className="badge mx-auto">{row?.chat?.chats?.filter(el => (el.status === 0 && el.user_id !== userData.id))?.length}</span>
                                                        : <></>}
                                                        
                                                    </div>
                                                </div>
                                        ))
                                    : 
                                        <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No dispute</h5>
                                :<></>}
                            </>
                        }
                        
                        
                        <div className="col-7 d-flex flex-column col-12">
                            <div style={{border:'2px solid #16307D', height:'', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2">
                                <div className="d-flex flex-row justify-content-center mx-1 my-1">
                                    {/* <h6 style={{color:'#16307D', fontSize:14}} className="my-1">Muhammed-Jamiu</h6> */}

                                    <div className="d-flex flex-row justify-content-between">
                                        <span onClick={() => moveDown()} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-arrow-down"></i>
                                        </span>
                                        |
                                        <span onClick={() => handleToggleView('chat')} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-comment"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('session')} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-calendar"></i>
                                        </span>
                                        <span onClick={() => handleToggleView('dispute')} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-legal"></i>
                                        </span>
                                        |
                                        <span onClick={() => setHideCenter(!hideCenter)} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-comments"></i>
                                        </span>
                                        <span onClick={() => setHideLeft(!hideLeft)} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-table"></i>
                                        </span>
                                        <span onClick={() => setHideRight(!hideRight)} style={{border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill mx-1 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-users"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>

                            {hideCenter === true? <></>: <>

                                {isMeeting === true?
                                    <div style={{border:'2px solid #16307D', height:'45vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 overflow-y-auto">
                                        <img style={{border:'#16307D 3px solid', width:'100%', height:'100%'}} alt='ddk' src= "./assets/images/1.jpg" className=""></img>

                                    </div>
                                
                                :

                                <div style={{border:'2px solid #16307D', height:'45vh', backgroundColor:'#F1F9FF'}} className="rounded-2 my-2 px-2 overflow-y-auto" id="move-chat-div">

                                    {(isChat === true && isPendingRequest === true)? 

                                        <div className="d-flex justify-content-center" style={{marginTop:'19vh'}}>

                                            <button style={{backgroundColor:'red', border:"solid 1px #16307D"}} className="btn text-white">Delete Request</button>
                                        </div>

                                    :

                                        <>

                                            {(isChat === true && isRequest === true)? 

                                                <div className="d-flex justify-content-center" style={{marginTop:'19vh'}}>

                                                    <button style={{backgroundColor:'green', border:"solid 1px #16307D"}} className="btn text-white mr-2">Accept</button>

                                                    <button style={{backgroundColor:'red', border:"solid 1px #16307D"}} className="btn text-white ml-2">Decline</button>
                                                </div>

                                            :   
                                                <>
                                                    {(isChat === true && viewOption === 'chat')? <>

                                                        {chatCurrentData !== null? <>
                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                                {chatCurrentData?.user?.first_name} started this chat at {timeFormat(chatCurrentData?.created_at)}...
                                                            </h6>
                                                            

                                                            {chatCurrentData?.chats?.length > 0 ?
                                                                chatCurrentData?.chats?.map((row, index) => (
                                                                    <div key={row.id}>
                                                                        <>
                                                                        {
                                                                            index === 0? 
                                                                                <div className="d-flex my-1">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    compDate(row.created_at, chatCurrentData?.chats[index - 1]?.created_at) !== true?
                                                                                    <div className="d-flex my-1">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                    </div>: <></>}
                                                                            </>
                                                                        }
                                                                        </>

                                                                        {row?.user_id === userData?.id?
                                                                        
                                                                            <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                                <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                        {row.type === 0?
                                                                                            row.msg 
                                                                                        :
                                                                                            'Not supported message'
                                                                                        }

                                                                                    </div>
                                                                                    <div className="d-flex flex-row justify-content-start">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        :
                                                                            <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                        {row.type === 0?
                                                                                            row.msg 
                                                                                        :
                                                                                            'Not supported message'
                                                                                        }
                                                                                    </div>
                                                                                    <div className="d-flex flex-row justify-content-end">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                ))

                                                                :

                                                                <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                            }

                                                            {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                    <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                        {newSentMsg}

                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-start">
                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>: null}
                                                        </>: <>
                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome ...</h6>
                                                        </>}


                                                        </>: 
                                                        <>
                                                        {(isSession === true && viewOption === 'session' && sessionCurrentData !== null )? <>
                                                            
                                                            {sessionCurrentData !== null? <>
                                                                <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                                    {sessionCurrentData?.user?.first_name} started this session at {timeFormat(sessionCurrentData?.created_at)}...
                                                                </h6>

                                                                {sessionCurrentData?.chat?.chats?.length > 0 ?
                                                                    sessionCurrentData?.chat?.chats?.map((row, index) => (
                                                                        <div key={row.id}>
                                                                            <>
                                                                            {
                                                                                index === 0? 
                                                                                    <div className="d-flex my-1">
                                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                    </div>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        compDate(row.created_at, chatCurrentData?.chats[index - 1]) !== true?
                                                                                        <div className="d-flex my-1">
                                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                        </div>: <></>}
                                                                                </>
                                                                            }
                                                                            </>

                                                                            {row?.user_id === userData?.id?
                                                                            
                                                                                <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                                    <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                            {row.type === 0?
                                                                                                row.msg 
                                                                                            :
                                                                                                <span onClick={() => payPrivateSession(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Pay for {row.msg}</span>
                                                                                            }

                                                                                        </div>
                                                                                        <div className="d-flex flex-row justify-content-start">
                                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            
                                                                            :
                                                                                <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                            {row.type === 0?
                                                                                                row.msg 
                                                                                            :
                                                                                                <span onClick={() => payPrivateSession(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Pay for {row.msg}</span>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="d-flex flex-row justify-content-end">
                                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    ))

                                                                    :

                                                                    <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                                }

                                                                {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                    <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                            {newSentMsg}

                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-start">
                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>: null}

                                                                {newSentSession? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                    <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                    <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                        <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                            <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Processing Invoice</span>
                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-start">
                                                                            <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>: null}
                                                            </>:<>
                                                                <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No session chat</h6>
                                                            </>}
                                                            
                                                            </>: <>
                                                                {(isDispute === true && viewOption === 'dispute')? <>
                                                                    {disputeCurrentData !== null? <>
                                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">
                                                                            {disputeCurrentData.user?.first_name} started this dispute chat at {timeFormat(disputeCurrentData.created_at)}...
                                                                        </h6>
                                                                        

                                                                        {disputeCurrentData.chat?.chats?.length > 0 ?
                                                                            disputeCurrentData.chat?.chats?.map((row, index) => (
                                                                                <div key={row.id}>
                                                                                    <>
                                                                                    {
                                                                                        index === 0? 
                                                                                            <div className="d-flex my-1">
                                                                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                            </div>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                compDate(row.created_at, chatCurrentData?.chats[index - 1]) !== true?
                                                                                                <div className="d-flex my-1">
                                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-2 mx-auto">{timeTable(row.created_at)}</h6>
                                                                                                </div>: <></>}
                                                                                        </>
                                                                                    }
                                                                                    </>

                                                                                    {row?.user_id === userData?.id?
                                                                                    
                                                                                        <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                                            <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                                    {row.type === 0?
                                                                                                        row.msg 
                                                                                                    :
                                                                                                    <>
                                                                                                    {row.status === 5? 
                                                                                                        <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px red"}} className="badge py-2 my-1 rounded-pill">Cashed out {row.msg}</span>:
                                                                                                        <span onClick={() => payRefund(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Cash out {row.msg}</span>
                                                                                                        }
                                                                                                    </>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    
                                                                                    :
                                                                                        <div className="d-flex flex-row justify-content-end mb-1" style={{paddingLeft:'20%'}}>
                                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#67BEFA', border:'1px solid #67BEFA'}} className="my-1 p-2 rounded-top">
                                                                                                    {row.type === 0?
                                                                                                        row.msg 
                                                                                                    :
                                                                                                    <>
                                                                                                        {row.status === 5? 
                                                                                                        <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px red"}} className="badge py-2 my-1 rounded-pill">Cashed out {row.msg}</span>:
                                                                                                        <span onClick={() => payRefund(row)} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Cash out {row.msg}</span>
                                                                                                        }
                                                                                                    </>
                                                                                                    
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="d-flex flex-row justify-content-end">
                                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormat(row.created_at)}</h6>
                                                                                                </div>
                                                                                                
                                                                                            </div>
                                                                                            <img alt='ddk' src={row.user?.image? domain+row.user.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 ml-1" height="30" width="30"></img>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            ))

                                                                            :

                                                                            <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No chat yet</h6>
                                                                        }

                                                                        {newSentMsg? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                            <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #16307D'}} className="my-1 p-2 rounded-top">
                                                                                    {newSentMsg}

                                                                                </div>
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>: null}

                                                                        {newSentDispute? <div className="d-flex flex-row justify-content-start mb-1" style={{paddingRight:'20%'}}>
                                                                            <img alt='ddk' src={userData?.image? domain+userData.image: "./assets/images/1.jpg"} className="rounded-circle mt-auto mb-4 mr-2" height="30" width="30"></img>
                                                                            <div className="d-flex flex-column px-1 py-1" style={{MaxWidth:'70%'}}>
                                                                                <div style={{color:'#16307D', fontSize:12, backgroundColor:'#FFFFFF', border:'1px solid #FFFFFF'}} className="my-1 p-2 rounded-top">
                                                                                    <span style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">Processing Invoice</span>
                                                                                </div>
                                                                                <div className="d-flex flex-row justify-content-start">
                                                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-0">{timeFormatNow()}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>: null}
                                                                    </>:<>
                                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">No dispute chat</h6>
                                                                    </>}
                                                                    
                                                                    </>: <>
                                                                        <h6 style={{color:'#16307D', fontSize:12, textAlign: 'center'}} className="my-2 mx-auto">Welcome {userData.first_name}...</h6>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        </>}
                                                </>
                                            }
                                        </>
                                    }
                                    
                                    <div ref={messagesEndRef2} />
                                </div>}

                            </>}

                            <div style={{border:'2px solid #16307D', height:'10%', backgroundColor:'#F1F9FF'}} className="rounded-2 my-1">
                                <div className="d-flex flex-row justify-content-between">
                                    <div style={{width:'90%'}} className="d-flex flex-row justify-content-between">
                                        {/* <i className="fa fa-face-smile px-2 my-auto"></i> */}
                                        <span onClick={() => handleSendMessage()} style={{backgroundColor:'#5FF15C', border:"solid 1px #16307D", color:'#16307D'}} className="badge px-1 py-1 rounded-pill mx-2 my-auto">
                                            <i style={{color:'#16307D', fontSize:16}} className="fa fa-paper-plane"></i>
                                        </span>
                                        <input onKeyPress={handleKeyPress} value={textField} onChange={handleTextFieldChange} style={{width:'90%'}} className="form-control my-1" placeholder="Type here"></input>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between">
                                        {isDispute === true?
                                            <span onClick={() => handleCreateInvoice()} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-1 rounded-pill">Pay</span> 
                                        : 
                                            userData?.userType === 3? <span onClick={() => createPrivateSession()} style={{backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-1 rounded-pill">Offer</span> : <span style={{backgroundColor:'#E1F2FE', color:'grey', border:"solid 1px #16307D"}} className="badge py-2 my-2 mx-1 rounded-pill">Offer</span>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        {hideRight === true? <></>
                        : <>
                            {(isChat === true && viewOption === 'chat')? 
                                <>
                                    {chatCurrentData !== null? <>
                                        <div className="my-1 mx-auto">

                                            {chatCurrentData?.user?.id === userData.id? 
                                                <img alt='ddk' src={chatCurrentData?.receiver?.image? domain+chatCurrentData?.receiver?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                            : 
                                                <img alt='ddk' src={chatCurrentData?.user?.image? domain+chatCurrentData?.user?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                            }
                                        </div>
                                        
                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.first_name: chatCurrentData?.user?.first_name}</h6>
                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.receiver.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>
                                        <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                            {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.description: chatCurrentData?.user?.description}
                                        </div>
                                        <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                        <h6 style={{color:'#16307D', fontSize:14, textAlign: 'center'}} className="my-1 ml-2">About</h6>

                                        {chatCurrentData?.user?.id === userData.id?
                                        
                                            <Link to={chatCurrentData?.receiver?.user_type === 4? '/player?id='+chatCurrentData?.receiver?.player?.uuid : '/coach?id='+chatCurrentData?.receiver?.coach?.uuid}>View profile</Link>
                                        : 
                                            <Link to={chatCurrentData?.user?.user_type === 4? '/player?id='+chatCurrentData?.user?.player?.uuid : '/coach?id='+chatCurrentData?.user?.coach?.uuid}>View profile</Link>
                                        }

                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">First Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.first_name: chatCurrentData?.user?.first_name}</h6>
                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Last Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.receiver.last_name: chatCurrentData?.user?.last_name}</h6>
                                        
                                        <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.receiver.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>

                                        <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                        <div className="row px-1" style={{maxWidth:'100%'}}>
                                            <h6 style={{fontWeight:'bolder', textAlign:'center'}}>Expertise</h6>
                                            <div style={{}} className="">

                                                {chatCurrentData?.user?.id === userData.id? 
                                                    chatCurrentData?.receiver?.expertise?.length > 0 ?
                                                        chatCurrentData?.receiver?.expertise.map((row) => (
                                                            <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                        ))
                                                    : <h5 style={{color:'#16307D', fontSize: 12, textAlign: 'center'}}>No Expertise...</h5>
                                                : 
                                                    chatCurrentData?.user?.expertise?.length > 0 ?
                                                        chatCurrentData?.user?.expertise.map((row) => (
                                                            <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                        ))
                                                    : <h5 style={{color:'#16307D', fontSize: 12, textAlign: 'center'}}>No Expertise...</h5>
                                                }
                                                
                                            </div>
                                        </div>
                                    </>:<>
                                        {/* Nothing */}
                                    </>}

                                    
                                </>:<>
                                    {(isDispute === true && viewOption === 'dispute')? 
                                        <>
                                            {disputeCurrentData !== null? <>
                                                <div className="my-1 mx-auto">

                                                    {chatCurrentData?.user?.id === userData.id? 
                                                        <img alt='ddk' src={chatCurrentData?.against?.image? domain+chatCurrentData?.against?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    : 
                                                        <img alt='ddk' src={chatCurrentData?.user?.image? domain+chatCurrentData?.user?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    }
                                                </div>

                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.id === userData?.id? chatCurrentData?.against?.first_name: chatCurrentData?.user?.first_name}</h6>
                                                <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.against?.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>
                                                    
                                                <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                                    {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.description: chatCurrentData?.user?.description}
                                                </div>
                                                <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About</h6>

                                                {chatCurrentData?.user?.id === userData.id?

                                                    <Link to={chatCurrentData?.against?.user_type === 4? '/player?id='+chatCurrentData?.against?.player?.uuid : '/coach?id='+chatCurrentData?.against?.coach?.uuid}>View profile</Link>
                                                    : 
                                                    <Link to={chatCurrentData?.user?.user_type === 4? '/player?id='+chatCurrentData?.user?.player?.uuid : '/coach?id='+chatCurrentData?.user?.coach?.uuid}>View profile</Link>
                                                }

                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">First Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.first_name: chatCurrentData?.user?.first_name}</h6>
                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Last Name: {chatCurrentData?.id === userData?.id? chatCurrentData?.against?.last_name: chatCurrentData?.user?.last_name}</h6>

                                                <h6 style={{color:'#16307D', fontSize:12, marginLeft:'3px'}} className="my-1">Joined: {chatCurrentData?.id === userData?.id? timeFormat(chatCurrentData?.against?.created_at): timeFormat(chatCurrentData?.user?.created_at)}</h6>

                                                <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                <div className="row px-1" style={{maxWidth:'100%'}}>
                                                    <h6 style={{fontWeight:'bolder', textAlign: 'center'}}>Expertise</h6>
                                                    <div style={{}} className="">

                                                        {chatCurrentData?.user?.id === userData.id? 
                                                            chatCurrentData?.against?.expertise?.length > 0 ?
                                                                chatCurrentData?.against?.expertise.map((row) => (
                                                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Expertise...</h5>
                                                        : 
                                                            chatCurrentData?.user?.expertise?.length > 0 ?
                                                                chatCurrentData?.user?.expertise.map((row) => (
                                                                    <span key={row.id} style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.expertise.name}</span>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Expertise...</h5>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </>:<>
                                                {/* nothing */}
                                            </>}
                                            
                                        </>
                                    :
                                        
                                        <>
                                            {(isSession === true && viewOption === 'session')? 
                                            <>
                                                {sessionCurrentData !== null? <>
                                                    <div className="my-1 mx-auto">

                                                        <img alt='ddk' src={chatCurrentData?.image? domain+chatCurrentData?.image:"./assets/images/1.jpg"} className="rounded-circle" height="70" width="70"></img>
                                                    </div>

                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">{chatCurrentData?.name}</h6>
                                                    <h6 style={{color:'#16307D', fontSize:12}} className="my-1 mx-auto">Created: {timeFormat(chatCurrentData?.created_at)}</h6>
                                                        
                                                    <div style={{color:'#16307D', fontSize:12, width:'12vw'}} className="my-3 mx-auto">
                                                        {chatCurrentData?.description}
                                                    </div>
                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About</h6>

                                                    <Link to={'/session?id='+chatCurrentData?.uuid}>View</Link>
                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>

                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">About Coach</h6>
                                                    <Link to={'/coach?id='+chatCurrentData?.user?.coach?.uuid}>View Coach</Link>

                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">Meetings</h6>

                                                    <div>
                                                        {chatCurrentData?.meetings?.length > 0 ?
                                                            chatCurrentData?.meetings?.map((row) => (
                                                                    <span key={row.id}>
                                                                        {row.status === 1? 
                                                                            <span onClick={() => {navigateToRoute(`/chat?id=${row.uuid}&&type=meeting`)}} style={{marginRight:'5px', backgroundColor:'green', color:'#16307D', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>

                                                                            :
                                                                                <>
                                                                                {row.status === 5? 
                                                                                    <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'red', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>
            
                                                                                    :
                                                                                    
                                                                                    <span style={{marginRight:'5px', backgroundColor:'#E1F2FE', color:'red', border:"solid 1px #16307D"}} className="badge py-2 my-1 rounded-pill">{row.name}</span>
                                                                                    
                                                                                }</>

                                                                        }
                                                                    </span>
                                                                    
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Meetings...</h5>
                                                        }
                                                    </div>

                                                    <hr style={{color:"", backgroundColor:'#16307D', height:'1px', margin: '0'}} className="mx-1 mt-1"/>
                                                    <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1 ml-2">Players</h6>
                                                    <div>
                                                        {chatCurrentData?.bookings?.length > 0 ?
                                                            chatCurrentData?.bookings?.map((row) => (
                                                                <div key={row.id} onClick={() => {navigateToRoute(`/player?id=${row.player?.uuid}&&code=${row.user?.uuid}`)}} style={{backgroundColor:'#94D1FF'}} className="d-flex flex-row justify-content-between px-1 py-1 my-1">
                                                                    <img alt='ddk' src={row?.user?.image? domain+row?.user?.image:"./assets/images/1.jpg"} className="rounded-circle mt-1" height="40" width="40"></img>

                                                                    <div onClick={() => handleChangeChat(row)} className="d-flex flex-column px-1 py-1">
                                                                        <h6 style={{color:'#16307D', fontSize:12}} className="my-1">{row?.user.first_name }</h6>
                                                                    </div>
                                                                    
                                                                </div>
                                                                ))
                                                            : <h5 style={{color:'#16307D', fontSize: 12, textAlign:'center'}}>No Meetings...</h5>
                                                        }
                                                    </div>
                                                </>: <>
                                                        {/* nothing */}
                                                </>}
                                                
                                            </>
                                        :
                                            
                                            <h6 style={{color:'#16307D', fontSize:14, textAlign:'center'}} className="my-1">No selection</h6>
                                        }
                                        </> 
                                    }
                                </>}

                            </>
                        }
                    </div>
                </div>
            </div>

            {/* <Footer/> */}
        </>
        
    )
}

export default Chat