import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain, timeFormatNow } from "../../dummyData";

import CustomAlert from '../Alert/CustomAlert';

let url =  domain;

const ViewSessionModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };

  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const customAlertRef = useRef()

  const { user } = useSelector((state) => state.persist.user)
  const { loading, error, successMessage } = useSelector((state) => state.session)
  const userData = user;

  const navigate = useNavigate();
  const navigateToRoute = (url) => {
      navigate(url);
  };

  return (
    <div onClick={() => handleClose}>
      <CustomAlert ref={customAlertRef}/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props?.event?.title? props?.event?.title: 'View'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="d-flex justify-content-center my-2 flex-column">

            <div className="row">
              <div className="col-3">
                  <img alt='ddk' src= {props.event?.sessionD?.image? domain+props.event?.sessionD.image:"./assets/images/1.jpg"} className="rounded-circle" width="90" height="90">
                  </img>
              </div>
              <div className="col-9">
                  <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.event?.sessionD?.name}</h6>
                  <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                      <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                      {props.event?.sessionD?.training_location?.city? props.event?.sessionD?.training_location?.city+',': ''}
                      {props.event?.sessionD?.training_location?.state? props.event?.sessionD?.training_location?.state+',': ''} {props.event?.sessionD?.user?.country}
                  </h6>
                  

                  <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontWeight: 'bolder', textAlign:'center'}}>
                    {props.event?.sessionD?.total? '£ '+props.event?.sessionD?.total+'/hr': ''}
                  </h6>

                  <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontWeight: 'bolder', textAlign:'center'}}>
                    Posted: {props.event?.sessionD?.created_at? timeFormatNow(props.event?.sessionD?.created_at): ''}
                  </h6>
                  <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', marginLeft:'10px', fontSize: 12, fontWeight: 'bolder', textAlign:'center'}}>
                    Time: {props.event?.sessionD?.start_at? timeFormatNow(props.event?.sessionD?.start_at): ''}
                  </h6>

                  <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D'}}>Type: {props.event?.sessionD?.max_booking > 1? `Multiple(${props.event?.sessionD?.bookings?.length} of ${props.event?.sessionD?.max_booking})`: `Single(${props.event?.sessionD?.bookings?.length} of ${props.event?.sessionD?.max_booking})`}</h6>

                  <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D'}}>Status: {
                  props.event?.sessionD?.status === 1? 'Closed' : 
                  props.event?.sessionD?.status === 5? 'Ended' : 'Open'
                  }</h6>

              </div>
          </div>
            
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className=''>

            <span onClick={() => {navigateToRoute(`/session?id=${props.event?.sessionD?.uuid}&&code=${userData?.uuid}`)}} style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill mr-2">
              View
            </span>

            <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleClose}>
              Cancel
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default ViewSessionModal