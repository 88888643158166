import React, { useState, useRef, useEffect } from 'react';
import './banner.css';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";

import { deleteMeeting, editMeeting, createMeeting } from "../../routes/MeetingAction";
import { editSession, deleteSession } from "../../routes/SessionAction";

import CustomAlert from '../Alert/CustomAlert';
import MediaModal from '../Modal/MediaModal';


const SessionBanner = (props) => {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const { loading, error, successMessage } = useSelector((state) => state.session)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()
    const displayModallRef = useRef()
    const handleDisplay = (title, media) => {
        displayModallRef.current.handleOpen(title, media);
        
    };

    const [showMForm, setShowMForm] = useState(false);

    const [meetingFormData, setMeetingFormData] = useState({
        name: '',
        is_private: false,
        is_online: false,
        session_id: props.data?.id,
        description: '',
        start_at: '',
        end_at: '',
        status: 0,
    });

    const handleMeetingFormChange = (e) => {
        if (e.target.name === 'status' || e.target.name === 'is_private' || e.target.name === 'is_online') {
            setMeetingFormData({ ...meetingFormData, [e.target.name]: e.target.checked? 0: 1 });
        } else {
            setMeetingFormData({ ...meetingFormData, [e.target.name]: e.target.value });
        }
    }

    const handleEdit = (type, status) => {
        let sessionFormData = props.data
        sessionFormData.status = status
        dispatch(editSession(sessionFormData))
        .then(response =>{

            if (error) {
                // console.log('er', error)
                customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
            triggerRefresh();
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleDelete = () => {
        if(props.data?.bookings?.length) {
            customAlertRef.current.handleOpen("Sorry you can't delete session that has players", 'danger');
        } else {
            dispatch(deleteSession(props.data?.id))
            .then(response =>{

                if (error) {
                    // console.log('er', error)
                    customAlertRef.current.handleOpen(error, 'danger');
                
                } else {
                    if (successMessage !== '') {
                        customAlertRef.current.handleOpen(successMessage, 'success');
                    }
                }
                triggerRefresh();
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    const [isPaidCoach, setIsPaidCoach] = useState(false);
    const isCoachHandler = () => {

        let sub = props.data?.coach?.subscriptions?.filter(el => ((el.user_id === userData.id) && el.status === 5)).map(row => row)

        if (sub?.length > 0) {
            setIsPaidCoach(true)
            // console.log('sub1', sub)
        } else {
            setIsPaidCoach(false)
            // console.log('sub2', sub)
        }

        

    }

    const handleAddMeeting = () => {
        isCoachHandler()
        setShowMForm(!showMForm)
        setMeetingFormData({
            name: '',
            is_private: false,
            is_online: false,
            session_id: props.data?.id,
            description: '',
            start_at: '',
            end_at: '',
            status: 0,
        })
    }

    const setRow = (row) => {
        const updatedRow = { ...row };
        if (updatedRow.status) {
            updatedRow.status =  updatedRow.status? 1: 0;
        }
        setMeetingFormData(updatedRow)
    }

    const triggerRefresh = () => {
        props.fetchSession();
        window.location.reload();
    }

    const deleteRow = (row) => {
        return dispatch(deleteMeeting(row.id))
        .then(response =>{

            if (error) {
                // console.log('er', error)
                customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
            triggerRefresh();
        })
        .catch((error) => {
            console.log(error)
        })
        
    }

    const handleSubmitMeeting = () => {
        if(meetingFormData.name === '') return
        return meetingFormData.id > 0? 
        dispatch(editMeeting(meetingFormData))
        : dispatch(createMeeting(meetingFormData))
        .then(response =>{

            if (error) {
                // console.log('er', error)
                customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
            triggerRefresh();
        })
        .catch((error) => {
            console.log(error)
            customAlertRef.current.handleOpen(error, 'danger');
        })
    }

    return (
        <>
            <MediaModal ref={displayModallRef}/>
            <CustomAlert ref={customAlertRef}/>
            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img onClick={() => {handleDisplay(props.data?.name, props.data?.image? domain+props.data.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data?.image? domain+props.data.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.data?.name}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.trainingLocation?.city? props.data?.trainingLocation?.city+',': ''} {props.data?.trainingLocation?.state? props.data?.trainingLocation?.state+',': ''} {props.data?.user?.country}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Created: {timeFormat(props.data?.created_at)}</h6>
                            <span style={{marginLeft:'20px',  border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                {props.data?.status !== 5? 'Active' : 'In Active'}
                            </span>
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Bookings: ({props.data?.bookings?.length})</h6>

                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Cost: {props.data.user?.currency_symbol} {props.data?.total}</h6>

                        {props.data?.status !== 5 ? <div className="d-flex flex-row my-1 justify-content-center">
                        <div className='mx-1'>
                            <span onClick={() => {handleEdit('close', props.data?.status ===1? 0: 1)}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-stop"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>{props.data?.status === 0? 'Close': 'Open'}</h6>
                        </div>
                        {props.data?.is_online?
                        <div className='mx-1'>
                            <span onClick={handleAddMeeting} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Meet</h6>
                        </div>: null}
                        <div className='mx-1'>
                            <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                        </div>

                        </div>: <></>}
                        
                    </div>
                    <div className="d-flex flex-column">
                    {props.data?.status !== 5 ?<span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={() => {handleEdit('end', 5)}}>End</span>: <></>}
                        <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleDelete}>Delete</span>
                    </div>
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #16307D", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img onClick={() => {handleDisplay(props.data?.name, props.data?.image? domain+props.data.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data?.image? domain+props.data?.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20}}>{props.data?.name}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.trainingLocation?.city? props.data?.trainingLocation?.city+',': ''} {props.data?.trainingLocation?.state? props.data?.trainingLocation?.state+',': ''} {props.data?.user?.country}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Created: {timeFormat(props.data?.created_at)}</h6>
                        
                    {/* </div> */}
                </div>
                <div className="d-flex justify-content-center">
                    <span style={{ border:"solid 1px #16307D", maxWidth:'150px', color:'#16307D'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        {props.data?.status !== 5? 'Active' : 'In Active'}
                    </span>
                </div>
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Bookings: ({props.data?.bookings?.length})</h6>
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Cost: {props.data.user?.currency_symbol} {props.data?.total}</h6>
                    
                    {props.data?.status !== 5 ? <div className="d-flex flex-row my-1 justify-content-center">
                        <div className='mx-1'>
                            <span onClick={() => {handleEdit('close', props.data?.status ===1? 0: 1)}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-stop"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>{props.data?.status === 0?'Close': 'Open'}</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={handleAddMeeting} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Meet</h6>
                        </div>
                        <div className='mx-1'>
                            <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill">
                                <i style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message"></i>
                            </span>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                        </div>
                    </div>: <></>}
                    
                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center">
                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={() => {handleEdit('end', 5)}}>End</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleDelete}>Delete</span>
                    </div>
                    
                </div>
                
            </div>

            {showMForm === true? 
            <div className="mx-4 p-3" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                <h3 className="d-flex justify-content-center">Meeting Schedule Form</h3>
                <div id='meeting-form'>


                {isPaidCoach === true? <> <div className="row"> 
                        <div className="mb-3 col-sm-12 col-md-3">
                            <label className="form-label">Name</label>
                            <input type="text" value={meetingFormData.name? meetingFormData.name: ''} onChange={handleMeetingFormChange} name='name' className="form-control form-control-sm" id="exampleFormControlInput1" required placeholder="name"/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-3">
                            <label className="form-label">Start At</label>
                            <input type="date" value={meetingFormData.start_at} onChange={handleMeetingFormChange} name='start_at' className="form-control form-control-sm" placeholder="start date"/>
                        </div>
                        <div className="mb-3 col-sm-12 col-md-3">
                            <label className="form-label">End At</label>
                            <input type="date" value={meetingFormData.end_at} onChange={handleMeetingFormChange} name='end_at' className="form-control form-control-sm" placeholder="end date"/>
                        </div>   
                        <div className="mb-3 col-sm-12 col-md-3">
                            <label className="form-label">Description</label>
                            <input type="text" value={meetingFormData.description? meetingFormData.description: ''} name='description' onChange={handleMeetingFormChange} className="form-control form-control-sm" required id="exampleFormControlInput1" placeholder="description"/>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mb-3">
                        <div className="d-flex mx-auto">
                            <div className="form-check form-switch col-3 mx-auto">
                                <input name='is_private' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleMeetingFormChange} checked={meetingFormData.is_private === 0? true: false}/>
                                <label className="form-check-label">Private</label>
                            </div>
                        </div>
                        <div className="d-flex mx-auto">
                            <div className="form-check form-switch col-3 mx-auto">
                                <input name='is_online' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleMeetingFormChange} checked={meetingFormData.is_online === 0? true: false}/>
                                <label className="form-check-label">Online</label>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mb-3">
                        <div className="d-flex mx-auto">
                            <div className="form-check form-switch col-3 mx-auto">
                                <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleMeetingFormChange} checked={meetingFormData.status === 0? true: false}/>
                                <label className="form-check-label">Status</label>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="d-flex mx-auto">
                            <div className="mb-3 ml-4">
                                <button onClick={() => handleAddMeeting('alert')} className="btn btn-sm btn-secondary">Close</button>
                            </div>
                            <div className="mb-3 col-4">
                                <button onClick={handleSubmitMeeting} className="btn btn-sm btn-primary">Submit</button>
                            </div>
                        </div>
                    </div></>:
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'10px', marginTop:'0', color:'red', textAlign:'center'}}>Please top up your subscription to show add meeting form</h6>}

                    

                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Meeting Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                            <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Start at</th>
                                            <th>End AT</th>
                                            <th>Created date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {props.data?.meetings?.map((row) => (
                                            <tr key={row.id}>
                                                <td>{row.name}</td>
                                                <td>{row.description}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                    : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{timeTable(row.start_at)}</td>
                                                <td>{timeTable(row.end_at)}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                                <td>
                                                    <i onClick={() => {setRow(row, 'alert')}}  className="fas fa-edit text-success mr-2"></i>
                                                    <i onClick={() => {deleteRow(row, 'alert')}} className="fas fa-trash text-danger ml-2"></i>
                                                </td>
                                            </tr>
                                        ))}
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
            : <></>}
            
        </>
    );

}

export default SessionBanner