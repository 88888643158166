import moment from 'moment'

export const homeData = [
    {
      id: 1,
      name: "The youths game",
      rating: 4.7,
      time: "2hr : 22mins",
      desc: "Sand and dust storms (SDS), also known as sirocco, haboob, yellow dust, white storms, and the harmattan, are a natural phenomenon linked with land and water management and climate change.",
      starring: "Karen Gilchrist, James Earl Jones",
      genres: "Action",
      tags: "Action, Adventures, Horror",
      cover: "../images/home1.jpg",
      video: "../video/video1.mp4",
      date: "20-Jan-1997",
    },
    {
      id: 2,
      name: "skull island",
      rating: 4.6,
      time: "2hr : 22mins",
      desc: "The island, like its inhabitants, is wholly fictional. Kong's island home in the Pacific makes its first appearance, along with Kong himself, in the 1933 film King Kong. ",
      starring: "Brenda Chapman, Jeff Nathanson",
      genres: "Adventures",
      tags: "Adventures,Animation,Family",
      cover: "../images/home2.jpg",
      video: "../video/video2.mp4",
      date: "10-JUL-2021",
    },
    {
      id: 3,
      name: "Pirates Sea",
      rating: 4.8,
      time: "2hr : 22mins",
      desc: "Piracy is an act of robbery or criminal violence by ship or boat-borne attackers upon another ship or a coastal area, typically with the goal of stealing cargo and other valuable items or properties. ",
      starring: "James Chinlund, Jeff Nathanson",
      genres: "Adventures",
      tags: "Adventures,Action ",
      cover: "../images/home3.jpg",
      video: "../video/video3.mp4",
      date: "20-FEB-2010",
    },
    {
      id: 4,
      name: "Sand Dust",
      rating: 4.9,
      time: "2hr : 22mins",
      desc: "Sand and dust storms (SDS), also known as sirocco, haboob, yellow dust, white storms, and the harmattan, are a natural phenomenon linked with land and water management and climate change.",
      starring: "Karen Gilchrist, James Earl Jones",
      genres: "Action",
      tags: "Action, Adventures, Horror",
      cover: "../images/home4.jpg",
      video: "../video/video4.mp4",
      date: "12-Aug-2015",
    },
  ]
  export const upcome = [
    {
      id: 1,
      cover: "../images/upcome/u1.jpg",
      name: "My office Boss",
      time: "2hr : 38mins",
    },
    {
      id: 2,
      cover: "../images/upcome/u2.jpg",
      name: "Shadowe",
      time: "2hr : 38mins",
    },
    {
      id: 3,
      cover: "../images/upcome/u3.jpg",
      name: "Another Danger",
      time: "2hr : 38mins",
    },
    {
      id: 4,
      cover: "../images/upcome/u4.jpg",
      name: "One Man Army",
      time: "2hr : 38mins",
    },
    {
      id: 5,
      cover: "../images/upcome/u5.jpg",
      name: "Jumbo Queen",
      time: "2hr : 38mins",
    },
  ]
  export const latest = [
    {
      id: 1,
      cover: "../images/upcome/u6.jpg",
      name: "King of Jungle",
      time: "2hr : 38mins",
    },
    {
      id: 2,
      cover: "../images/upcome/u7.jpg",
      name: "The illusion",
      time: "2hr : 38mins",
    },
    {
      id: 3,
      cover: "../images/upcome/u1.jpg",
      name: "Latest Movie",
      time: "2hr : 38mins",
    },
    {
      id: 4,
      cover: "../images/upcome/u5.jpg",
      name: "Latest two",
      time: "2hr : 38mins",
    },
    {
      id: 5,
      cover: "../images/upcome/u4.jpg",
      name: "Latest Three",
      time: "2hr : 38mins",
    },
  ]
  
  export const trending = [
    {
      id: 1,
      name: "The Dinosaur",
      rating: 4.7,
      time: "2hr : 22mins",
      desc: "Sand and dust storms (SDS), also known as sirocco, haboob, yellow dust, white storms, and the harmattan, are a natural phenomenon linked with land and water management and climate change.",
      starring: "Karen Gilchrist, James Earl Jones",
      genres: "Action",
      tags: "Action, Adventures, Horror",
      cover: "../images/img/slide1.jpg",
      date: "20-Jan-1997",
    },
    {
      id: 2,
      name: "The Great Emperor",
      rating: 4.6,
      time: "2hr : 22mins",
      desc: "The island, like its inhabitants, is wholly fictional. Kong's island home in the Pacific makes its first appearance, along with Kong himself, in the 1933 film King Kong. ",
      starring: "Brenda Chapman, Jeff Nathanson",
      genres: "Adventures",
      tags: "Adventures,Animation,Family",
      cover: "../images/img/slide2.jpg",
      date: "10-JUL-2021",
    },
    {
      id: 3,
      name: "Jumbo Queen",
      rating: 4.8,
      time: "2hr : 22mins",
      desc: "Piracy is an act of robbery or criminal violence by ship or boat-borne attackers upon another ship or a coastal area, typically with the goal of stealing cargo and other valuable items or properties. ",
      starring: "James Chinlund, Jeff Nathanson",
      genres: "Adventures",
      tags: "Adventures,Action ",
      cover: "../images/img/slide3.jpg",
      date: "20-FEB-2010",
    },
  ]
  export const recommended = [
    {
      id: 4,
      cover: "../images/rec/rec1.jpg",
      name: "One Man Army",
      time: "2hr : 38mins",
    },
    {
      id: 5,
      cover: "../images/rec/rec2.jpg",
      name: "Jumbo Queen",
      time: "2hr : 38mins",
    },
    {
      id: 1,
      cover: "../images/rec/rec3.jpg",
      name: "My office Boss",
      time: "2hr : 38mins",
    },
    {
      id: 2,
      cover: "../images/rec/rec4.jpg",
      name: "Shadowe",
      time: "2hr : 38mins",
    },
    {
      id: 3,
      cover: "../images/upcome/u3.jpg",
      name: "Another Danger",
      time: "2hr : 38mins",
    },
  ]

export const rowData = [
  {id:1, name:'emjay', createdAt:'22-02-2023'},
  {id:2, name:'emjay', createdAt:'22-02-2023'},
  {id:3, name:'emjay', createdAt:'22-02-2023'},
  {id:4, name:'emjay', createdAt:'22-02-2023'},
  {id:5, name:'emjay', createdAt:'22-02-2023'},
  {id:6, name:'emjay', createdAt:'22-02-2023'},
  {id:7, name:'emjay', createdAt:'22-02-2023'},
  {id:8, name:'emjay', createdAt:'22-02-2023'},
  {id:9, name:'emjay', createdAt:'22-02-2023'},
  {id:10, name:'emjay', createdAt:'22-02-2023'},
]

//This is the Auth token, you will use it to generate a meeting and connect to it
export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjMmZhMzJlYy03MTc4LTQxMDItOTIxZi0xMTZmOGQxYmE5MjYiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4ODY5NDA3OSwiZXhwIjoxODQ2NDgyMDc5fQ.sLq-UbUciieqYXPB1ZkMpyaPeOc3NgGi-wBCTnUYwVI";
// API call to create a meeting
export const createMeeting = async () => {

  fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      authorization: `${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  })
  .then(response => {
    return response.json()
  })
  .then(data => {
    // console.log('a', data.roomId)
    return data.roomId;
  })


  // {
  //   "apiKey": "c2fa32ec-7178-4102-921f-116f8d1ba926",
  //   "webhook": {
  //       "events": []
  //   },
  //   "disabled": false,
  //   "autoCloseConfig": {
  //       "type": "session-end"
  //   },
  //   "createdAt": "2024-05-26T15:09:18.839Z",
  //   "updatedAt": "2024-05-26T15:09:18.839Z",
  //   "roomId": "hl0a-b3b9-cmgn",
  //   "links": {
  //       "get_room": "https://api.videosdk.live/v2/rooms/hl0a-b3b9-cmgn",
  //       "get_session": "https://api.videosdk.live/v2/sessions/"
  //   },
  //   "id": "6653509ea1a2369a70ae7ce1"
  // }


  // const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
  //   method: "POST",
  //   headers: {
  //     authorization: `${authToken}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({}),
  // })

  // //Destructuring the roomId from the response
  // const { roomId } = await res.json();
  // return roomId;
};

export const compDate = (date1, date2) =>{
  if (moment(date1).format('DD/MM/YYYY') === moment(date2).format('DD/MM/YYYY'))
    return true
  else
    return false
}

export const timeFormat = (time) =>{
  if (moment(time).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY'))
    return moment(time).format('hh:mm:ss a');
  else
    return moment(time).fromNow();
}

export const timeFormatNow = () => {
  const timenow = new Date(); // Set timenow to the current date and time
  return moment(timenow).fromNow();
}

export const timeTable = (value) => {
  return moment(value).format('YYYY-MM-DD')
  // return moment(value).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export const fullTime = (value) => {
  return moment(value).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export const dateTime = (value) => {
  return moment(value).format('dddd, MMMM Do, YYYY')
}

// Check if a date is today
export const isToday = (date) => {
  return moment(date).isSame(moment(), 'day');
};

// Check if a date is within this week
export const isThisWeek = (date) => {
  return moment(date).isSame(moment(), 'week');
};

// Check if a date is within this month
export const isThisMonth = (date) => {
  return moment(date).isSame(moment(), 'month');
};

// export const timeFormatNUse = (time, formatType) => 
//   switch (formatType) {
//     case 'fromNow':
//       return moment(time).fromNow();
//     case 'timeOnly':
//       return moment(time).format('hh:mm:ss a');
//     case 'fullDateTime':
//       return moment(time).format('dddd, MMMM Do, YYYY h:mm:ss A');
//     case 'fullDate':
//       return moment(time).format('dddd, MMMM Do, YYYY');
//     default:
//       return moment(time).format('YYYY-MM-DD HH:mm:ss');
//   }
// };

export const reduceText = (text, length, suffix) => {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
}

export const uploadFile = (e) => {
  let poster = '';
  let file = e.target.files[0];
  if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
      if (file.size < 10097152) {
          poster = file;
      } else {
          console.log('Image Size Too Large', 'Image size cannot be more than 10MB', 'warning');
      }
  } else {
      console.log('File Format not Supported', 'Supported file types are jpg | jpeg | png', 'warning');
  }

  return poster;
}

export const domain = "https://api.nextlevel-football.com/"; //server
// export const domain = "http://127.0.0.1:8001/"; //dev

export const domainUrl = "https://api.nextlevel-football.com/api"; //server
// export const domainUrl = "http://127.0.0.1:8001/api"; //dev

// export const meetingUrl = "http://localhost:3001"; //dev
export const meetingUrl = "https://meeting.nextlevel-football.com"; //live



// export const domainUrl = () => {
//   return "https://api.nextlevel-football.com/api"

//   // url = "http://127.0.0.1:8000/api"

//   // return url;
// }
  