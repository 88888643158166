import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { createFlag, editFlag } from '../../routes/FlagAction';

import ErrorDialog from '../../components/Alert/ErrorDialog';
import CustomAlert from '../../components/Alert/CustomAlert';

const FlagForm = forwardRef((props, ref) => {
    const { error, successMessage } = useSelector((state) => state.persist.user)

    const dispatch = useDispatch();

    const errorDialogRef = useRef()
    const alertRef = useRef()

    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState({
        value: "",
        description: "",
        type: "",
        status: 0,
        name: "",
        url: '',
    });

    useImperativeHandle(
        ref,
        () => ({
        clearData() {clearData()},
        handleEdit(data) {
            handleEdit(data)
        },

        handleSubmit() {
            
            if (value !== '' && description !== '' && name !== '') {
                // console.log('data sent', formData)

                if (edit) {
                    dispatch(editFlag(formData))
                    .then(response =>{

                        if (error) {
                            handleAlert('error', 'Error saving data');
                            
                        } else {
                            if (successMessage !== '') {
                                handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            }
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                } else {
                    dispatch(createFlag(formData))
                    .then(response =>{

                        if (error) {
                            handleAlert('error', 'Error saving data');
                            
                        } else {
                            if (successMessage !== '') {
                                handleAlert('success', (edit?'Edited':'Saved')+' Successfully')
                            }
                        }
                    })
                    .catch((error) => {
                        props.handleResponse(false);
                        handleAlert('error', error);
                    })
                    
                }
            
            
            } else {
                handleErrorDialog('Please make sure you fill the form correctly')
            }

        }

    }),)


    const handleAlert = (status, message) => {
        alertRef.current.handleOpen(message, status);
    };

    const handleErrorDialog = (message) => {
        errorDialogRef.current.handleOpen(message);
    };

    const { value, description, type, status, name, url } = formData;

    const handleChange = (e) => {
        if (e.target.name === 'status') {
        setFormData({ ...formData, [e.target.name]: e.target.checked? 0: 1 });
        } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {const file = files[0];
            setFormData({ ...formData, url: file });
        } else {
            setFormData({ ...formData, url: '' });
            console.error("No file selected");
        }
    };

    const handleEdit = (data) => {
        setEdit(true);
        setFormData({ ...data, url: '' });
    };

    const clearData = () => {
        setFormData({ ...formData, 
            description: '', 
            value: '', 
            type: 1, 
            status: 0, 
            name: '',
            url: '',
        }
    );
    setEdit(false)
  };

  return (
    <>
        <ErrorDialog message={message} ref={errorDialogRef}/>
        <CustomAlert message={message} status={alertType} ref={alertRef}/>

        <form id='flag-form'>

            {type === 3? <div className='row'>
                <div className="mb-3">
                    <label className="form-label">If type Image</label>
                    <input required onChange={handleImageChange} type="file" name="url" id="url" autoComplete="image" className="form-select form-select-sm" placeholder="Image"></input>
                </div>
            </div>:<></>}

            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">VAlue</label>
                    <input type="text" value={value} onChange={handleChange} name='value' className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter value"/>
                </div>
            </div>
            
            <div className='row'>
                <div className="mb-3 col-12">
                    <label className="form-label">Description</label>
                    <input type="text" value={description} name='description' onChange={handleChange} className="form-control form-control-sm" id="exampleFormControlInput1" placeholder="enter value"/>
                </div>
                <div className="form-check form-switch col-3 mx-auto">
                    <input name='status' className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleChange} checked={status===0?true:false}/>
                    <label className="form-check-label">Status</label>
                </div>
            </div>
            
        </form>
        
    </>
  );
})

export default FlagForm