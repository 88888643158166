import React, { useState, useRef, useEffect } from 'react';
import './banner.css';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { timeFormat, timeTable, domain } from "../../dummyData";
import { createRemark } from "../../routes/RemarkAction";
import { createDispute } from "../../routes/DisputeAction";
import { editSessionBooking } from "../../routes/SessionBookingAction";
import {checkout} from '../../routes/PaymentAction';

import CustomAlert from '../Alert/CustomAlert';
import MediaModal from '../Modal/MediaModal';


const SessionBanner = (props) => {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.persist.user)
    const { loading, error, successMessage } = useSelector((state) => state.session)
    const userData = user;

    const navigate = useNavigate();
    const navigateToRoute = (url) => {
        navigate(url);
    };

    const customAlertRef = useRef()
    const displayModallRef = useRef()
    const handleDisplay = (title, media) => {
        displayModallRef.current.handleOpen(title, media);
        
    };

    const [showRForm, setShowRForm] = useState(false);
    const [showMForm, setShowMForm] = useState(false);

    const [remarkFormData, setRemarkFormData] = useState({
        to_id: props.data?.user?.id,
        message: '',
        description: '',
    });

    const handleRemarkFormChange = (e) => {
        if (e.target.name === 'status') {
            setRemarkFormData({ ...remarkFormData, [e.target.name]: e.target.checked? 0: 1 });
            } else {
            setRemarkFormData({ ...remarkFormData, [e.target.name]: e.target.value });
        }
    }

    const handleShowMeeting = () => {
        setShowMForm(!showMForm);
    }

    const handleJoinSession = () => {
        if (userData?.userType === 4) {
            if(props.data?.coach_state) {
                if(props.data?.state === 5) {
                    dispatch(checkout({
                        email: userData?.email, 
                        paymentMode: 'card',
                        type: 'session',
                        id: props.data?.id,
                        description: `Session ${props.data.name} booking between coach ${props.data?.user?.first_name} ${props.data?.user?.last_name} and player ${userData?.first_name} ${userData?.last_name}.`,
                    }))
                    .then(response =>{
                        triggerRefresh();
                        if (error) {
                            // console.log('er', error)
                            customAlertRef.current.handleOpen(error, 'danger');
                        
                        }
                    })
                    .catch((error) => {
                        customAlertRef.current.handleOpen(error, 'danger');
                        console.log(error)
                    });
                }else {
                    customAlertRef.current.handleOpen("Sorry the coach can't receive payment now.", 'danger');
                }
            }else {
                customAlertRef.current.handleOpen("Sorry the session isn't available now.", 'danger');
            }
        }else {
            customAlertRef.current.handleOpen("Sorry you are not a player.", 'danger');
        }
    }

    const handleLeaveSession = () => {
        let booking = props.data?.bookings?.filter(el => el.user_id !== userData.id);
        if (booking.length > 0) {
            let bookingData = booking;
            bookingData.status = 3;

            return dispatch(editSessionBooking(bookingData))
            .then(response =>{

                if (error) {
                    // console.log('er', error)
                    customAlertRef.current.handleOpen(error, 'danger');
                
                } else {
                    if (successMessage !== '') {
                        customAlertRef.current.handleOpen(successMessage, 'success');
                    }
                }

                triggerRefresh();
            })
            .catch((error) => {
                customAlertRef.current.handleOpen(error, 'danger');
                console.log(error)
            })
        }
    }

    const handleDispute = () => {
        return dispatch(createDispute({
            against_id: props.data?.user?.id,
            disputable_type: "App\Models\Session",
            disputable_id: props.data?.id,
            message: `Session ${props.data.name} dispute between coach ${props.data?.user?.first_name} ${props.data?.user?.last_name} and player ${userData?.first_name} ${userData?.last_name}.`,
            description: `Session ${props.data.name} dispute between coach ${props.data?.user?.first_name} ${props.data?.user?.last_name} and player ${userData?.first_name} ${userData?.last_name}.`,
        }))
        .then(response =>{
            if (error) {
                // console.log('er', error)
                customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
            triggerRefresh();
        })
        .catch((error) => {
            customAlertRef.current.handleOpen(error, 'danger');
            console.log(error)
        })
    }

    const handleAddRemark = () => {
        setShowRForm(!showRForm)
    }

    const triggerRefresh = () => {
        props.fetchSession();
    }

    const handleSubmitRemark = () => {
        // if(remarkFormData.name === '') return
        
        return dispatch(createRemark({...remarkFormData, to_id: props.data?.user?.id}))
        .then(response =>{

            if (error) {
                // console.log('er', error)
                customAlertRef.current.handleOpen(error, 'danger');
            
            } else {
                if (successMessage !== '') {
                    customAlertRef.current.handleOpen(successMessage, 'success');
                }
            }
            triggerRefresh();
        })
        .catch((error) => {
            console.log(error)
            customAlertRef.current.handleOpen(error, 'danger');
        })
    }

    return (
        <>
            <MediaModal ref={displayModallRef}/>
            <CustomAlert ref={customAlertRef}/>
            <div className="b-screen">
                <div className="d-flex justify-content-between mx-3 p-3 my-2" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                    <div className="row">
                        <div className="col-3">
                            <img onClick={() => {handleDisplay(props.data?.name, props.data?.image? domain+props.data.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data?.image? domain+props.data.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                            </img>
                        </div>
                        <div className="col-9">
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20,}}>{props.data?.name}</h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                                <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                                {props.data?.trainingLocation?.city? props.data?.trainingLocation?.city+',': ''} {props.data?.trainingLocation?.state? props.data?.trainingLocation?.state+',': ''} {props.data?.user?.country}
                            </h6>
                            <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Created: {timeFormat(props.data?.created_at)}</h6>
                            <span style={{marginLeft:'20px', border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 rounded-pill">
                                <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                                {props.data?.status !== 5? 'Active' : 'In Active'}
                            </span>
                        </div>
                    </div>
                    <div>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Players: ({props.data?.bookings?.length})</h6>
                        <h6 style={{marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>£ {props.data?.total}</h6>
                        
                        {props.data?.paid? <div className="d-flex flex-row my-1 justify-content-center">
                            
                            {props.data?.paid? <>
                            <div className='mx-1'>
                                {/* <span onClick={handleAddRemark} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleAddRemark} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-star ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Rate</h6>
                            </div>

                            <div className='mx-1'>
                                {/* <span onClick={handleDispute} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleDispute} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-home ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Issue</h6>
                            </div>
                            
                            <div className='mx-1'>
                                {/* <span onClick={handleShowMeeting} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleShowMeeting} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom:'0', marginTop:'0', marginLeft:'0', color:'#3458C2', fontSize: '0.8em', textalign:'center'}}>Meet</h6>
                            </div>
                            </>: <></>}
                            
                            <div className='mx-1'>
                                {/* <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={() => {navigateToRoute('/chat')}} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom: '0', marginTop: '0', marginLeft: '0', color: '#3458C2', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                            </div>

                        </div>: <></>}
                        
                    </div>
                    
                    {((props.data?.bookings?.length === props.data?.max_booking) && (!props.data?.member))?
                        <div className="d-flex flex-column">
                            <span style={{backgroundColor:'grey', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill">
                                Join
                            </span>
                            <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" >
                                Full
                            </span>
                        </div>
                    :
                        userData?.userType === 4? 
                            <div className="d-flex flex-column">
                                {props.data?.member? 
                                    props.data?.paid?
                                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleLeaveSession}>Leave</span>
                                    :
                                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleJoinSession}>
                                            Pay now
                                        </span>

                                :
                                    <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill" onClick={handleJoinSession}>
                                        Join
                                    </span>
                                
                                }

                                <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" >
                                    {props.data?.status === 1 ? "Closed"
                                    : props.data?.status === 5 ? "Ended"
                                    : "Open"}
                                </span>
                            </div>
                        :
                        <div className="d-flex flex-column">
                            <span style={{backgroundColor:'grey', border:"solid 1px #16307D"}} className="badge px-3 py-2 my-1 rounded-pill">
                                Join
                            </span>
                            <span style={{border:"solid 1px #16307D", color:'#16307D'}} className="badge px-3 py-2 my-1 rounded-pill" >
                                {props.data?.status === 1 ? "Closed"
                                : props.data?.status === 5 ? "Ended"
                                : "Open"}
                            </span>
                        </div>
                    }
                    
                    
                </div>
            </div>

            <div className="mx-3 p-3 my-2 m-screen" style={{
                backgroundColor:'#E1F2FE', 
                // border:"solid 1px #16307D", 
                borderRadius:'10px'
                }}>
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <img onClick={() => {handleDisplay(props.data?.name, props.data?.image? domain+props.data.image:"./assets/images/1.jpg")}} alt='ddk' src= {props.data?.image? domain+props.data?.image:"./assets/images/1.jpg"} className="rounded-circle" width="80" height="80">
                        </img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {/* <div className="d-flex justify-content-center"> */}
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#16307D', fontSize:20}}>{props.data?.name}</h6>
                        
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#3458C2'}}>
                            <i style={{marginRight:'5px'}} className="fa-solid fa-magnifying-glass"></i>
                            {props.data?.trainingLocation?.city? props.data?.trainingLocation?.city+',': ''} {props.data?.trainingLocation?.state? props.data?.trainingLocation?.state+',': ''} {props.data?.user?.country}
                        </h6>
                        <h6 className="d-flex justify-content-center" style={{marginBottom:'0', marginTop:'0', marginLeft:'20px', color:'#67BEFA'}}>Created: {timeFormat(props.data?.created_at)}</h6>
                        
                    {/* </div> */}
                </div>
                <div className="d-flex justify-content-center">
                    <span style={{ border:"solid 1px #16307D", maxWidth:'150px', color:'#16307D'}} className="badge px-2 py-2 my-1 rounded-pill">
                        <i style={{color:'#16307D', marginRight:'5px'}} className="fa-solid fa-bolt"></i>
                        {props.data?.status !== 5? 'Active' : 'In Active'}
                    </span>
                </div>
                <div className="row">
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>Players: ({props.data?.bookings?.length})</h6>
                    <h6 className="d-flex justify-content-center" style={{ marginLeft:'', marginBottom:'0', marginTop:'0', color:'#16307D', textAlign:'center'}}>£ {props.data?.total}</h6>
                        
                    {props.data?.bookings?.length > 0? <div className="d-flex flex-row my-1 justify-content-center">
                            
                        {props.data?.paid? <>
                            <div className='mx-1'>
                                {/* <span onClick={handleAddRemark} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleAddRemark} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-star ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom: '0', marginTop: '0', marginLeft: '0', color: '#3458C2', fontSize: '0.8em', textalign:'center'}}>Rate</h6>
                            </div>
                            <div className='mx-1'>
                                {/* <span onClick={handleDispute} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleDispute} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-home ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom: '0', marginTop: '0', marginLeft: '0', color: '#3458C2', fontSize: '0.8em', textalign:'center'}}>Issue</h6>
                            </div>
                            <div className='mx-1'>
                                {/* <span onClick={handleShowMeeting} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                    <i onClick={handleShowMeeting} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-calendar ml-1"></i>
                                {/* </span> */}
                                <h6 style={{marginBottom: '0', marginTop: '0', marginLeft: '0', color: '#3458C2', fontSize: '0.8em', textalign:'center'}}>Meet</h6>
                            </div>
                        
                        </>: <></>}
                        <div className='mx-1'>
                            {/* <span onClick={() => {navigateToRoute('/chat')}} style={{ border:"solid 1px #16307D", color:'#16307D', marginRight:'5px'}} className="badge px-1 py-1 rounded-pill"> */}
                                <i onClick={() => {navigateToRoute('/chat')}} style={{color:'#16307D', fontSize:16}} className="fa-solid fa-message ml-1"></i>
                            {/* </span> */}
                            <h6 style={{marginBottom: '0', marginTop: '0', marginLeft: '0', color: '#3458C2', fontSize: '0.8em', textalign:'center'}}>Chat</h6>
                        </div>
                    </div>: <></>}
                    
                </div>

                {((props.data?.bookings?.length === props.data?.max_booking) && (!props.data?.member))?
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-center">
                            <span style={{backgroundColor:'grey', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center">Join</span>
                        </div>
                        <div className="d-flex justify-content-center">
                            <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" >
                                Full
                            </span>
                        </div>
                        
                    </div>
                :
                    userData?.userType === 4?
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center">
                                {/* {props.data?.paid? */}
                                {props.data?.member? 
                                    props.data?.paid?
                                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleLeaveSession}>Leave</span>
                                    :
                                        <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleJoinSession}>
                                            Pay now
                                        </span>
                                
                                :
                                    <span style={{backgroundColor:'#16307D', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" onClick={handleJoinSession}>
                                        Join
                                    </span>
                                }
                            </div>
                            <div className="d-flex justify-content-center">
                                <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" >
                                    {props.data?.status === 1 ? "Closed"
                                    : props.data?.status === 5 ? "Ended"
                                    : "Open"}
                                </span>
                            </div>
                            
                        </div>
                    :
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center">
                                <span style={{backgroundColor:'grey', border:"solid 1px #16307D", maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center">Join</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <span style={{border:"solid 1px #16307D", color:'#16307D', maxWidth:'150px'}} className="badge px-3 py-2 my-1 rounded-pill d-flex justify-content-center" >
                                    {props.data?.status === 1 ? "Closed"
                                    : props.data?.status === 5 ? "Ended"
                                    : "Open"}
                                </span>
                            </div>
                            
                        </div>  
                }
                
            </div>

            {showRForm === true? 
                <div className="mx-4 p-3" style={{
                        backgroundColor:'#E1F2FE', 
                        // border:"solid 1px #16307D", 
                        borderRadius:'10px'
                        }}>
                    <h3 className="d-flex justify-content-center">Remark Form</h3>
                    <div id='remark-form'>
                        
                        
                        <div className="row">
                            <div className="mb-3 col-sm-12 col-md-4">
                                <label className="form-label">Comment</label>
                                <input type="text" value={remarkFormData.message} name='message' onChange={handleRemarkFormChange} className="form-control form-control-sm" required id="exampleFormControlInput1" placeholder="comment"/>
                            </div>
                            <div className="mb-3 col-sm-12 col-md-8">
                                <label className="form-label">Description</label>
                                <input type="text" value={remarkFormData.description} name='description' onChange={handleRemarkFormChange} className="form-control form-control-sm" id="exampleFormControlInput1" required placeholder="description"/>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex mx-auto">
                                <div className="mb-3 ml-4">
                                    <button onClick={() => handleAddRemark('alert')} className="btn btn-sm btn-secondary">Close</button>
                                </div>
                                <div className="mb-3 col-4">
                                    <button onClick={handleSubmitRemark} className="btn btn-sm btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            : <></>}

            {showMForm === true? 
            <div className="mx-4 p-3" style={{
                    backgroundColor:'#E1F2FE', 
                    // border:"solid 1px #16307D", 
                    borderRadius:'10px'
                    }}>
                <div id='meeting-form'>

                    <div className="card shadow mb-4" style={{height: "100%"}}>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Meeting Schedule Table</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{height: "300px"}}>
                            <table className="table table-bordered table-striped table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Created date</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{}}>
                                        {props.data?.meetings?.map((row) => (
                                            <tr key={row.id}><td>{row.description}</td>
                                                <td>
                                                    {row.status === 0? 
                                                        <span className="badge text-bg-success">Active</span>
                                                    : 
                                                        <span className="badge text-bg-danger">In Active</span>
                                                    }
                                                </td>
                                                <td>{timeTable(row.start_at)}</td>
                                                <td>{timeTable(row.end_at)}</td>
                                                <td>{timeTable(row.created_at)}</td>
                                            </tr>
                                        ))}
                                        

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
            : <></>}
            
        </>
    );

}

export default SessionBanner