import React, { useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ViewSessionModal from '../Modal/ViewSessionModal';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ userType, data, duser }) => {
  const [availability, setAvailability] = useState(data || []);
  const [cuser, setCuser] = useState(duser || null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [hours, setHours] = useState('');
  const [message, setMessage] = useState('');

  const modalRef = useRef(null);

  const handleSelectSlot = ({ start }) => {};

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    if (modalRef.current) {
      modalRef.current.handleOpen();
    }
  };

  const events = availability?.map(event => ({
    title: event?.session?.name,
    start: new Date(event?.session?.start_at),
    end: new Date(event?.session?.end_at),
    allDay: false,
    eventId: event?.session?.id,
    sessionD: event?.session,
    style: new Date(event?.session?.start_at) < new Date() ? { backgroundColor: 'lightcoral', color: 'white' } : { backgroundColor: 'lightblue', color: 'black' },
  })) || [];

  const CustomEvent = ({ event }) => {
    return (
      <div style={{ textAlign: 'center', color: event.style.color }}>
        {event.title}
      </div>
    );
  };

  return (
    <div>
      <ViewSessionModal ref={modalRef} event={selectedEvent} />
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}
        style={{ height: 600 }}
        eventPropGetter={(event) => ({
          style: event.style,
        })}
        components={{
          event: CustomEvent,
        }}
        onSelectEvent={handleEventClick}
      />
    </div>
  );
};

export default MyCalendar;
