import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Custom.css'

import { useDispatch, useSelector } from 'react-redux'
import {getFlags} from '../../routes/FlagAction';

import { domain } from '../../dummyData';
let url =  domain;

const TipsModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true)
  };

  const handleClose = () => {
    setShow(false)
  };


  useImperativeHandle(
    ref,
    () => ({
      handleOpen() {handleOpen()},
      handleClose() {handleClose()},
    }),
  )

  const handleSubmit = () => {
    props.handleSubmit()
  };

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(1);
  const [indexNo, setIndexNo] = useState(0);

  const [sessionFlagData, setSessionFlagData] = useState([]);
  const [subscriptionFlagData, setSubscriptionFlagData] = useState([]);
  const [playerFlagData, setPlayerFlagData] = useState([]);
  const [coachFlagData, setCoachFlagData] = useState([]);

  const handleSelected = (e) => {
    // setSelected(e.target.value)
    setIndexNo(1);
    setSelected(parseInt(e.target.value, 10));
    // console.log(selected)
  };

  const fetchData = () => {
    dispatch(getFlags())
    .then(response =>{
      let resData = response.payload.data;
      const editableData = resData.map(o => ({ ...o }));

      setSessionFlagData(editableData?.filter(el => (el.name === 'app-guide-s')))
      setCoachFlagData(editableData?.filter(el => (el.name === 'app-guide-c')))
      setSubscriptionFlagData(editableData?.filter(el => (el.name === 'app-guide-su')))
      setPlayerFlagData(editableData?.filter(el => (el.name === 'app-guide-p')))

      // console.log(sessionFlagData)

    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {

      fetchData()

  }, [])


  return (
    <div onClick={() => handleClose}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props.title?props.title: 'Form'}</Modal.Title> */}
          <select style={{width:"150px"}} name='type' value={selected} onChange={handleSelected} className="form-select form-select-sm" aria-label=".form-select-sm example">
            <option value="1">Subscription</option>
            <option value="2">Sessions</option>
            <option value="3">Players</option>
            <option value="4">Coach</option>
          </select>
        </Modal.Header>

        <Modal.Body>
          {/* {props.tableForm? props.tableForm : 'Form not available'} */}

          <div className="d-flex justify-content-center my-2 flex-column">
            {selected === 1 ? (
              subscriptionFlagData?.length ? <>
                <img
                  alt="ddk"
                  src={url + subscriptionFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />
                {subscriptionFlagData[indexNo]?.description? 
                <div dangerouslySetInnerHTML={{__html: subscriptionFlagData[indexNo]?.description}} style={{textAlign:'center', color:'#16307D'}}>
                  {/* {subscriptionFlagData[indexNo]?.description.split('<br/>').map(function (rtext, index) {
                      return <p key={index}>{ rtext }</p>; 
                  })} */}
                  {/* {subscriptionFlagData[indexNo]?.description} */}
                </div>: null}
              </> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : selected === 2 ? (
              sessionFlagData?.length ? <div className="flex-column mx-auto">
                <img
                  alt="ddk"
                  src={url + sessionFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />
                {sessionFlagData[indexNo]?.description? 
                <div dangerouslySetInnerHTML={{__html: sessionFlagData[indexNo]?.description}} style={{textAlign:'center', color:'#16307D'}}>
                  {/* {sessionFlagData[indexNo]?.description?.split('<br/>').map(function (rtext, index) {
                      return <p key={index}>{ rtext }</p>; 
                  })} */}
                  {/* {sessionFlagData[indexNo]?.description} */}
                </div>: null}
              </div> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : selected === 3 ? (
              playerFlagData?.length ? <>
                <img
                  alt="ddk"
                  src={url + playerFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />
                {playerFlagData[indexNo]?.description? 
                <div dangerouslySetInnerHTML={{__html: playerFlagData[indexNo]?.description}} style={{textAlign:'center', color:'#16307D'}}>
                  {/* {playerFlagData[indexNo]?.description} */}
                </div>: null}
              </> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : selected === 4 ? (
              coachFlagData?.length ? <>
                <img
                  alt="ddk"
                  src={url + coachFlagData[indexNo]?.url}
                  className="img-fluid"
                  style={{ maxHeight: '90%', maxWidth: '100%' }}
                />
                {coachFlagData[indexNo]?.description? 
                <div dangerouslySetInnerHTML={{__html: coachFlagData[indexNo]?.description}} style={{textAlign:'center', color:'#16307D'}}>
                  {/* {coachFlagData[indexNo]?.description} */}
                </div>: null}
              </> : <><h6 style={{textAlign:'center', color:'#16307D'}}>Sorry Not Available Now</h6></>
            ) : null}
          </div>

          {/* <div className="d-flex justify-content-center my-2">
            {sessionFlagData?.length? 
            <img
              alt="ddk"
              src={url+sessionFlagData[indexNo]?.url}
              // className="img-fluid rounded-circle"
              className="img-fluid"
              style={{ maxHeight: '90%', maxWidth: '90%'}}
            />:
            <h6>Empty</h6>}
          </div> */}

        </Modal.Body>

        <Modal.Footer>
          <div>
            {selected === 1 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (subscriptionFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (subscriptionFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : selected === 2 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (sessionFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (sessionFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : selected === 3 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (playerFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (playerFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : selected === 4 ? 
            <>
              <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : (coachFlagData.length - 1))} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              <span onClick={() => indexNo < (coachFlagData.length - 1)? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle mx-2">
                <i className="fa fa-arrow-right"></i>
              </span>
              <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
                <i className="fa fa-close"></i>
              </span>
            </> 
            : null}
          </div>
          {/* <span onClick={() => setIndexNo(prevIndex => prevIndex > 0 ? prevIndex - 1 : sessionFlagData.length)} style={{color:'#16307D', border:"solid 1px #16307D"}} className="badge rounded-circle">
            <i className="fa fa-arrow-left"></i>
          </span>
          <span onClick={() => indexNo < sessionFlagData.length? setIndexNo(indexNo+1) : setIndexNo(0)} style={{color:'green', border:"solid 1px #16307D"}} className="badge rounded-circle">
            <i className="fa fa-arrow-right"></i>
          </span>
          <span onClick={handleClose} style={{color:'red', border:"solid 1px #16307D"}} className="badge rounded-circle">
            <i className="fa fa-close"></i>
          </span> */}
        </Modal.Footer>
      </Modal>
    </div>
  );

})


export default TipsModal